import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { create } from './InventoryGenExitsController';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import {
    Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Typography, LinearProgress, Collapse,
    FormControl, InputLabel, Select, MenuItem, Snackbar, Tabs, AppBar, Tab
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import ItemsAutocomplete from '../../components/Items/ItemsAutocomplete';
import getTodayDate from '../../services/getTodayDate';
import { findOpenProjects } from './InventoryGenExitsController';
import { findActiveProfitCentersInFirstDimension } from '../../components/ProfitCenters/ProfitCentersController';
import { v4 as uuid } from 'uuid';
import TabPanel from '../../components/TabPanel';

const InventoryGenExits = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [comments, setComments] = useState('');
    const [branchs, setBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [error, setError] = useState({isError: false, errorMessage: []});
    const [serverError, setServerError] = useState({isServerError: false, serverErrorMessage: ''});
    const [progressBar, setProgressBar] = useState(true);
    const [items, setItems] = useState([{ItemCode: '', ItemName: '', Quantity:0, ProjectCode: '', CostingCode: ''}]);
    const [openProjects, setOpenProjects] = useState([]);
    const [profitCenters, setProfitCenters] = useState([]);
    const classes = CreateAndViewStyle();

    useEffect(() => {
        const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));
        setBranchs(branchs);
    }, []);

    useEffect(() => {
        async function fetchData() {
            const projects = await findOpenProjects();
            setOpenProjects(projects);
        };

        fetchData();

    }, []);

    useEffect(() => {
        async function fetchData() {
            const profitCenters = await findActiveProfitCentersInFirstDimension();
            setProfitCenters(profitCenters);
        };

        fetchData();

    }, []);

    const handleNewItem = () => { 

        setItems([...items, {ItemCode: '', ItemName: '', Quantity: 0, ProjectCode: ''}]);        
    };
 
    const handleDeleteItem = (event) => {
        
        const index = event.currentTarget.dataset.key;   
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    const handleSubmit = async () => {
       
        setProgressBar(false);

        const errorList = hasErrors();

        if( errorList.length === 0 ) {

            const finalPurchaseRequest = {
                DocDate: documentDate,  
                Comments: comments,
                DocumentLines: items,
                BPL_IDAssignedToInvoice: selectedBranch
            }  
            
            const result = await create(finalPurchaseRequest);
            setProgressBar(true);

            if(result.data.error) {
                setServerError({isServerError: true, serverErrorMessage: result.data.error.message.value})
                 
            } else {
                alert('SAÍDA REALIZADA COM SUCESSO!');
                history.push('/home/inventoryGenExits'); 
            }

        } else {            
            setError({isError: true, errorMessage: errorList });
            setProgressBar(true);
        }
    }

    const handleBranchSelection = async (event) => {                                
        setSelectedBranch(event.target.value);
    }

    const setInputItem = (item, index) => {
        if(item) {

            let tempItems = [...items];
            tempItems[index].ItemCode = item.ItemCode;   
            tempItems[index].ItemName = item.ItemName;   
            setItems(tempItems);
        }
    }

    const hasErrors = () => {

        let errorList = [];

        if( !documentDate ) 
        errorList.push('Data - Selecione uma data válida para a saída de mercadoria.');   

        if( !selectedBranch ) 
        errorList.push('Filial - Selecione uma filial válida.');     

        if( items.length <= 0 || !items[0].ItemCode ) 
            errorList.push('Itens - Selecione ao menos um item para a solicitação.');

        items.forEach((item, index) => {

            if( item.Quantity <= 0 ) 
                errorList.push(`Linha ${index} - Quantidade deve ser maior que zero.`);
    
        });

        return errorList;

    }

    return (
        <>
            <Snackbar 
                open={serverError.isServerError} 
                autoHideDuration={6000} 
                onClose={()=> {
                    setServerError({isServerError: false, serverErrorMessage: ''});
                }}
            >
                <Alert 
                    onClose={()=> {
                        setServerError({isServerError: false, serverErrorMessage: ''});
                    }} 
                    severity="error"
                
                >
                Erro: {serverError.serverErrorMessage}
                </Alert>
            </Snackbar>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                    Saída de Mercadoria - Adicionar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
            <TabPanel value={tabs} index={1}>
                <Grid container spacing={2}>
                    <Grid item >
                        <TextField
                            id="date"
                            label="Data da Saída"
                            type="date"
                            defaultValue={getTodayDate()}
                            value={documentDate}
                            variant="outlined"
                            size="small"
                            onChange={(event) => {
                                setDocumentDate(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item >
                        <FormControl variant="outlined" className={classes.formControl} size="small">
                            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                            <Select
                            labelId="filial"
                            id="filial"
                            label="Filial"   
                            value={selectedBranch}        
                            onChange={handleBranchSelection}                                            
                            >                            
                            {branchs.map(branch => {
                                return(
                                    <MenuItem value={branch.BPLID}>{branch.BPLName}</MenuItem>
                                );
                            })}                           
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={16}>
                        <Button 
                            variant="contained" 
                            onClick={handleNewItem}
                            size='small'
                            color='primary'
                        >
                            Novo item
                    </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow className={classes.tablehead}>
                                        <TableCell>Item</TableCell>                                        
                                        <TableCell>Quantidade</TableCell>
                                        <TableCell>Centro de Custo</TableCell>
                                        <TableCell>Projeto</TableCell>
                                        <TableCell>Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                <ItemsAutocomplete 
                                                    idx={index}
                                                    addItemField={items[index]}
                                                    setInputItem={setInputItem}                                                    
                                                />                                                
                                                </TableCell>                                                
                                                <TableCell>                      
                                                    <TextField                                                                                                   
                                                        variant="outlined"
                                                        margin="dense"                                                                                                              
                                                        label="Quantidade"
                                                        value={items[index].Quantity}
                                                        onChange={(event) => {
                                                            let tempItems = [...items];
                                                            tempItems[index].Quantity = event.target.value;   
                                                            setItems([...tempItems]);                                                       
                                                        }}
                                                    />
                                                </TableCell>      
                                                <TableCell>
                                                    <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: 200 }}>
                                                        <InputLabel id="demo-simple-select-label">Centro de Custo</InputLabel>
                                                        <Select
                                                        labelId="centro-de-custo"
                                                        label="Centro de Custo"                                 
                                                        value={items[index].CostingCode}        
                                                        onChange={event => {    
                                                            let tempItems = [...items];
                                                            tempItems[index].CostingCode = event.target.value;   
                                                            setItems(tempItems);   
                                                        }}                                            
                                                        >                          
                                                        {profitCenters.map(profit => {
                                                            return(
                                                                <MenuItem value={profit.CenterCode} key={uuid()}>{profit.CenterCode + ' - ' + profit.CenterName}</MenuItem>
                                                            );
                                                        })}                           
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>                                         
                                                <TableCell>
                                                    <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: 200 }}>
                                                        <InputLabel id="demo-simple-select-label">Projeto</InputLabel>
                                                        <Select
                                                        labelId="projeto"
                                                        label="Projeto"                                 
                                                        value={items[index].ProjectCode}        
                                                        onChange={event => {    
                                                            let tempItems = [...items];
                                                            tempItems[index].ProjectCode = event.target.value;   
                                                            setItems(tempItems);   
                                                        }}                                            
                                                        > 
                                                        <MenuItem value=""><em>Nenhum</em></MenuItem>                           
                                                        {openProjects.map(project => {
                                                            return(
                                                                <MenuItem value={project.Code} key={uuid()}>{project.Code + ' - ' + project.Name}</MenuItem>
                                                            );
                                                        })}                           
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>                                         
                                                <TableCell>                                                    
                                                    <Button 
                                                        data-key={index}
                                                        onClick={handleDeleteItem}
                                                    >
                                                    <DeleteOutlineIcon />
                                                    </Button>                                                      
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Observações"
                            multiline
                            rows={2}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            values={comments}
                            onChange={(event) => {
                                setComments(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                            Adicionar
                        </Button>
                        <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home/inventoryGenExits'}>
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress hidden={progressBar}/>
                    </Grid>
                    <Grid item xs={12}> 
                        <Collapse in={error.isError}>                      
                            <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                onClick={() => {
                                    setError({isError: false, errorMessage: []});
                                }}
                                >
                                  Fechar
                                </Button>
                              }
                            >
                                { error.errorMessage.map(err => {
                                    return(
                                        <>- { err } <br/></>                                        
                                        
                                    )
                                }) }
                            </Alert>
                        </Collapse>
                    </Grid>
                </Grid>
                </TabPanel>
            </Paper>            
        </>
    );
}

export default InventoryGenExits;