import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { 
    searchByPartDescription, 
    searchByPartDescriptionOthersInputs, 
    searchByPartDescriptionGraos,
    searchByPartDescriptionPurchaseRequest,
    searchByPartDescriptionConsumeItens
} from './ItemsController';
import { UnitOfMeasurementGroupsByAbsEntry, UnitOfMeasurementsList } from '../../pages/DefaultController';

const ItemsAutocomplete = (props) => {

    const width = props.width ? props.width : 300;
    const [listItems, setListItems] = useState([]);
    const [addInputValue, setAddInputValue] = useState('');

    const handleChangeFilterOption = (event, newInputValue) => {

        let searchMethod = searchByPartDescription;
        
        if(props.othersInputs) {
            searchMethod = searchByPartDescriptionOthersInputs;
        }

        if(props.graos) {
            searchMethod = searchByPartDescriptionGraos;
        }

        if(props.purchaseRequest) {
            searchMethod = searchByPartDescriptionPurchaseRequest;
        }

        if(props.consume) {
            searchMethod = searchByPartDescriptionConsumeItens;
        }

        setAddInputValue(newInputValue.toUpperCase());

        if (newInputValue.length >= 3) {
            searchMethod(newInputValue.toUpperCase())
                .then((result => {
                    if (result) {
                        setListItems(result)
                    }
                }))
        } else {
            setListItems([])
        }
    }

    const handleUnGpList = async (value) => {

        if (value) {
            UnitOfMeasurementGroupsByAbsEntry(value?.UoMGroupEntry)
                .then(grupoUn => {
                    let listaUnidades = ''
                    grupoUn.UoMGroupDefinitionCollection?.forEach(element => {
                        if (listaUnidades !== '') {
                            listaUnidades = listaUnidades + `,${element.AlternateUoM}`
                        } else {
                            listaUnidades = element.AlternateUoM
                        }
                    })
                    UnitOfMeasurementsList(listaUnidades)
                        .then(unidades => {
                            value.ListaUnidades = unidades
                            props.setInputItem(value, props.idx);
                        })
                })
        }
    }

    return (
        <>
            <Autocomplete
                key="itemautocomplete"
                style={{ width: width }}
                value={props.addItemField}
                getOptionSelected={(option, value) => {
                    return option.ItemCode === value.ItemCode;
                }}
                getOptionLabel={(option) => option.ItemCode ? option.ItemCode + ' - ' + option.ItemName : ''}
                onChange={(event, value) => { handleUnGpList(value) }}
                inputValue={addInputValue}
                onInputChange={handleChangeFilterOption}
                options={listItems}
                fullWidth
                size="small"
                renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
        </>
    );
}

export default ItemsAutocomplete;