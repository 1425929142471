import React, { useState } from 'react';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import {
    Paper, IconButton, TableCell, TableRow, TableBody, Table,
    TableContainer, TableHead, Typography, Grid, Button, LinearProgress
} from '@material-ui/core';
import FileUpload from '../FileUpload/FileUpload';
import { createAttachment, updateAttachment } from '../../services/AttachmentController';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import { useHistory } from "react-router-dom";

export default function AttachmentUpdate(props) {

    let history = useHistory();

    const [fileList, setFileList] = useState([]);
    const [progressBar, setProgressBar] = useState(true);
    const classes = CreateAndViewStyle();

    const handleFileAdd = (file) => {

        if (file) {
            const tempFiles = [...fileList, file];
            setFileList(tempFiles);
        }
    }

    const handleDeleteFile = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...fileList];
        newItems.splice(index, 1);
        setFileList(newItems);
    }

    const sendAttachments = async () => {

        if (fileList.length > 0) {

            let formData = new FormData();

            await fileList.forEach((file, index) => {
                formData.append(`file_${index}`, file, file.name);
            });

            return await createAttachment(formData)
                .then((result) => {
                    return result;
                })
                .catch((error) => {
                    console.log('Caiu no error')
                    return error;
                });
        }
    }

    const updateAttachments = async () => {

        if (fileList.length > 0) {

            let formData = new FormData();

            await fileList.forEach((file, index) => {
                formData.append(`file_${index}`, file, file.name);
            });

            return await updateAttachment(formData, props.AttachmentEntry)
                .then((result) => {
                    return { status: 204 };
                })
                .catch((error) => {

                    return error;
                });
        }
    }



    const handleAttachments = async (event) => {

        setProgressBar(false);

        // Documento já possui anexos
        if (props.AttachmentEntry) {

            const attachmentResult = await updateAttachments();
            if (attachmentResult.status === 204) {
                alert('ANEXOS ATUALIZADOS COM SUCESSO!');                
                history.push(props.DocumentListLink);
            }
            // Documento não possui anexos
        } else {
            console.log('Cria registro de anexo e atualiza Documento')
            const attachmentResult = await sendAttachments();

            if(attachmentResult) {
                
                const documentAttResult = await props.UpdateDocumentFunction(props.DocEntry, attachmentResult.AbsoluteEntry);
                console.log(documentAttResult)
                if (documentAttResult.status === 204) {
                    alert('ANEXOS ATUALIZADOS COM SUCESSO!');                
                    history.push(props.DocumentListLink);
                }
            }

        }

        setProgressBar(true);
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Adicionar Anexo
                    </Typography>
                </Grid>
            </Paper>
            <FileUpload
                handleFileAdd={handleFileAdd}
            />
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Novos Arquivos</TableCell>
                            <TableCell>Excluir</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fileList.map((file, index) => {
                            return (
                                <TableRow
                                    key={uuid()}
                                >
                                    <TableCell>{file ? file.name : ''}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            component="span"
                                            data-key={index}
                                            onClick={handleDeleteFile}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                color="primary"
                className={classes.button}
                variant="contained"
                disabled={fileList.length === 0}
                onClick={handleAttachments}
            >
                Adicionar Anexos
            </Button>
            <Grid item xs={12}>
                <LinearProgress hidden={progressBar} />
            </Grid>
        </>
    )
}

