import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findAll } from './PurchaseDeliveryNotesController';
import dateFormat from '../../services/dateFormat';
import moneyFormat from '../../services/moneyFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';
import status from './purchaseDeliveryStatus';

const PurchaseDeliveryNotesList = ({ history }) => {

    const columns = [
        {
            field: 'id',
            headerName: '#',
            width: 90,
        },
        {
            field: 'DocNum',
            headerName: 'Nº',
            width: 100,
        },
        {
            field: 'SequenceSerial',
            headerName: 'Nº NF',
            width: 140,
        },
        {
            field: 'DocDate',
            headerName: 'Data',
            width: 120,
        },
        {
            field: 'CardName',
            headerName: 'Fornecedor',
            width: 400,
        },
        {
            field: 'DocTotal',
            headerName: 'Valor R$',
            width: 150,
            type: 'number'
        },
        {
            field: 'DocumentStatus',
            headerName: 'Status',
            width: 120,
        },
        {
            field: 'BPLName',
            headerName: 'Filial',
            width: 300,
        },
        {
            field: 'Comments',
            headerName: 'Observações',
            width: 1000,
        }
    ];

    const useStyles = ListDocumentsStyle;

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const result = await findAll();
            
            if (result) {
                let final = result.map((item, index) => {

                    const docStatus = status(item.DocumentStatus, item.Cancelled);
                    
                    return {
                        id: item.DocEntry,
                        DocNum: item.DocNum,
                        DocDate: dateFormat(item.DocDate),
                        CardName: item.CardCode + ' - ' + item.CardName,
                        DocTotal: moneyFormat(item.DocTotal),
                        DocumentStatus: docStatus,
                        BPLName: item.BPLName,
                        Comments: item.Comments,
                        SequenceSerial: item.SequenceSerial
                    }
                })

                setRowsData(final);
            }
        }

        fetchData();
    }, []);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Recebimentos de Mercadoria
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    onRowClick={(event) => {
                        history.push(`/home/purchaseDeliveryNotes/view/${event.row.id}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/purchaseDeliveryNotes/openPurchaseOrders'}>
                            Novo Recebimento de Mercadorias
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default PurchaseDeliveryNotesList;