import api from '../services/api'

export const SeriesService_GetDocumentSeriesList = async (doc, stype) => {

    const options = {
        method: 'GET',
        url: `/SeriesService_GetDocumentSeriesList/${doc}/${stype}`,
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const UnitOfMeasurementGroupsList = async () => {

    const options = {
        method: 'GET',
        url: '/UnitOfMeasurementGroupsList',
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const UnitOfMeasurementGroupsByAbsEntry = async (AbsEntry) => {

    const options = {
        method: 'GET',
        url: `/UnitOfMeasurementGroupsByAbsEntry/${AbsEntry}`,
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then((response) => {
        return response.data
    }).catch((error) => {
        console.error(error)
    })
}

export const UnitOfMeasurementsService_GetList = async () => {

    const options = {
        method: 'GET',
        url: '/UnitOfMeasurementsService_GetList',
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const UnitOfMeasurementsList = async (listUnCode) => {

    const options = {
        method: 'POST',
        url: `/UnitOfMeasurementsList/listByGroup`,
        headers: { 'Content-Type': 'application/json' },
        data: {codes: listUnCode}
    }

    return api.request(options).then((response) => {
        return response.data
    }).catch((error) => {
        console.error(error)
    })
}

export const ItemGroupList = async () => {

    const options = {
        method: 'GET',
        url: '/ItemsGroup',
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const NCMCodesSetupList = async (ncm) => {

    const options = {
        method: 'GET',
        url: `/NCMCodesSetupList/${ncm}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const WarehouseLocations = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

    const options = {
        method: 'POST',
        url: '/WarehouseLocations',
        headers: { 'Content-Type': 'application/json' },
        data: branchs
    }

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const Safra = async () => {

    const options = {
        method: 'GET',
        url: '/Safra',
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const Cultura = async () => {

    const options = {
        method: 'GET',
        url: '/Cultura',
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}


export const EmployeesInfo = async (locationid) => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'))
    const options = {
        method: 'POST',
        url: `/EmployeesInfo/${locationid}`,
        headers: { 'Content-Type': 'application/json' },
        data: branchs
    }

    return api.request(options).then((response) => {
        return response.data
    }).catch((error) => {
        console.error(error)
    })
}

export const OperacoesAgricolas = async () => {

    const options = {
        method: 'GET',
        url: '/OperacoesAgricolas',
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const Talhoes = async (location) => {

    const options = {
        method: 'GET',
        url: `/Talhoes/${location}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const WarehouseToBranchList = async (location) => {

    const options = {
        method: 'GET',
        url: `/WarehouseToBranchList`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}

export const findOneSafra = async (Code) => {

    const options = {
        method: 'GET',
        url: `/Safra/${Code}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        if(response.data.value[0])
            return response.data.value[0]
    }).catch((error) => {
        console.error(error)
    })
}

export const findOneCultura = async (Code) => {

    const options = {
        method: 'GET',
        url: `/Cultura/${Code}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        if(response.data.value[0])
            return response.data.value[0]
    }).catch((error) => {
        console.error(error)
    })
}


export const findOneEmployeesInfo = async (Code) => {

    const options = {
        method: 'GET',
        url: `/EmployeesInfo/findOne/${Code}`,
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then((response) => {
        if(response.data.value[0])
        return response.data.value[0]
    }).catch((error) => {
        console.error(error)
    })
}

export const findOneProfitCenter = async (Code) => {

    const options = {
        method: 'GET',
        url: `/ProfitCenters/${Code}`,
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then((response) => {
        if(response.data.value[0])
            return response.data.value[0]
    }).catch((error) => {
        console.error(error)
    })
}