import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findOpenPurchaseOrders, findOpenApReserveInvoices } from './PurchaseDeliveryNotesController';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';
import dateFormat from '../../services/dateFormat';
import moneyFormat from '../../services/moneyFormat';

const ViewOpenPurchaseOrders = ({ history }) => {

    const columns = [
        // {
        //     field: 'id',
        //     headerName: '#',
        //     width: 90,
        // },
        {
            field: 'Type',
            headerName: 'Tipo',
            width: 150,
        },
        {
            field: 'DocNum',
            headerName: 'Nº SAP',
            width: 130,
        },
        {
            field: 'ImportFileNum',
            headerName: 'Nº Ped. Fornec.',
            width: 200,
        },
        {
            field: 'DocDate',
            headerName: 'Data',
            width: 120,
        },
        {
            field: 'CardName',
            headerName: 'Fornecedor',
            width: 650,
        },
        {
            field: 'DocTotal',
            headerName: 'Valor R$',
            width: 200,
            type: 'number'
        },
        {
            field: 'BPLName',
            headerName: 'Filial',
            width: 300,
        },
        {
            field: 'Comments',
            headerName: 'Observações',
            width: 300,
        }
    ];

    const useStyles = ListDocumentsStyle;

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const result = await findOpenPurchaseOrders();
            const invoicesResult = await findOpenApReserveInvoices();
         
            let purchaseOrders = [];
            let apInvoices = [];

            if (result) {
                purchaseOrders = result.map((item, index) => {
                    return {
                        id: `PC-${item.DocEntry}`,
                        Type: 'Pedido de Compras',
                        DocNum: item.DocNum,
                        ImportFileNum: item.ImportFileNum ? 'PC: ' + item.ImportFileNum : '',
                        DocDate: dateFormat(item.DocDate),
                        SortDate: new Date(item.DocDate),
                        CardName: item.CardCode + ' - ' + item.CardName + ' - ' + item.FederalTaxID,
                        DocTotal: moneyFormat(item.DocTotal),
                        BPLName: item.BPLName,
                        ItemDescription: item.Comments,
                    }
                })

            }
            
            if (invoicesResult) {
                apInvoices = invoicesResult.map((item, index) => {
                    return {
                        id: `NF-${item.DocEntry}`,
                        Type: 'NF Rec. Futuro',
                        DocNum: item.DocNum,
                        ImportFileNum: 'NF: ' + item.SequenceSerial + (item.ImportFileNum ? ' - PC: ' + item.ImportFileNum : ''),
                        DocDate: dateFormat(item.DocDate),
                        SortDate: new Date(item.DocDate),
                        CardName: item.CardCode + ' - ' + item.CardName + ' - ' + item.FederalTaxID,
                        DocTotal: moneyFormat(item.DocTotal),
                        BPLName: item.BPLName,
                        ItemDescription: item.Comments,
                    }
                })

            }
            const tableList = purchaseOrders.concat(apInvoices);
            const sortedTableList = tableList.sort((a, b) => b.SortDate - a.SortDate)
            
            setRowsData(sortedTableList);
        }


        fetchData();
    }, []);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Recebimentos de Mercadoria - Adicionar
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>
                <Typography variant="h6">Selecione um Pedido de Compras Aberto ou NF de Recebimento Futuro  para Receber as Mercadorias:</Typography>
                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    onRowClick={(event) => {
                        history.push(`/home/purchaseDeliveryNotes/viewPurchaseOrders/create/${event.row.id}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/purchaseDeliveryNotes'}>
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default ViewOpenPurchaseOrders;