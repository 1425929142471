import api from '../../services/api';

export const findByCode = async (id) => {

    const options = {
        method: 'GET',
        url: `/Items/${id}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data;
    }).catch(function (error) {

        console.error(error);
    });
};

export const searchByDescription = async (description) => {

    const options = {
        method: 'GET',
        url: `/Items/searchpart/purchaseRequest/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};

export const searchByPartDescription = async (description) => {

    const options = {
        method: 'GET',
        url: `/Items/searchpart/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};

export const searchByPartDescriptionFuelAndLubrification = async (groupcode, description) => {

    const options = {
        method: 'GET',
        url: `/Items/FuelAndLubrification/${groupcode}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};

export const searchByPartDescriptionOnHand = async (description) => {

    const options = {
        method: 'GET',
        url: `/Items/searchpart/onStock/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};

export const equipments = async (locationId, description) => {

    const options = {
        method: 'GET',
        url: `/Equipments/${locationId}/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};

export const searchByPartDescriptionOthersInputs = async (description) => {

    const options = {
        method: 'GET',
        url: `/Items/searchpart/othersInputs/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};

export const searchByPartDescriptionConsumeItens = async (description) => {

    const options = {
        method: 'GET',
        url: `/Items/searchpart/consume/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};