import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from './logoBase64';
import dateFormat from '../../../services/dateFormat';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const printPDF = (obj) => {

    console.log(obj)

    const DATA = dateFormat(obj.Detalhes.CreateDate) ?? '';
    const HORA = obj.Detalhes.CreateTime ?? '';
    const NUMERO_ROMANEIO = obj.Detalhes.DocEntry ?? '';
    const CCG = obj.Transfer.U_CCG ?? '';
    const FILIAL = obj.Transfer.BPLName ?? '';
    const INSCRICAO_ESTADUAL = obj.Filial.FederalTaxID2;
    const ENDERECO = `${obj.Filial.AddressType ?? ''} ${obj.Filial.Street ?? ''}, ${obj.Filial.StreetNo ?? ''} ${obj.Filial.Building ?? ''} - ${obj.Filial.Block ?? ''} - ${obj.Filial.ZipCode?? ''}`;
    const CIDADE = `${obj.Filial.City}-${obj.Filial.State}`;
    const OPERACAO = `SAÍDA - ${obj.Transfer.StockTransferLines[0].ItemDescription ?? ''}`;
    const DEPOSITO = obj.Transfer.StockTransferLines[0].WarehouseCode ?? '';
    const ATIVIDADE = obj.Transfer.StockTransferLines[0].ItemDescription ?? '';
    const PRODUTO = obj.Transfer.StockTransferLines[0].ItemDescription ?? '';
    const CULTIVAR = obj.Transfer.StockTransferLines[0].ItemDescription ?? '';
    //const PRODUTOR_COOPERANTE =  obj.Transfer.ToWarehouse ?? '';
    //const CNPJ = '';
    //const ENDERECO_CLIENTE = '';
    //const CIDADE_CLIENTE = '';
    const PESO_TOTAL = `${(obj.Transfer.U_PesoBruto).toFixed(2)} KG`;
    const PESO_TARA = `${obj.Transfer.U_PesoTara.toFixed(2)} KG`;
    const PESO_NETO = `${obj.Transfer.U_PesoNeto.toFixed(2)} KG`;
    //const PESO_BRUTO = `${obj.Transfer.U_PesoBruto.toFixed(2)} KG`;
    const DEFEITO1_NOME = obj.Classificacao[0]?.U_Description ?? '';
    const DEFEITO2_NOME = obj.Classificacao[1]?.U_Description ?? '';
    const DEFEITO1 = `${obj.Classificacao[0].U_DescontoKg} KG`;
    const DEFEITO2 = `${obj.Classificacao[1].U_DescontoKg} KG`;
    const DEFEITO1_GRAU = `${obj.Classificacao[0].U_Value} %`;
    const DEFEITO2_GRAU = `${obj.Classificacao[1].U_Value} %`;
    const DEFEITO1_PERCENTUAL_DESCONTO = '';
    const DEFEITO2_PERCENTUAL_DESCONTO = '';
    const TOTAL_DESCONTO = `${obj.Transfer.U_DescontoKg.toFixed(2)} KG`;
    const PESO_LIQUIDO = `${obj.Transfer.U_PesoLiquido.toFixed(2)} KG`;
    const QUANTIDADE_EM_SACAS = `${(obj.Transfer.U_PesoLiquido/60).toFixed(2)} SC`;
    const MOTORISTA = obj.Transfer.U_Motorista;
    const PLACA = obj.Detalhes.U_Placa ?? '';
    const OBSERVACAO = obj.Transfer.Comments;
    const TRANSPORTADORA = obj.Detalhes.U_Transportadora ? obj.Detalhes.U_Transportadora.slice(12) : '';



    var docDefinition = {
        defaultStyle: {
            fontSize: 8
        },
        pageSize: 'A4',
        pageMargins: [ 25, 25, 25, 25 ],
        content: [


            {
                table: {

                    widths: ['*'],
                    heights: [40, 24, 2, 24, 12, 12, 100, 40],
                    body: [

                        [{
                            table: {
                                widths: [70, 160, 55, 20, 60, 40, 50],
                                heights: [20, 20, 20, 20, 20, 20, 20],
                                body: [
                                    [
                                        {
                                            rowSpan: 2,
                                            image: logo,
                                            width: 40,
                                            height: 40,
                                        },
                                        {
                                            text: 'Data:',
                                            bold: true,
                                            alignment: 'right'
                                        },
                                        DATA,
                                        {
                                            text: 'HR:',
                                            bold: true,
                                            alignment: 'right'
                                        },
                                        HORA,
                                        {
                                            text: 'CCG:',
                                            bold: true,
                                        },
                                        CCG
                                    ],
                                    [
                                        '',
                                        {
                                            colSpan: 4,
                                            text: FILIAL
                                        },
                                        '',
                                        '',
                                        '',
                                        {
                                            text: 'Nº Rom.:',
                                            bold: true,
                                        },
                                        NUMERO_ROMANEIO
                                    ],
                                ]
                            },
                            layout: 'noBorders'
                        }],
                        [{
                            table: {
                                widths: [70, 150, 40, 200],
                                heights: [10, 10, 10, 10],
                                body: [
                                    [
                                        {
                                            text: 'Inscrição Estadual:',
                                            bold: true,
                                        },
                                        INSCRICAO_ESTADUAL,
                                        {
                                            text: 'Endereço:',
                                            bold: true,
                                        },
                                        ENDERECO
                                    ],
                                    [
                                        {
                                            text: 'Cidade:',
                                            bold: true,
                                        },
                                        CIDADE,
                                        {
                                            text: 'Operação:',
                                            bold: true,
                                        },
                                        OPERACAO
                                    ],
                                ]
                            },
                            layout: 'noBorders'

                        }],
                        [''],
                        [{
                            table: {
                                widths: [200, '*', '*', '*'],
                                heights: [10, 10, 10, 10],
                                body: [
                                    [
                                        {
                                            text: 'Depósito:',
                                            bold: true,
                                        },
                                        {
                                            text: 'Atividade:',
                                            bold: true,
                                        },
                                        {
                                            text: 'Produto:',
                                            bold: true,
                                        },
                                        {
                                            text: 'Cultivar:',
                                            bold: true,
                                        },

                                    ],
                                    [
                                        DEPOSITO,
                                        ATIVIDADE,
                                        PRODUTO,
                                        CULTIVAR
                                    ]
                                ],
                            },
                            layout: 'noBorders'

                        }],


                        [{
                            table: {
                                widths: [40, 90, 80, 70, 80, 90],
                                heights: [10, 10, 10, 10, 10, 10],
                                body: [
                                    [
                                        '',
                                        {
                                            text: 'Peso Bruto:',
                                            bold: true,
                                        },
                                        PESO_TOTAL,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Peso Tara:',
                                            bold: true,
                                        },
                                        PESO_TARA,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Peso Neto:',
                                            bold: true,
                                        },
                                        PESO_NETO,
                                        '',
                                        {
                                            text: 'Grau',
                                            bold: true,
                                        },
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: `${DEFEITO1_NOME}:`,
                                            bold: true,
                                        },
                                        DEFEITO1,
                                        '',
                                        DEFEITO1_GRAU,
                                        DEFEITO1_PERCENTUAL_DESCONTO,

                                    ],
                                    [
                                        '',
                                        {
                                            text: `${DEFEITO2_NOME}:`,
                                            bold: true,
                                        },
                                        DEFEITO2,
                                        '',
                                        DEFEITO2_GRAU,
                                        DEFEITO2_PERCENTUAL_DESCONTO,

                                    ],
                                    [
                                        '',
                                        '',
                                        '',
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Total Descontos:',
                                            bold: true,
                                        },
                                        TOTAL_DESCONTO,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Peso Liquido:',
                                            bold: true,
                                        },
                                        PESO_LIQUIDO,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Qtd. Sacas:',
                                            bold: true,
                                        },
                                        QUANTIDADE_EM_SACAS,
                                        '',
                                        '',
                                        '',

                                    ],
                                ],
                            },
                            layout: 'noBorders'

                        }],                       
                        [{
                            table: {
                                widths: [70, 240, '*'],
                                heights: [10, 10, 10, ],
                                body: [
                                    [
                                        {
                                            text: 'Motorista:',
                                            bold: true,
                                        },
                                        MOTORISTA,
                                        ''

                                    ],
                                    [
                                        {
                                            text: 'Placa:',
                                            bold: true,
                                        },
                                        PLACA,
                                        '___________________________________________________________'

                                    ],
                                    [
                                        {
                                            text: 'Transportadora:',
                                            bold: true,
                                        },
                                        TRANSPORTADORA,
                                        'Responsável'
                                    ],
                                    [
                                        {
                                            text: 'Observação:',
                                            bold: true,
                                        },
                                        {
                                            rowSpan: 3,
                                            text: OBSERVACAO
                                        },
                                        ''

                                    ],
                                    [
                                        '',
                                        '',
                                        '___________________________________________________________'
                                    ],
                                    [
                                        '',
                                        '',
                                        'Assinatura'

                                    ],
                                ],
                            },
                            layout: 'noBorders'

                        }],                       


                    ]
                },                
            },

            {
                table: {

                    widths: ['*'],
                    heights: [40, 24, 2, 24, 12, 12, 100, 40],
                    body: [

                        [{
                            table: {
                                widths: [70, 160, 55, 20, 60, 40, 50],
                                heights: [20, 20, 20, 20, 20, 20, 20],
                                body: [
                                    [
                                        {
                                            rowSpan: 2,
                                            image: logo,
                                            width: 40,
                                            height: 40,
                                        },
                                        {
                                            text: 'Data:',
                                            bold: true,
                                            alignment: 'right'
                                        },
                                        DATA,
                                        {
                                            text: 'HR:',
                                            bold: true,
                                            alignment: 'right'
                                        },
                                        HORA,
                                        {
                                            text: 'CCG:',
                                            bold: true,
                                        },
                                        CCG
                                    ],
                                    [
                                        '',
                                        {
                                            colSpan: 4,
                                            text: FILIAL
                                        },
                                        '',
                                        '',
                                        '',
                                        {
                                            text: 'Nº Rom.:',
                                            bold: true,
                                        },
                                        NUMERO_ROMANEIO
                                    ],
                                ]
                            },
                            layout: 'noBorders'
                        }],
                        [{
                            table: {
                                widths: [70, 150, 40, 200],
                                heights: [10, 10, 10, 10],
                                body: [
                                    [
                                        {
                                            text: 'Inscrição Estadual:',
                                            bold: true,
                                        },
                                        INSCRICAO_ESTADUAL,
                                        {
                                            text: 'Endereço:',
                                            bold: true,
                                        },
                                        ENDERECO
                                    ],
                                    [
                                        {
                                            text: 'Cidade:',
                                            bold: true,
                                        },
                                        CIDADE,
                                        {
                                            text: 'Operação:',
                                            bold: true,
                                        },
                                        OPERACAO
                                    ],
                                ]
                            },
                            layout: 'noBorders'

                        }],
                        [''],
                        [{
                            table: {
                                widths: [200, '*', '*', '*'],
                                heights: [10, 10, 10, 10],
                                body: [
                                    [
                                        {
                                            text: 'Depósito:',
                                            bold: true,
                                        },
                                        {
                                            text: 'Atividade:',
                                            bold: true,
                                        },
                                        {
                                            text: 'Produto:',
                                            bold: true,
                                        },
                                        {
                                            text: 'Cultivar:',
                                            bold: true,
                                        },

                                    ],
                                    [
                                        DEPOSITO,
                                        ATIVIDADE,
                                        PRODUTO,
                                        CULTIVAR
                                    ]
                                ],
                            },
                            layout: 'noBorders'

                        }],

 
                        [{
                            table: {
                                widths: [40, 90, 80, 70, 80, 90],
                                heights: [10, 10, 10, 10, 10, 10],
                                body: [
                                    [
                                        '',
                                        {
                                            text: 'Peso Bruto:',
                                            bold: true,
                                        },
                                        PESO_TOTAL,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Peso Tara:',
                                            bold: true,
                                        },
                                        PESO_TARA,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Peso Neto:',
                                            bold: true,
                                        },
                                        PESO_NETO,
                                        '',
                                        {
                                            text: 'Grau',
                                            bold: true,
                                        },
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: `${DEFEITO1_NOME}:`,
                                            bold: true,
                                        },
                                        DEFEITO1,
                                        '',
                                        DEFEITO1_GRAU,
                                        DEFEITO1_PERCENTUAL_DESCONTO,

                                    ],
                                    [
                                        '',
                                        {
                                            text: `${DEFEITO2_NOME}:`,
                                            bold: true,
                                        },
                                        DEFEITO2,
                                        '',
                                        DEFEITO2_GRAU,
                                        DEFEITO2_PERCENTUAL_DESCONTO,

                                    ],
                                    [
                                        '',
                                        '',
                                        '',
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Total Descontos:',
                                            bold: true,
                                        },
                                        TOTAL_DESCONTO,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Peso Liquido:',
                                            bold: true,
                                        },
                                        PESO_LIQUIDO,
                                        '',
                                        '',
                                        '',

                                    ],
                                    [
                                        '',
                                        {
                                            text: 'Qtd. Sacas:',
                                            bold: true,
                                        },
                                        QUANTIDADE_EM_SACAS,
                                        '',
                                        '',
                                        '',

                                    ],
                                ],
                            },
                            layout: 'noBorders'

                        }],                       
                        [{
                            table: {
                                widths: [70, 240, '*'],
                                heights: [10, 10, 10, ],
                                body: [
                                    [
                                        {
                                            text: 'Motorista:',
                                            bold: true,
                                        },
                                        MOTORISTA,
                                        ''

                                    ],
                                    [
                                        {
                                            text: 'Placa:',
                                            bold: true,
                                        },
                                        PLACA,
                                        '___________________________________________________________'

                                    ],
                                    [
                                        {
                                            text: 'Transportadora:',
                                            bold: true,
                                        },
                                        TRANSPORTADORA,
                                        'Responsável'
                                    ],
                                    [
                                        {
                                            text: 'Observação:',
                                            bold: true,
                                        },
                                        {
                                            rowSpan: 3,
                                            text: OBSERVACAO
                                        },
                                        ''

                                    ],
                                    [
                                        '',
                                        '',
                                        ''
                                    ],
                                    [
                                        '',
                                        '',
                                        '___________________________________________________________'
                                    ],
                                    [
                                        '',
                                        '',
                                        'Assinatura'

                                    ],
                                ],
                            },
                            layout: 'noBorders'

                        }],                       


                    ]
                },                
            },
        ]
    };

    pdfMake.createPdf(docDefinition).open();
}

export default printPDF;