import React, { useState } from 'react';
import {
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Typography,
    FormControl, Select, MenuItem,
} from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';

const ItemData = (props) => {

    const classes = CreateAndViewStyle();

    const [talhoes, setTalhoes] = useState([]);
    const [disableVarieties, setDisableVarieties] = useState(true);
    const [varieties, setVarieties] = useState([]);
    const [totalQtdeTalhoes, setTotalQtdeTalhoes] = useState(0.0)
    const [classificationList, setClassificationList] = useState([]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Dados do Item</Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="item"
                        label="Item"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.DocumentLines ? `${props.invoice.DocumentLines[0].ItemCode} - ${props.invoice.DocumentLines[0].ItemDescription}` : ''}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="um"
                        label="U.M."
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.DocumentLines ? props.invoice.DocumentLines[0].MeasureUnit : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Dados de Pesagem</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="ticket"
                        label="Nº Ticket"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.U_NumeroTicket}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="ccg"
                        label="CCG"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.U_CCG}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="pesoBruto"
                        label="Peso Bruto"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.U_PesoBruto}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="pesoTara"
                        label="Peso Tara"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.U_PesoTara}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="pesoNeto"
                        label="Peso Neto"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        disabled
                        value={props.transfer.U_PesoNeto}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="pesoLiquido"
                        label="Peso Liquido"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        disabled
                        value={props.transfer.U_PesoLiquido}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="desconto"
                        label="Desconto em Kilos"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.U_DescontoKg}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Classificação</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.tablehead}>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Defeito</TableCell>
                                    <TableCell>%</TableCell>
                                </TableRow>
                            </TableHead>

                            {props.detalhes.ROM_ANALISECollection &&
                                props.detalhes.ROM_ANALISECollection.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={item.U_Code}
                                                    disabled
                                                    fullWidth
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={item.U_Description}
                                                    disabled
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={item.U_Value}
                                                    disabled
                                                    fullWidth
                                                />
                                            </TableCell>
                                        </TableRow>

                                    )
                                })
                            }
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Observações"
                        multiline
                        rows={2}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={props.transfer.JournalMemo}
                        disabled
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ItemData;