import React, { useState } from 'react';
import { Button, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';


const FileUpload = (props) => {

    const classes = CreateAndViewStyle();
    const [alert, setAlert] = useState({
        display: false
    });

    const handleCloseAlert = () => {
        setAlert({
            display: false
        });
    }

    return (
    <>
 
        <Button
            variant="contained"
            component="label"
            size='small'
            color='primary'
            className={classes.button}
            >
            Selecionar arquivo
            <input
                type="file"
                hidden
                onChange={(event) => {                    
                    props.handleFileAdd(event.target.files[0]);                       
                }} 
            />
        </Button>      
        
        <br />
        <br />
        <Collapse in={alert.display}>
            <Alert 
                severity={alert.severity}
                hidden
                onClose={handleCloseAlert}
            >
                {alert.message}
            </Alert>
        </Collapse>
    </>
    );
}

export default FileUpload;