import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import referenceid from '../../services/referenceid'
import {
    Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
    Tabs, Tab, AppBar, Button, LinearProgress, Collapse, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import ItemsAutocomplete from './ItemsAutocomplete';
import { v4 as uuid } from 'uuid';
import TabPanel from '../../components/TabPanel';
import { WarehouseLocations, Safra, Cultura, OperacoesAgricolas, Talhoes, WarehouseToBranchList } from '../DefaultController';
import { findWarehouseByLocation, create } from './AgriculturalOperationsController';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const AgriculturalOperations = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [documentInitialDate, setDocumentInitialDate] = useState(getTodayDate());
    const [documentFinalDate, setDocumentFinalDate] = useState(getTodayDate());
    const [initialHour, setInitialHour] = useState('');
    const [finalHour, setFinalHour] = useState('');
    const [operationList, setOperationList] = useState([]);
    const [operation, setOperation] = useState('');
    const [comments, setComments] = useState('');
    const [progressBar, setProgressBar] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [items, setItems] = useState([{}]);
    const [selectedTalhao, setSelectedTalhao] = useState({});
    const [selectedCulture, setSelectedCulture] = useState('');
    const [selectedVariety, setSelectedVariety] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [talhaoList, setTalhaoList] = useState([]);
    const [cultures, setCultures] = useState([]);
    const [varieties, setVarieties] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [safraList, setSafraList] = useState([]);
    const [safra, setSafra] = useState('');
    const [disableVarieties, setDisableVarieties] = useState(true);
    const [disableInputs, setDisableInputs] = useState(true);
    const [warehouseToBranchList, setWarehouseToBranchList] = useState([]);
    const [rda, setRda] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {
            const loc = await WarehouseLocations()
            setLocationsList(loc);

            const op = await OperacoesAgricolas();
            setOperationList(op);

            const sf = await Safra()
            setSafraList(sf)

            const ct = await Cultura()
            setCultures(ct)

            const whs = await WarehouseToBranchList()
            setWarehouseToBranchList(whs)
        };
        fetchData();
    }, []);

    const handleNewItem = () => {
        setSubmitDisabled(false);
        setItems([...items, { ItemCode: '', ItemName: '', InventoryUOM: '', Quantity: 0 }]);
    };

    const handleDeleteItem = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    const setInputItem = (item, index) => {
        if (item) {

            let tempItems = [...items];
            tempItems[index].ItemCode = item.ItemCode;
            tempItems[index].ItemName = item.ItemName;
            tempItems[index].InventoryUOM = item.InventoryUOM;
            setItems(tempItems);
        }
    }

    const filterTalhoes = async (location) => {
        const talhoes = await Talhoes(location);
        setTalhaoList(talhoes[0].TALHOESCollection)
    }

    const handleCulture = (event) => {
        setSelectedCulture(event.target.value);
        const tempCulture = cultures.filter((atual) => {
            if (atual.Code === event.target.value)
                return atual;
        })
        if (tempCulture[0].VARIEDADESCollection.length > 0) {
            setDisableVarieties(false);
            setVarieties(tempCulture[0].VARIEDADESCollection)
        } else {
            setDisableVarieties(true);
            setVarieties([]);
        }
    }

    const searchItemWarehouses = async (item, index) => {
        console.log(item)
        if (item) {
            let tempItems = [...items];
            tempItems[index].DepositosCusto = item.ItemWarehouseInfoCollection;

            const deps = await findWarehouseByLocation(item.ItemCode, selectedLocation);
            tempItems[index].ListaDepositos = deps.value;
            setItems([...tempItems]);

        }
    }

    const mapWarehouseToBranch = (WhsCode) => {
        let result = null;
        warehouseToBranchList.forEach((value) => {
            if (value.WarehouseCode === WhsCode)
                result = value.BusinessPlaceID
        })
        return result;
    }

    const validateStocks = () => {

        if (items) {

            for (let i = 0; i < items.length; i++) {

                for (let j = 0; j < items[i]?.ListaDepositos?.length; j++) {
                    let item = items[i].ListaDepositos[j]["Items/ItemWarehouseInfoCollection"];
                    if (items[i].WarehouseCode === item.WarehouseCode) {

                        if (items[i].Quantity > item.InStock) {
                            setError({ isError: true, errorMessage: [`Quantidade maior que saldo disponível na linha [${++i}]`] });
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    const groupItensByWarehouse = (itemList) => {

        let groupedList = []
        let warehouseList = []

        itemList.forEach(item => {
            if (!warehouseList.includes(item.WarehouseCode)) {
                warehouseList.push(item.WarehouseCode)
            }
        })

        for (let i = 0; i < warehouseList.length; i++) {
            let tempWhsItems = []
            for (let j = 0; j < itemList.length; j++) {
                if (warehouseList[i] === itemList[j].WarehouseCode) {
                    let tmpItem = itemList[j]
                    tmpItem.DepositosCusto = []
                    tempWhsItems.push(tmpItem)
                }
            }
            groupedList.push(tempWhsItems)
        }

        return groupedList

    }

    const createInventoryExitList = (list, referenceid) => {

        const commomFields = {
            DocDate: documentInitialDate,
            U_OPAgriDtIni: documentInitialDate,
            U_OPAgriDtFin: documentFinalDate,
            U_OPAgriHIni: initialHour,
            U_OPAgriHFin: finalHour,
            Comments: comments,
            U_Talhao: selectedTalhao.U_TalhaoCode,
            U_Safra: safra,
            U_Cultura: selectedCulture,
            U_Variedade: selectedVariety,
            U_AreaRealizada: quantity,
            U_OperacaoAgricola: operation,
            JournalMemo: `B2Agri - Operações agrícolas (${referenceid})`,
            U_B2Obj: 'B2AGRI_AGRIOPER',
            Reference2: referenceid,
            U_RDA: rda
        };

        let objectList = []

        list.forEach(item => {
            let obj = { ...commomFields }
            obj.BPL_IDAssignedToInvoice = item[0].Branch
            obj.DocumentLines = item

            objectList.push(obj)
        })

        return objectList
    }

    const makeRequests = async () => {
        const refid = referenceid();

        const groupedItensByWarehouse = groupItensByWarehouse(items)
        const exitsGroupedList = createInventoryExitList(groupedItensByWarehouse, refid)


        for (let i = 0; i < exitsGroupedList.length; i++) {

            const result = await create(exitsGroupedList[i]);
            
            if (result.data.error) {
                setError({ isError: true, errorMessage: [result.data.error.message.value] });
                return false;
            }
        }

        return true
    }

    const handleSubmit = async () => {

        setProgressBar(false);
        setSubmitDisabled(true);

        if (validateStocks()) {

            const result = await makeRequests();

            if (result) {
                alert('APONTAMENTO REALIZADO COM SUCESSO!');
                history.push('/home/agriculturalOperations/list');
                setProgressBar(true);
            }
        }

        setProgressBar(true);
        setSubmitDisabled(false);
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Operações Agrícolas
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                    <Tab label="Área" value={2} />
                    <Tab label="Insumos" value={3} disabled={disableInputs} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                id="date"
                                label="Data Inicial"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentInitialDate}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                    setDocumentInitialDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="initialHour"
                                label="Hora Inicial"
                                type="time"
                                value={initialHour}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                    setInitialHour(event.target.value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="date"
                                label="Data Final"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentFinalDate}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                    setDocumentFinalDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="finalHour"
                                label="Hora Final"
                                type="time"
                                value={finalHour}
                                variant="outlined"
                                size="small"
                                style={{ width: "100%" }}
                                onChange={(event) => {
                                    setFinalHour(event.target.value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Localização</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="localizacao"
                                    id="localizacao"
                                    label="Local de Produção"
                                    value={selectedLocation}
                                    onChange={(event) => {
                                        setSelectedLocation(event.target.value)
                                        setDisableInputs(false);
                                        setItems([{}]);
                                        filterTalhoes(event.target.value)
                                    }}
                                >
                                    {locationsList.map(location => {
                                        return (
                                            <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth size="small" style={{ width: "100%" }}>
                                <InputLabel id="operation">Operação</InputLabel>
                                <Select
                                    labelId="operation"
                                    id="operation"
                                    label="Operação"
                                    defaultValue=""
                                    value={operation}
                                    onChange={event => {
                                        setOperation(event.target.value)
                                    }}
                                >
                                    {operationList.map(operation => {
                                        return (
                                            <MenuItem value={operation.Code} key={uuid()}>{operation.Name}</MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="rda"
                                label="RDA"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={rda}
                                onChange={(event) => {
                                    setRda(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="safra-select-input">Safra</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="safra-select-input"
                                    id="safra-select"
                                    label="Safra"
                                    defaultValue=""
                                    value={safra}
                                    onChange={(event) => {
                                        setSafra(event.target.value)
                                    }}
                                >
                                    {safraList.map(item => {
                                        return (
                                            <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                        );
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="comments"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>

                    </Grid>
                </TabPanel>

                <TabPanel value={tabs} index={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="talhaoInput">Talhão</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="talhaoInput"
                                    label="Talhão"
                                    value={selectedTalhao.U_TalhaoCode}
                                    onChange={event => {
                                        const tempTalhao = talhaoList.find(obj => {
                                            return obj.U_TalhaoCode === event.target.value
                                        })
                                        setSelectedTalhao(tempTalhao);
                                    }}
                                >
                                    {talhaoList.map(talhao => {
                                        return (
                                            <MenuItem value={talhao.U_TalhaoCode} key={uuid()}>{talhao.U_TalhaoCode}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                label="Fazenda"
                                variant="outlined"
                                value={selectedTalhao.U_Produtor}
                                fullWidth
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="culturaInput">Cultura</InputLabel>
                                <Select
                                    labelId="culturaInput"
                                    label="Cultura"
                                    value={selectedCulture}
                                    onChange={event => { handleCulture(event) }}
                                >
                                    {cultures.map(culture => {
                                        return (
                                            <MenuItem value={culture.Code} key={uuid()}>{culture.Name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="variedadeInput">Variedade</InputLabel>
                                <Select
                                    labelId="variedadeInput"
                                    label="Variedade"
                                    disabled={disableVarieties}
                                    value={selectedVariety}
                                    onChange={event => {
                                        setSelectedVariety(event.target.value)
                                    }}
                                >
                                    {varieties.map(variety => {
                                        return (
                                            <MenuItem value={variety.U_Variedades} key={uuid()}>{variety.U_Variedades}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Área Total"
                                variant="outlined"
                                size="small"
                                value={selectedTalhao.U_Area}
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CurrencyTextField
                                fullWidth
                                label="Área realizada"
                                currencySymbol=""
                                decimalCharacter=","
                                digitGroupSeparator="."
                                minimumValue="0"
                                variant="outlined"
                                size="small"
                                value={quantity ?? ''}
                                onChange={(event, value) => {
                                    if (value <= selectedTalhao.U_Area && value >= 0)
                                        setQuantity(value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={tabs} index={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={16}>
                            <Button
                                variant="contained"
                                onClick={handleNewItem}
                                size='small'
                                color='primary'
                            >
                                Novo item
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Item</TableCell>
                                            <TableCell>U.M.</TableCell>
                                            <TableCell>Depósito/Estoque</TableCell>
                                            <TableCell>Quantidade</TableCell>
                                            <TableCell>Rendimento</TableCell>
                                            <TableCell>Excluir</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <ItemsAutocomplete
                                                            idx={index}
                                                            addItemField={items[index]}
                                                            setInputItem={setInputItem}
                                                            othersInputs
                                                            onExecute={searchItemWarehouses}
                                                            width={500}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <TextField
                                                            disabled
                                                            fullWidth
                                                            variant="outlined"
                                                            label="U.M."
                                                            size="small"
                                                            value={items[index].InventoryUOM}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <FormControl variant="outlined" size="small" className={classes.formControl}>
                                                            <Select
                                                                fullWidth
                                                                labelId="deposito"
                                                                id="deposito"
                                                                value={items[index].WarehouseCode ?? ''}
                                                                onChange={(event) => {
                                                                    let tempItems = [...items];
                                                                    tempItems[index].WarehouseCode = event.target.value;
                                                                    tempItems[index].Branch = mapWarehouseToBranch(event.target.value);
                                                                    setItems([...tempItems]);
                                                                }}
                                                            >
                                                                {items[index].ListaDepositos?.map(deposito => {
                                                                    return (
                                                                        <MenuItem key={uuid()} value={deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode}>{deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode + ' / ' + deposito["Items/ItemWarehouseInfoCollection"].InStock + ' ' + items[index].InventoryUOM}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <CurrencyTextField
                                                            style={{ width: 120 }}
                                                            id={index}
                                                            variant="outlined"
                                                            margin="dense"
                                                            currencySymbol=""
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            decimalPlaces={4}
                                                            minimumValue="0"
                                                            value={items[index].Quantity ?? ''}
                                                            onChange={(event, value) => {
                                                                let tempItems = [...items];
                                                                tempItems[index].Quantity = value;

                                                                if (selectedTalhao.U_Area && tempItems[index].Quantity) {
                                                                    tempItems[index].Rendimento = (tempItems[index].Quantity / quantity).toFixed(4);
                                                                }

                                                                setItems([...tempItems]);
                                                            }}
                                                            inputProps={{
                                                                'data-key': index,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <CurrencyTextField
                                                            fullWidth
                                                            variant="outlined"
                                                            currencySymbol=""
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            decimalPlaces={"4"}
                                                            minimumValue="0"
                                                            margin="dense"
                                                            label={items[index].InventoryUOM ? `${items[index].InventoryUOM}/HA` : ''}
                                                            value={items[index].Rendimento ?? ''}
                                                            onChange={(event, value) => {
                                                                let tempItems = [...items];
                                                                tempItems[index].Rendimento = value;

                                                                if (selectedTalhao.U_Area && tempItems[index].Rendimento) {
                                                                    tempItems[index].Quantity = (tempItems[index].Rendimento * quantity).toFixed(4);
                                                                }

                                                                setItems([...tempItems]);
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            data-key={index}
                                                            onClick={handleDeleteItem}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </TabPanel>

                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" disabled={submitDisabled} className={classes.button} onClick={handleSubmit}>
                        Adicionar
                    </Button>
                    <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home'}>
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    )
}

export default AgriculturalOperations;