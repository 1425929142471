import React from 'react';
import { Switch } from 'react-router-dom';

import Login from '../pages/Login';
import Layout from '../pages/Layout';
//import _404 from '../pages/_404';

import Route from './Route';

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/home" component={Layout} isPrivate />   
        </Switch>
    );
};

export default Routes;