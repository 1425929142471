import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import {
    Grid, Paper, Typography, Tabs, Tab, AppBar, Button, LinearProgress, Collapse
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import SenderData from './Components/SenderDataView'
import RecipientData from './Components/RecipientDataView';
import ItemData from './Components/ItemDataView';
import { findBrachInfo, findStockTransferByReference2, findDetalhesByReference2, findInvoiceByReference2, createStockTransfer, updateTransfer, cancelDetalhesRomaneio } from './Components/OwnShipmentController';
import { Print as PrintIcon } from '@material-ui/icons';
import printPDF from './Components/printPDF';
import Alert from '@material-ui/lab/Alert';

const OwnShipment = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    let { id } = useParams();

    const [transfer, setTransfer] = useState({});
    const [detalhes, setDetalhes] = useState({});
    const [invoice, setInvoice] = useState({});
    const [branch, setBranch] = useState({});
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [progressBar, setProgressBar] = useState(true);
    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {
            const trans = await findStockTransferByReference2(id);
            if (trans[0]) {
                setTransfer(trans[0]);
                const brc = await findBrachInfo(trans[0].BPLID);
                setBranch(brc);
            }

            const det = await findDetalhesByReference2(id);
            if (det[0]) {
                setDetalhes(det[0]);
            }

            const inv = await findInvoiceByReference2(id);
            if (inv[0]) {
                setInvoice(inv[0]);
            }

        };
        fetchData();
    }, [id]);

    const handleCancel = async () => {

        setProgressBar(false);

        let obj = {
            BPLID: transfer.BPLID,
            Reference2: transfer.Reference2,
            ToWarehouse: transfer.FromWarehouse,
            FromWarehouse: transfer.ToWarehouse,
            U_PesoBruto: transfer.U_PesoBruto,
            U_PesoLiquido: transfer.U_PesoLiquido,
            U_PesoTara: transfer.U_PesoTara,
            U_PesoNeto: transfer.U_PesoNeto,
            U_NumeroTicket: transfer.U_NumeroTicket,
            U_DescontoKg: transfer.U_DescontoKg,
            Comments: transfer.Comments,
            JournalMemo: `Cancelamento - B2Agri - Remessa para armazem (${transfer.Reference2})`,
            U_B2Obj: 'B2AGRI_ROMREMPROPRIO',
            U_Safra: transfer.U_Safra,
            U_CCG: transfer.U_CCG,
            U_Motorista: transfer.U_Motorista,
            StockTransferLines: [{
                ItemCode: transfer.StockTransferLines[0].ItemCode,
                WarehouseCode: transfer.StockTransferLines[0].WarehouseCode,
                FromWarehouseCode: transfer.StockTransferLines[0].FromWarehouseCode,
                Quantity: transfer.StockTransferLines[0].Quantity 
            }]
        };
        
        const result = await createStockTransfer(obj);        

        if (result.data.error) {

            setError({ isError: true, errorMessage: [result.data.error.message.value] });

        }
        else {
            const att = await updateTransfer(transfer.DocEntry, {
                JournalMemo: `CANCELADO - ${transfer.JournalMemo}`,
                Comments: `CANCELADO - ${transfer.Comments}`
            })
            await cancelDetalhesRomaneio(detalhes.DocEntry)
            console.log(att)
            setProgressBar(true);
            alert('CANCELAMENTO REALIZADO COM SUCESSO!')
            history.push('/home/ownShipment/list');
        }
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5">
                        Romaneio de Remessa para Armazém Próprio - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados do Remetente" value={1} />
                    <Tab label="Dados do Destinatário" value={2} />
                    <Tab label="Dados do Item" value={3} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <SenderData
                        transfer={transfer}
                        branch={branch}
                        detalhes={detalhes}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={2}>
                    <RecipientData
                        invoice={invoice}
                        transfer={transfer}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={3}>
                    <ItemData
                        invoice={invoice}
                        transfer={transfer}
                        detalhes={detalhes}
                    />
                </TabPanel>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link}
                        to={'/home'}
                    >
                        Voltar
                    </Button>
                    <Button 
                        color="primary" 
                        className={classes.button} 
                        variant="outlined" 
                        onClick={handleCancel}
                        disabled={transfer.JournalMemo ? transfer.JournalMemo.includes('CANCELADO') : false}
                    >
                        Cancelar Apontamento
                    </Button>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        startIcon={<PrintIcon />}
                        onClick={() => {
                            const rom = {
                                Detalhes: detalhes,
                                Transfer: transfer,
                                Classificacao: detalhes.ROM_ANALISECollection,
                                Filial: branch,
                            };
                            printPDF(rom)
                        }}
                    >
                        Imprimir
                    </Button>
                </Grid>
            </Paper>
        </>
    );
}

export default OwnShipment;