import Axios from 'axios'

export const BuscaCEP = async (cep) => {
    return Axios.request(`https://viacep.com.br/ws/${cep}/json`)
        .then((response) => {
            return response.data
        })
        .catch((erro) => {
            console.log(erro)
            return { logradouro: '' }
        })
}