import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TextField } from '@material-ui/core';

export default function FormDialog(props) {

    const handleClose = () => {
        props.setCancelDialogOpen(false);
    };

    return (
        <div>
            <Dialog open={props.cancelDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Alerta</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja cancelar a Nota Fiscal de Saída?
                    </DialogContentText>
                    <TextField
                        id="motivo"
                        label="Motivo"
                        variant="outlined"
                        size="small"
                        value={props.cancelMotive}
                        fullWidth
                        onChange={(event) => {
                            props.setCancelMotive(event.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        variant={"contained"}
                    >
                        Voltar
                    </Button>
                    <Button
                        onClick={props.action}
                        color="primary"
                        variant="outlined"
                    >
                        Cancelar NFe
                    </Button>
                </DialogActions>
                <LinearProgress hidden={props.progressBar} />
            </Dialog>
        </div>
    );
}
