import React, { useState, useEffect } from 'react';
import {
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Typography,
    FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import Classification from './Classification';
import ItemsAutocomplete from '../../../components/Items/ItemsAutocomplete';
import { findBatchNumbersFromItem, findCultureByItemCode, findItemByCode } from './OutgoingMailingController';
import { findByBranchIdWithThird } from '../../../components/Deposits/DepositsController';
import { Talhoes, Cultura } from '../../DefaultController';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import moneyFormat from '../../../services/moneyFormat';
import { Safra } from '../../DefaultController';
import { v4 as uuid } from 'uuid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const ItemData = (props) => {

    const classes = CreateAndViewStyle();

    const [batchs, setBatchs] = useState([]);
    const [talhoes, setTalhoes] = useState([]);
    const [disableVarieties, setDisableVarieties] = useState(true);
    const [varieties, setVarieties] = useState([]);
    const [totalQtdeTalhoes, setTotalQtdeTalhoes] = useState(0.0)
    const [classificationList, setClassificationList] = useState([]);
    const [safraList, setSafraList] = useState([]);

    useEffect(() => {
        async function fetchData() {

            const sf = await Safra()
            setSafraList(sf)

        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {

            atualizaSomatoria()
        }

        fetchData();
    });

    useEffect(() => {
        async function fetchData() {

            const sf = await Safra()
            setSafraList(sf)
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {

            if (props.object.ItemData.item) {
                const iCode = props.object.ItemData.item.ItemCode

                const itemResult = await findItemByCode(iCode);

                if (itemResult?.ManageBatchNumbers === 'tYES') {

                    const btc = await findBatchNumbersFromItem(iCode);
                    setBatchs(btc);
                }


                const classific = await findCultureByItemCode(iCode);
                if (classific.length > 0) {
                    setClassificationList(classific[0].CULT_ANALISESCollection)
                }

                const ct = await Cultura()
                const tempCulture = ct.filter((atual) => {
                    if (atual.U_ItemCode === iCode)
                        return atual;
                })

                if (tempCulture[0].VARIEDADESCollection.length > 0) {
                    setDisableVarieties(false);
                    setVarieties(tempCulture[0].VARIEDADESCollection)
                } else {
                    setDisableVarieties(true);
                    setVarieties([]);
                }
            }
        };

        fetchData();
    }, [props.object.ItemData.item]);

    useEffect(() => {
        async function fetchData() {

            if (props.object.SenderData.selectedDeposit) {
                const deposits = await findByBranchIdWithThird(props.object.SenderData.selectedBranch);
                const depObj = deposits.find(x => x.WarehouseCode === props.object.SenderData.selectedDeposit);
                if (depObj) {

                    const tal = await Talhoes(depObj.Location)
                    if (tal[0]) {

                        setTalhoes(tal[0].TALHOESCollection);
                    }
                }
            }
        };

        fetchData();
    }, [props.object.SenderData.selectedDeposit]);

    useEffect(() => {
        handleSaldoEstoque()   
    },[props.object.ItemData, props.object.SenderData.selectedDeposit])

    const atualizaSomatoria = () => {

        const dCultivo = props.object.DadosCultivo
            
        if (dCultivo) {
            const pCultivo = Object.values(dCultivo).reduce((t, { Quantidade }) => t + parseFloat(Quantidade ? Quantidade : 0), 0)
            
            setTotalQtdeTalhoes(pCultivo)
        }
    }

    const handleItemSelection = (value) => {
        props.setObject({
            ...props.object,
            ItemData: {
                ...props.object.ItemData,
                item: value
            }
        })
    }

    const handlePesoNeto = () => {
        if (props.object.ItemData.pesoBruto && props.object.ItemData.pesoTara) {
            const neto = props.object.ItemData.pesoBruto - props.object.ItemData.pesoTara;

            props.setObject({
                ...props.object,
                ItemData: {
                    ...props.object.ItemData,
                    pesoNeto: neto
                }
            })
        }
    }

    const handlePesoLiquido = () => {
        if (props.object.ItemData.pesoNeto) {
            const liquido = props.object.ItemData.pesoNeto - props.object.ItemData.descontoKilos;

            props.setObject({
                ...props.object,
                ItemData: {
                    ...props.object.ItemData,
                    pesoLiquido: liquido
                }
            })
        }
    }

    const handleDeleteItem = (event) => {
        const index = event.currentTarget.dataset.key;

        let tempArray = [];
        if (props.object.DadosCultivo) {
            tempArray = [...props.object.DadosCultivo]
            tempArray.splice(index, 1);
            props.setObject({
                ...props.object,
                DadosCultivo: tempArray
            })
        }
       
    }

    const handleSaldoEstoque = () => {
        if(props.object.ItemData.item) {

            const whsInfo = props.object.ItemData.item.ItemWarehouseInfoCollection;
    
            const saldo = whsInfo.filter(item => {
                return item.WarehouseCode === props.object.SenderData.selectedDeposit
            })
    
            if(saldo[0]) {
                console.log(saldo[0].InStock)
                props.setInStock(saldo[0].InStock)
            }
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Dados do Item</Typography>
                </Grid>
                <Grid item xs={6}>
                    <ItemsAutocomplete
                        idx={0}
                        addItemField={props.object.ItemData.item}
                        setInputItem={handleItemSelection}
                        width={'100%'}
                        graos
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        id="um"
                        label="U.M."
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={
                            props.object.ItemData.item ?
                                props.object.ItemData.item.InventoryUOM
                                : ''
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                <CurrencyTextField
                        id="saldoEstoque"
                        label="Saldo Estoque"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        disabled
                        value={props.inStock ? props.inStock : ''}
                    />
                </Grid>                        
                <Grid item xs={12}>
                    <Typography>Dados de Cultivo</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="safra-select-input">Safra</InputLabel>
                        <Select
                            fullWidth
                            labelId="safra-select-input"
                            id="safra-select"
                            label="Safra"
                            defaultValue=""
                            value={props.object.ItemData.safra || ''}
                            onChange={event => {
                                props.setObject({
                                    ...props.object,
                                    ItemData: {
                                        ...props.object.ItemData,
                                        safra: event.target.value
                                    }
                                })

                                handleSaldoEstoque();
                            }}
                        >
                            {safraList.map(item => {
                                return (
                                    <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    {
                        batchs.length > 0 &&

                        <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                            <InputLabel id="loteLabel">Lote</InputLabel>
                            <Select
                                labelId="loteLabel"
                                id="lote"
                                label="Lote"
                                value={props.object.ItemData.lote || ''}
                                defaultValue=""
                                onChange={event => {
                                    props.setObject({
                                        ...props.object,
                                        ItemData: {
                                            ...props.object.ItemData,
                                            lote: event.target.value
                                        }
                                    })
                                }}
                            >
                                {batchs.map(lote => {
                                    return (
                                        <MenuItem value={lote.Batch}>{lote.Batch}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    }
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} disabled>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Variedade</TableCell>
                                    <TableCell>Talhão</TableCell>
                                    <TableCell align='right'>Quantidade</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.object.DadosCultivo.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={classes.tablePaddingRemove} style={{ width: '40%' }}>
                                                <FormControl variant="outlined" size="small" fullWidth>
                                                    <Select
                                                        labelId="variedadeInput"
                                                        disabled={disableVarieties}
                                                        value={props.object.DadosCultivo ? props.object.DadosCultivo[index].Variedade : ''}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            'data-key': index,
                                                        }}
                                                        onChange={event => {
                                                            let tempArray = [];
                                                            if (props.object.DadosCultivo) {
                                                                tempArray = [...props.object.DadosCultivo];
                                                            }

                                                            tempArray[index] = {
                                                                Talhao: tempArray[index].Talhao,
                                                                Variedade: event.target.value,
                                                                Quantidade: tempArray[index].Quantidade
                                                            };

                                                            props.setObject({
                                                                ...props.object,
                                                                DadosCultivo: tempArray
                                                            })
                                                        }}
                                                    >
                                                        {varieties.map(variety => {
                                                            return (
                                                                <MenuItem value={variety.U_Variedades}>{variety.U_Variedades}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={classes.tablePaddingRemove} style={{ width: '30%' }}>
                                                <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                                                    <Select
                                                        labelId="talhao"
                                                        id={index}

                                                        value={props.object.DadosCultivo ? props.object.DadosCultivo[index].Talhao : ''}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            'data-key': index,
                                                        }}
                                                        onChange={event => {
                                                            let tempArray = [];
                                                            if (props.object.DadosCultivo) {
                                                                tempArray = [...props.object.DadosCultivo];
                                                            }

                                                            tempArray[index] = {
                                                                Talhao: event.target.value,
                                                                Variedade: tempArray[index].Variedade,
                                                                Quantidade: tempArray[index].Quantidade
                                                            };

                                                            props.setObject({
                                                                ...props.object,
                                                                DadosCultivo: tempArray
                                                            })
                                                        }}
                                                    >
                                                        {talhoes.map(talhao => {
                                                            return (
                                                                <MenuItem value={talhao.U_TalhaoCode}>{talhao.U_TalhaoCode}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='right' className={classes.tablePaddingRemove} style={{ width: '30%' }}>
                                                <CurrencyTextField
                                                    align='right'
                                                    id={index}
                                                    currencySymbol=""
                                                    decimalCharacter=","
                                                    digitGroupSeparator="."
                                                    minimumValue="0"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={props.object.DadosCultivo ? props.object.DadosCultivo[index].Quantidade : ''}
                                                    onChange={(event, value) => {
                                                        let tempArray = [];
                                                        if (props.object.DadosCultivo) {
                                                            tempArray = [...props.object.DadosCultivo];
                                                        }

                                                        tempArray[index] = {
                                                            Talhao: tempArray[index].Talhao,
                                                            Variedade: tempArray[index].Variedade,
                                                            Quantidade: value
                                                        };

                                                        props.setObject({
                                                            ...props.object,
                                                            DadosCultivo: tempArray
                                                        })

                                                        atualizaSomatoria()
                                                    }}
                                                    onBlur={() => {
                                                        let tempArray = [];
                                                        if (props.object.DadosCultivo) {
                                                            tempArray = [...props.object.DadosCultivo];
                                                            const existeLinhaEmBranco = tempArray.find((o) => {
                                                                return o.Talhao === '' || o.Quantidade === ''
                                                            })
                                                            //console.log(existeLinhaEmBranco)
                                                            if (existeLinhaEmBranco === undefined) {
                                                                props.setObject({
                                                                    ...props.object,
                                                                    DadosCultivo:
                                                                        [...props.object.DadosCultivo, { Variedade: '', Talhao: '', Quantidade: '' }]

                                                                })
                                                            }
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    data-key={index}
                                                    onClick={handleDeleteItem}
                                                >
                                                    <DeleteOutlineIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>

                                    );
                                })}
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align='right'>{moneyFormat(totalQtdeTalhoes)}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Dados de Pesagem</Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="ticket"
                        label="Nº Ticket"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.numeroTicket}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    numeroTicket: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="ccg"
                        label="CCG"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.ccg}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    ccg: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoBruto"
                        label="Peso Bruto"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoBruto}
                        onBlur={handlePesoNeto}
                        onChange={(event, value) => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    pesoBruto: value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoTara"
                        label="Peso Tara"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoTara}
                        onBlur={handlePesoNeto}
                        onChange={(event, value) => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    pesoTara: value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoNeto"
                        label="Peso Neto"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoNeto}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="desconto"
                        label="Desconto KG"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.descontoKilos}
                        onBlur={handlePesoLiquido}
                        onChange={(event, value) => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    descontoKilos: value
                                }
                            })
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoLiquido"
                        label="Peso Liquido"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoLiquido}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Classificação</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Classification
                        object={props.object}
                        setObject={props.setObject}
                        list={classificationList}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Observações"
                        multiline
                        rows={2}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={props.object.ItemData.comments}
                        onBlur={handlePesoLiquido}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    comments: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ItemData;