import React, { useEffect, useState } from 'react'
import {
    TextField, Collapse,
    Grid, Paper, Button, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, DialogContentText
} from '@material-ui/core'
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { v4 as uuid } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
    title: {
        background: '#000000',
        fontWeight: 'bold',
        color: '#FFF'
    }
}))

const EnderecosList = props => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }
    const [message, setMessage] = useState(false)
    const [mensagemAlert, setMessageAlert] = useState('')
    const [list, setList] = useState(props.ListDados)

    const [_Name, setName] = useState(null)
    const [_FirstName, setFirstName] = useState(null)
    const [_Title, setTitle] = useState(null)
    const [_MobilePhone, setMobilePhone] = useState(null)
    const [_E_Mail, setE_Mail] = useState(null)

    const handleAddItem = () => {
        const keyid = uuid()
        list.push({
            key: keyid,
            Name: _Name,
            FirstName: _FirstName,
            Title: _Title,
            MobilePhone: _MobilePhone,
            E_Mail: _E_Mail,
        })
        props.UpdateList(list)
        setOpen(false)
    }

    const handleRemoveItem = async (key) => {
        setList(list.filter(i => i.key !== key))
        props.UpdateList(list)
    }

    useEffect(() => {
        props.UpdateList(list)
    })

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Button
                            size='small'
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setName(null)
                                setFirstName(null)
                                setTitle(null)
                                setMobilePhone(null)
                                setE_Mail(null)
                                handleOpen()
                            }}
                        >
                            Novo Contato
                        </Button>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Primeiro Nome</TableCell>
                                    <TableCell>Título</TableCell>
                                    <TableCell>Celular</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((item) => {
                                    return (
                                        <TableRow key={item.key}>
                                            <TableCell>{item.Name}</TableCell>
                                            <TableCell>{item.FirstName}</TableCell>
                                            <TableCell>{item.Name}</TableCell>
                                            <TableCell>{item.MobilePhone}</TableCell>
                                            <TableCell>{item.E_Mail}</TableCell>
                                            <TableCell><Button onClick={() => handleRemoveItem(item.key)}><DeleteOutlineIcon /></Button></TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle className={classes.title} id="form-dialog-title">Cadastrar Novo Parceiro de Negócio</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid item xs={12}>
                            <Collapse in={message}>
                                <Alert severity="error"
                                    action={
                                        <Button color="inherit" size="small"
                                            onClick={() => {
                                                setMessageAlert('')
                                                setMessage(false)
                                            }}
                                        >
                                            Fechar
                                    </Button>
                                    }
                                >
                                    {mensagemAlert}
                                </Alert>
                            </Collapse>
                        </Grid>
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                label="ID do Contato"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_Name}
                                onChange={(event) => {
                                    setName(event.target.value.toUpperCase())
                                }}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Primeiro Nome"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_FirstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Título"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_Title}
                                onChange={(event) => {
                                    setTitle(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Celular"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_MobilePhone}
                                onChange={(event) => {
                                    setMobilePhone(event.target.value)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="E-mail"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_E_Mail}
                                onChange={(event) => {
                                    setE_Mail(event.target.value.toLowerCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                    <Button onClick={handleAddItem} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default EnderecosList