import api from '../../services/api.js';

const Auth = async (email, password) => {

    const options = {
        method: 'POST',
        url: '/Auth',
        headers: {'Content-Type': 'application/json'},
        data: {email, password}
      };
      
      return api.request(options).then(function (response) {
        
        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export default Auth;