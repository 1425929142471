import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { searchByPartDescription} from './EmployeesInfoController';


const EmployeeAutocomplete = (props) => {

    const width = props.width ? props.width : 300;
    const [listItems, setListItems] = useState([]);
    const [addInputValue, setAddInputValue] = useState('');

    const handleChangeFilterOption = (event, newInputValue) => {

        setAddInputValue(newInputValue.toUpperCase());

        if (newInputValue.length >= 3) {
            searchByPartDescription(newInputValue.toUpperCase())
                .then((result => {
                    if (result) {
                        setListItems(result)
                    }
                }))
        } else {
            setListItems([])
        }
    }

    const handleUnGpList = async (value) => {

        ;
    }

    return (
        <>
            <Autocomplete
                style={{ width: width }}
                value={props.addItemField}
                getOptionSelected={(option, value) => {
                    return option.ItemCode === value.ItemCode;
                }}
                getOptionLabel={(option) => option.EmployeeID ? option.EmployeeID + ' - ' + option.ExternalEmployeeNumber + ' - ' + option.FirstName + ' ' + option.LastName : ''}
                onChange={(event, value) => { props.setInputItem(value) }}
                inputValue={addInputValue}
                onInputChange={handleChangeFilterOption}
                options={listItems}
                fullWidth                
                size="small"
                renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
            />
        </>
    );
}

export default EmployeeAutocomplete;