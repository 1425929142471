import PurchaseRequestCreate from '../../pages/PurchaseRequest/Create';
import PurchaseRequestView from '../../pages/PurchaseRequest/View';
import PurchaseRequestCancel from '../../pages/PurchaseRequest/Cancel';
import PurchaseRequestClose from '../../pages/PurchaseRequest/Close';
import PurchaseDeliveryNotesCreate from '../../pages/PurchaseDeliveryNotes/Create';
import PurchaseDeliveryNotesView from '../../pages/PurchaseDeliveryNotes/View';
import PurchaseDeliveryOpenOrders from '../../pages/PurchaseDeliveryNotes/ViewOpenPurchaseOrders';
import PurchaseDeliveryNotesCancel from '../../pages/PurchaseDeliveryNotes/Cancel';
import InventoryGenExitsCreate from '../../pages/InventoryGenExits/Create';
import InventoryGenExitsView from '../../pages/InventoryGenExits/View';
import PurchaseRequestList from '../../pages/PurchaseRequest/List';
import PurchaseDeliveryNotesList from '../../pages/PurchaseDeliveryNotes/List';
import InventoryGenExitsList from '../../pages/InventoryGenExits/List';
import BusinessPartnersCreate from '../../pages/BusinessPartners/Create'
import AnimalBirthRecordCreate from '../../pages/AnimalLifeCycle/AnimalBirthRecordCreate'
import AnimalDeathRecordCreate from '../../pages/AnimalLifeCycle/AnimalDeathRecordCreate'
import AgriculturalOperationsCreate from '../../pages/AgriculturalOperations/Create';
import AgriculturalOperationsList from '../../pages/AgriculturalOperations/List';
import FuelAndLubrificationCreate from '../../pages/FuelAndLubrification/Create';
import FuelAndLubrificationList from '../../pages/FuelAndLubrification/List';
import SeedTreatmentCreate from '../../pages/SeedTreatment/Create';
import SeedTreatmentList from '../../pages/SeedTreatment/List';
import WeatherRecordCreate from '../../pages/WeatherRecord/Create';
import WeatherRecordList from '../../pages/WeatherRecord/List';
import FuelAndLubrificationView from '../../pages/FuelAndLubrification/View';
import AgriculturalOperationsView from '../../pages/AgriculturalOperations/View';
import SeedTreatmentView from '../../pages/SeedTreatment/View';
import WeatherRecordView from '../../pages/WeatherRecord/View';
import OutgoingMailing from '../../pages/OutgoingMailing/Create';
import OutgoingMailingView from '../../pages/OutgoingMailing/View';
import OutgoingMailingList from '../../pages/OutgoingMailing/List';
import ServiceOrder from '../../pages/ServiceOrder/Create';
import ServiceOrderList from '../../pages/ServiceOrder/List';
import ServiceOrderView from '../../pages/ServiceOrder/ViewAndEdit';
import ItemListReport from '../../pages/Reports/ItemListReport';
import OwnShipment from '../../pages/OwnShipment/Create';
import OwnShipmentList from '../../pages/OwnShipment/List';
import OwnShipmentView from '../../pages/OwnShipment/View';
import WarehouseExit from '../../pages/WarehouseExit/Create';
import WarehouseExitList from '../../pages/WarehouseExit/List';
import WarehouseExitView from '../../pages/WarehouseExit/View';
import OutboundListing from '../../pages/OutboundListing/Create';
import OutboundListingList from '../../pages/OutboundListing/List';
import AnimalBirthRecordList from '../../pages/AnimalLifeCycle/AnimalBirthRecordList';
import AnimalDeathRecordList from '../../pages/AnimalLifeCycle/AnimalDeathRecordList';
import AnimalBirthRecordView from '../../pages/AnimalLifeCycle/AnimalBirthRecordView';
import AnimalDeathRecordView from '../../pages/AnimalLifeCycle/AnimalDeathRecordView';
import AnimalBirthRecordCancel from '../../pages/AnimalLifeCycle/AnimalBirthRecordCancel';
import AnimalDeathRecordCancel from '../../pages/AnimalLifeCycle/AnimalDeathRecordCancel';
import PlantationShipment from '../../pages/PlantationShipment/Create';
import HarvestSummaryReport from '../../pages/Reports/HarvestSummaryReport';

const simpleRoutes = [
    {
        route: '/home/purchaseRequest',
        component: PurchaseRequestList
    },
    {
        route: '/home/purchaseRequest/create',
        component: PurchaseRequestCreate,
    },
    {
        route: '/home/purchaseRequest/view/:id',
        component: PurchaseRequestView,
    },
    {
        route: '/home/purchaseRequest/cancel/:id',
        component: PurchaseRequestCancel,
    },
    {
        route: '/home/purchaseRequest/close/:id',
        component: PurchaseRequestClose,
    },
    {
        route: '/home/purchaseDeliveryNotes',
        component: PurchaseDeliveryNotesList,
    },
    {
        route: '/home/purchaseDeliveryNotes/viewPurchaseOrders/create/:id',
        component: PurchaseDeliveryNotesCreate,
    },
    {
        route: '/home/purchaseDeliveryNotes/view/:id',
        component: PurchaseDeliveryNotesView,
    },
    {
        route: '/home/purchaseDeliveryNotes/openPurchaseOrders',
        component: PurchaseDeliveryOpenOrders,
    },
    {
        route: '/home/purchaseDeliveryNotes/cancel/:id',
        component: PurchaseDeliveryNotesCancel,
    },
    {
        route: '/home/inventoryGenExits',
        component: InventoryGenExitsList,
    },
    {
        route: '/home/inventoryGenExits/create',
        component: InventoryGenExitsCreate,
    },
    {
        route: '/home/inventoryGenExits/view/:id',
        component: InventoryGenExitsView,
    },
    {
        route: '/home/businesspartners/create',
        component: BusinessPartnersCreate,
    },
    {
        route: '/home/animalbirthrecord/create',
        component: AnimalBirthRecordCreate,
    },
    {
        route: '/home/animaldeathrecord/create',
        component: AnimalDeathRecordCreate,
    },
    {
        route: '/home/agriculturalOperations/create',
        component: AgriculturalOperationsCreate,
    },
    {
        route: '/home/agriculturalOperations/list',
        component: AgriculturalOperationsList,
    },
    {
        route: '/home/agriculturalOperations/view/:id',
        component: AgriculturalOperationsView,
    },
    {
        route: '/home/fuelAndLubrification/create',
        component: FuelAndLubrificationCreate,
    },
    {
        route: '/home/fuelAndLubrification/list',
        component: FuelAndLubrificationList,
    },
    {
        route: '/home/fuelAndLubrification/view/:id',
        component: FuelAndLubrificationView,
    },
    {
        route: '/home/seedTreatment/create',
        component: SeedTreatmentCreate,
    },
    {
        route: '/home/seedTreatment/List',
        component: SeedTreatmentList,
    },
    {
        route: '/home/seedTreatment/view/:id',
        component: SeedTreatmentView,
    },
    {
        route: '/home/weatherRecord/create',
        component: WeatherRecordCreate,
    },
    {
        route: '/home/weatherRecord/list',
        component: WeatherRecordList,
    },
    {
        route: '/home/weatherRecord/view/:id',
        component: WeatherRecordView,
    },
    {
        route: '/home/outgoingMailing/create',
        component: OutgoingMailing,
    },
    {
        route: '/home/outgoingMailing/view/:id',
        component: OutgoingMailingView,
    },
    {
        route: '/home/outgoingMailing/list',
        component: OutgoingMailingList,
    },
    {
        route: '/home/serviceOrder/create',
        component: ServiceOrder,
    },
    {
        route: '/home/serviceOrder/list',
        component: ServiceOrderList,
    },
    {
        route: '/home/serviceOrder/view/:id',
        component: ServiceOrderView,
    },
    {
        route: '/home/report/ItemList',
        component: ItemListReport,
    },
    {
        route: '/home/ownShipment/create',
        component: OwnShipment,
    },
    {
        route: '/home/ownShipment/view/:id',
        component: OwnShipmentView,
    },
    {
        route: '/home/ownShipment/list',
        component: OwnShipmentList,
    },
    {
        route: '/home/warehouseExit/create',
        component: WarehouseExit,
    },
    {
        route: '/home/warehouseExit/view/:id',
        component: WarehouseExitView,
    },
    {
        route: '/home/warehouseExit/list',
        component: WarehouseExitList,
    },
    {
        route: '/home/outboundListing/create/:Code?',
        component: OutboundListing,
    },
    {
        route: '/home/outboundListing/list',
        component: OutboundListingList
    },
    {
        route: '/home/animaldeathrecord/list',
        component: AnimalDeathRecordList,
    },
    {
        route: '/home/animalbirthrecord/list',
        component: AnimalBirthRecordList
    },
    {
        route: '/home/animaldeathrecord/view/:id',
        component: AnimalDeathRecordView,
    },
    {
        route: '/home/animalbirthrecord/view/:id',
        component: AnimalBirthRecordView
    },
    {
        route: '/home/animaldeathrecord/cancel/:id',
        component: AnimalDeathRecordCancel,
    },
    {
        route: '/home/animalbirthrecord/cancel/:id',
        component: AnimalBirthRecordCancel,
    },
    {
        route: '/home/plantationShipment/create/:Code?',
        component: PlantationShipment,
    },
    {
        route: '/home/report/HarvestSummary',
        component: HarvestSummaryReport,
    },
];

export default simpleRoutes;