import api from '../../services/api.js';

export const findWarehouseByLocation = async (ItemCode, LocationCode) => {

    const options = {
        method: 'GET',
        url: `/OperacoesAgricolas/${ItemCode}/${LocationCode}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data;
    }).catch(function (error) {

        console.error(error);
    });
};

export const create = async (operation) => {

    const options = {
      method: 'POST',
      url: 'OperacoesAgricolas/create',
      validateStatus: () => true,
      data: operation
    };
    
    return api.request(options).then(function (response) {
      
      return response;
    }).catch(function (error) {
      return error;
    });
  };

  export const findOperationsRegister = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

    const options = {
        method: 'POST',
        url: '/OperacoesAgricolas/list',
        headers: {'Content-Type': 'application/json'},
        data: branchs
      };
      
      return api.request(options).then(function (response) {
        
        return response;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export const findOneOperation = async (id) => {

  const options = {
      method: 'GET',
      url: `/OperacoesAgricolas/${id}`,
      headers: { 'Content-Type': 'application/json' }
  };

  return api.request(options).then(function (response) {

      return response.data.value;
  }).catch(function (error) {

      console.error(error);
  });
};
