import api from '../../../services/api.js';

export const findBrachInfo = async (id) => {

  const options = {
      method: 'GET',
      url: `/BusinessPlaces/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findWarehouseFromBranchAndBP = async (branchId, bp) => {

  const options = {
      method: 'GET',
      url: `/Warehouse/${branchId}/${bp}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findBatchNumbersFromItem = async (ItemCode) => {

  const options = {
      method: 'GET',
      url: `/Items/batchNumbers/${ItemCode}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {
      
      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findItemByCode = async (id) => {

  const options = {
      method: 'GET',
      url: `/Items/${id}`,
      headers: { 'Content-Type': 'application/json' }
  };

  return api.request(options).then(function (response) {

      return response.data;
  }).catch(function (error) {

      console.error(error);
  });
};

export const createStockTransfer = async (obj) => {

  const options = {
    method: 'POST',
    url: '/StockTransfers/create',
    validateStatus: () => true,
    data: obj
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const createDetailsRomaneio = async (obj) => {

  const options = {
    method: 'POST',
    url: '/DetalhesRomaneio/create',
    validateStatus: () => true,
    data: obj
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const createInvoice = async (obj) => {

  const options = {
    method: 'POST',
    url: '/DeliveryNotes/create',
    validateStatus: () => true,
    data: obj
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const findCultureByItemCode = async (ItemCode) => {

  const options = {
      method: 'GET',
      url: `/Cultura/Item/${ItemCode}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {
      
      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findStockTransferByReference2 = async (Reference2) => {

  const options = {
      method: 'GET',
      url: `/StockTransfers/findByReference/${Reference2}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findDetalhesByReference2 = async (Reference2) => {

  const options = {
      method: 'GET',
      url: `/DetalhesRomaneio/findByReference/${Reference2}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findInvoiceByReference2 = async (Reference2) => {

  const options = {
      method: 'GET',
      url: `/DeliveryNotes/findByReference/${Reference2}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const closeDetalhesRomaneio = async (DocEntry) => {

  const options = {
    method: 'GET',
    url: `/DetalhesRomaneio/close/${DocEntry}`,
    validateStatus: () => true
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const updateTransfer = async (DocEntry, transferObject) => {

  const options = {
    method: 'POST',
    url: `/StockTransfers/update/${DocEntry}`,
    validateStatus: () => true,
    data: transferObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    
    return error;
  });
}; 

export const cancelDetalhesRomaneio = async (DocEntry) => {

  const options = {
    method: 'GET',
    url: `/DetalhesRomaneio/cancel/${DocEntry}`,
    validateStatus: () => true
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const cancelDeliveryNote = async (DocEntry) => {

  const options = {
    method: 'GET',
    url: `/DeliveryNotes/cancel/${DocEntry}`,
    validateStatus: () => true
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};