import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import { findOne, updateAttachments } from './PurchaseDeliveryNotesController';
import { findAttachments } from '../../services/AttachmentController';
import {
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell, TableContainer,
    Button, Typography, Tabs, Tab, AppBar, IconButton
} from '@material-ui/core';
import moneyFormat from '../../services/moneyFormat';
import { v4 as uuid } from 'uuid';
import { Archive } from '@material-ui/icons';
import TabPanel from '../../components/TabPanel';
import status from './purchaseDeliveryStatus';
import AttachmentUpdate from '../../components/AttachmentsUpdate/Update';

const PurchaseDeliveryNotesView = () => {

    let { id } = useParams();
    const [purchaseDeliveryNote, setPurchaseDeliveryNote] = useState({});
    const [items, setItems] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [tabs, setTabs] = useState(0);
    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {

            if (id) {
                const result = await findOne(id);

                const docStatus = status(result.DocumentStatus, result.Cancelled);

                if (result) {
                    setPurchaseDeliveryNote({
                        "DocEntry": result.DocEntry,
                        "DocNum": result.DocNum,
                        "DocDate": result.DocDate,
                        "BPLName": result.BPLName,
                        "CardCode": result.CardCode,
                        "CardName": result.CardName,
                        "Comments": result.Comments,
                        "SequenceSerial": result.SequenceSerial,
                        "SeriesString": result.SeriesString,
                        "U_ChaveAcesso": result.U_ChaveAcesso,
                        "AttachmentEntry": result.AttachmentEntry,
                        "Status": docStatus
                    });
                    setItems(result.DocumentLines);

                }
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (purchaseDeliveryNote.AttachmentEntry) {

                const result = await findAttachments(purchaseDeliveryNote.AttachmentEntry);
                setAttachments(result.Attachments2_Lines);
            }
        };

        fetchData();
    }, [purchaseDeliveryNote.AttachmentEntry]);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Recebimento de Mercadoria - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={0} />
                    <Tab label="Anexos" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                label="Código"
                                type="text"
                                value={purchaseDeliveryNote.DocNum}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="date"
                                label="Data do Documento"
                                type="date"
                                fullWidth
                                value={purchaseDeliveryNote.DocDate}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="nf"
                                label="Nota Fiscal"
                                type="text"
                                fullWidth
                                value={purchaseDeliveryNote.SequenceSerial}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="nfSeries"
                                label="Série"
                                type="text"
                                fullWidth
                                value={purchaseDeliveryNote.SeriesString}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="status"
                                label="Status"
                                type="text"
                                fullWidth
                                value={purchaseDeliveryNote.Status}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Filial"
                                variant="outlined"
                                size="small"
                                value={purchaseDeliveryNote.BPLName}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="chavedeacesso"
                                label="Chave de Acesso NF"
                                type="text"
                                value={purchaseDeliveryNote.U_ChaveAcesso}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="pn"
                                label="Fornecedor"
                                type="text"
                                value={purchaseDeliveryNote.CardCode + ' - ' + purchaseDeliveryNote.CardName}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={16}>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell><Typography className={classes.bold}>Código</Typography></TableCell>
                                            <TableCell><Typography className={classes.bold}>Descrição</Typography></TableCell>
                                            <TableCell><Typography className={classes.bold}>Quantidade</Typography></TableCell>
                                            <TableCell><Typography className={classes.bold}>Preço Unitário</Typography></TableCell>
                                            <TableCell><Typography className={classes.bold}>Pedido Origem</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item) => {
                                            return (
                                                <TableRow key={uuid()}>
                                                    <TableCell>{item.ItemCode}</TableCell>
                                                    <TableCell>{item.ItemDescription}</TableCell>
                                                    <TableCell>{item.Quantity}</TableCell>
                                                    <TableCell>{moneyFormat(item.Price)}</TableCell>
                                                    <TableCell>{item.BaseEntry}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                value={purchaseDeliveryNote.Comments}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabs} index={1}>
                    <AttachmentUpdate
                        AttachmentEntry={purchaseDeliveryNote.AttachmentEntry}
                        DocEntry={purchaseDeliveryNote.DocEntry}
                        DocumentListLink={'/home/purchaseDeliveryNote'}
                        UpdateDocumentFunction={updateAttachments}
                    />
                    <br />
                    <br />
                    <br />
                    <Paper className={classes.title}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Typography className={classes.whitebold} variant="h5" fullWidth>
                                Anexos Existentes
                            </Typography>
                        </Grid>
                    </Paper>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.tablehead}>
                                    <TableCell><Typography className={classes.bold}>Arquivo</Typography></TableCell>
                                    <TableCell><Typography className={classes.bold}>Extensão</Typography></TableCell>
                                    <TableCell><Typography className={classes.bold}>Download</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attachments.map((attachment) => {
                                    return (
                                        <TableRow
                                            key={uuid()}
                                        >

                                            <TableCell>{attachment.FileName}</TableCell>
                                            <TableCell>{attachment.FileExtension}</TableCell>
                                            <TableCell>
                                                <a href={process.env.REACT_APP_NODE_API_URL + `/Attachments2/${purchaseDeliveryNote.AttachmentEntry}/${attachment.FileName}.${attachment.FileExtension}`}  >
                                                    <IconButton component="span">
                                                        <Archive />
                                                    </IconButton>
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <Button
                    color="primary"
                    className={classes.button}
                    variant="contained"
                    component={Link}
                    to={'/home/purchaseDeliveryNotes'}
                >
                    Voltar
                </Button>
                {purchaseDeliveryNote.Status !== 'Cancelado' &&
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link}
                        to={`/home/purchaseDeliveryNotes/cancel/${purchaseDeliveryNote.DocNum}-${purchaseDeliveryNote.DocEntry}`}
                    >
                        Cancelar
                    </Button>
                }
            </Paper>
        </>
    );
}

export default PurchaseDeliveryNotesView;