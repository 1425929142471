import React from 'react';
import { HashRouter } from 'react-router-dom';
import Routes from './routes';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './themes/mainTheme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <HashRouter basename="portalb2agri">
          <Routes />
        </HashRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
