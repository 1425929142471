import React, { useState } from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel } from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import BusinessPartnerAutocomplete from '../../../components/BusinessPartner/Autocomplete';

const InvoiceData = (props) => {

    const classes = CreateAndViewStyle();
    const [valor, setValor] = useState('')
    const [geraNf, setGeraNf] = useState(true);

    const handleBPSelection = (value) => {
        props.setObject({
            ...props.object,
            InvoiceData: {
                ...props.object.InvoiceData,
                transportadora: value
            }
        })
    }

    const handleGeraNf = (event) => {
        setGeraNf(event.target.checked)

        props.setObject({
            ...props.object,
            InvoiceData: {
                ...props.object.InvoiceData,
                geraNf: event.target.checked
            }
        })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={geraNf}
                                onChange={handleGeraNf}
                                name="geraNf"
                                color="primary"
                            />
                        }
                        label="Gera Documento Fiscal"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="dataNota"
                        label="Data da Nota"
                        type="date"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!geraNf}
                        InputLabelProps={{ shrink: true }}
                        value={props.object.InvoiceData.dataNota}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    dataNota: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CurrencyTextField
                        id="quantidadeNota"
                        label="Quantidade da Nota"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!geraNf}
                        InputLabelProps={{ shrink: true }}
                        value={props.object.InvoiceData.quantidadeNota}
                        onChange={(event, value) => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    quantidadeNota: value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CurrencyTextField
                        style={{ width: '100%' }}
                        id="valorNota"
                        variant="outlined"
                        currencySymbol="R$"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        size="small"
                        disabled={!geraNf}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={props.object.InvoiceData.valorNota}
                        onChange={(event, value) => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    valorNota: value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="sequenceCode">Sequência</InputLabel>
                        <Select
                            labelId="sequenceCode"
                            id="sequenceCode"
                            label="Sequência"
                            disabled={!geraNf}
                            value={props.object.InvoiceData.sequenceCode || ''}
                            defaultValue = ''
                            onChange={(event) => {
                                props.setObject({
                                    ...props.object,
                                    InvoiceData: {
                                        ...props.object.InvoiceData,
                                        sequenceCode: event.target.value
                                    }
                                })
                            }}
                        >
                            <MenuItem value={-2}>Externo</MenuItem>
                            <MenuItem value={-1}>Manual</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="numNf"
                        label="Número NF"
                        type="number"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!geraNf}
                        InputLabelProps={{ shrink: true }}
                        value={props.object.InvoiceData.nf}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    nf: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="serie"
                        label="Série NF"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!geraNf}
                        InputLabelProps={{ shrink: true }}
                        value={props.object.InvoiceData.serie}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    serie: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="model">Modelo</InputLabel>
                        <Select
                            labelId="model"
                            id="model"
                            label="Modelo"
                            disabled={!geraNf}
                            value={props.object.InvoiceData.model || ''}
                            defaultValue = ""
                            onChange={(event) => {
                                props.setObject({
                                    ...props.object,
                                    InvoiceData: {
                                        ...props.object.InvoiceData,
                                        model: event.target.value
                                    }
                                })
                            }}
                        >
                            <MenuItem value={1}>Modelo 1</MenuItem>
                            <MenuItem value={2}>Modelo 1-A</MenuItem>
                            <MenuItem value={39}>NFe (55)</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="chaveAcesso"
                        label="Chave de Acesso"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!geraNf}
                        InputLabelProps={{ shrink: true }}
                        value={props.object.InvoiceData.chaveAcesso}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    chaveAcesso: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="tipoFrete">Tipo Frete</InputLabel>
                        <Select
                            labelId="tipoFrete"
                            id="tipoFrete"
                            label="Tipo Frete"
                            disabled={!geraNf}
                            value={props.object.InvoiceData.incoterms || ''}
                            defaultValue = ""
                            onChange={event => {
                                props.setObject({
                                    ...props.object,
                                    InvoiceData: {
                                        ...props.object.InvoiceData,
                                        incoterms: event.target.value
                                    }
                                })
                            }}
                        >
                            <MenuItem value={0}>0 - Frete por conta do Remetente (CIF)</MenuItem>
                            <MenuItem value={1}>1 - Frete por conta do Destinatário (FOB)</MenuItem>
                            <MenuItem value={2}>2 - Frete por conta de Terceiros</MenuItem>
                            <MenuItem value={3}>3 - Transporte Próprio por conta do Remetente</MenuItem>
                            <MenuItem value={4}>4 - Transporte Próprio por conta do Destinatário</MenuItem>
                            <MenuItem value={9}>9 - Sem Ocorrência de Transporte</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="motorista"
                        label="Motorista"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.InvoiceData.motorista}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    motorista: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="placaCaminhao"
                        label="Placa Caminhão"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.InvoiceData.placaCaminhao}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                InvoiceData: {
                                    ...props.object.InvoiceData,
                                    placaCaminhao: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <BusinessPartnerAutocomplete
                        addItemField={props.object.InvoiceData.transportadora}
                        setInputItem={handleBPSelection}
                        width={'100%'}
                        label="Transportadora"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                        <InputLabel id="estadoVeiculo" >UF Veículo</InputLabel>
                        <Select
                            labelId="estadoVeiculo"
                            id="estadoVeiculo"
                            label="UF Veiculo"
                            defaultValue=""
                            value={props.object.InvoiceData.estadoPlaca || ''}
                            defaultValue = ""
                            onChange={event => {
                                props.setObject({
                                    ...props.object,
                                    InvoiceData: {
                                        ...props.object.InvoiceData,
                                        estadoPlaca: event.target.value
                                    }
                                })
                            }}
                        >
                            <MenuItem value={'AC'}>Acre</MenuItem>
                            <MenuItem value={'AL'}>Alagoas</MenuItem>
                            <MenuItem value={'AP'}>Amapá</MenuItem>
                            <MenuItem value={'AM'}>Amazonas</MenuItem>
                            <MenuItem value={'BA'}>Bahia</MenuItem>
                            <MenuItem value={'CE'}>Ceará</MenuItem>
                            <MenuItem value={'DF'}>Distrito Federal</MenuItem>
                            <MenuItem value={'ES'}>Espírito Santo</MenuItem>
                            <MenuItem value={'GO'}>Goiás</MenuItem>
                            <MenuItem value={'MA'}>Maranhão</MenuItem>
                            <MenuItem value={'MT'}>Mato Grosso</MenuItem>
                            <MenuItem value={'MS'}>Mato Grosso do Sul</MenuItem>
                            <MenuItem value={'MG'}>Minas Gerais</MenuItem>
                            <MenuItem value={'PA'}>Pará</MenuItem>
                            <MenuItem value={'PB'}>Paraíba</MenuItem>
                            <MenuItem value={'PR'}>Paraná</MenuItem>
                            <MenuItem value={'PE'}>Pernambuco</MenuItem>
                            <MenuItem value={'PI'}>Piauí</MenuItem>
                            <MenuItem value={'RJ'}>Rio de Janeiro</MenuItem>
                            <MenuItem value={'RN'}>Rio Grande do Norte</MenuItem>
                            <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
                            <MenuItem value={'RO'}>Rondônia</MenuItem>
                            <MenuItem value={'RR'}>Roraima</MenuItem>
                            <MenuItem value={'SC'}>Santa Catarina</MenuItem>
                            <MenuItem value={'SP'}>São Paulo</MenuItem>
                            <MenuItem value={'SE'}>Sergipe</MenuItem>
                            <MenuItem value={'TO'}>Tocantins</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        </>
    )
}

export default InvoiceData;