import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(0),
    },
    paperInternal: {
        padding: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
    },
    tablerow: {
        textDecoration: 'none',
    },
    button: {
        marginRight: '5px',
        marginTop: '15px'
    },
    formControl: {
        minWidth: 200,
    },
    quantityInput: {
        minWidth: 100,
    },
    mediumInput: {
        width: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    gridPadding: {
        padding: theme.spacing(1)
    },
    title: {
        background: fade('#000000', 0.3)
    },
    whitebold: {
        fontWeight: 'bold',
        color: '#FFF'
    },
    tablePaddingRemove: {
        padding: '3px'
    },
    mousePointer: {
        color: 'red'
    }
}));