import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import {
    Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
    Tabs, Tab, AppBar, Button, LinearProgress, Collapse, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import { findActiveProfitCentersInFirstDimension2 } from '../../components/ProfitCenters/ProfitCentersController';
import { v4 as uuid } from 'uuid';
import ItemsAutocomplete from './ItemsAutocomplete';
import Alert from '@material-ui/lab/Alert';
import TabPanel from '../../components/TabPanel';
import { findWarehouseByLocation } from '../SeedTreatment/SeedTreatmentController';
import { create } from '../../pages/InventoryGenExits/InventoryGenExitsController';
import { WarehouseLocations, Safra, WarehouseToBranchList, EmployeesInfo } from '../DefaultController'
import EquipmentsAutoComplete from '../../components/Equipments/EquipmentsAutoComplete'
import referenceid from '../../services/referenceid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import EmployeeAutocomplete from '../../components/EmployeesInfo/EmployeesInfoAutoComplete';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { findByCode } from './ItemsController';

const FuelAndLubrification = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [progressBar, setProgressBar] = useState(true);
    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [equipament, setEquipament] = useState('');
    const [operador, setOperador] = useState('');
    const [profitCenter, setProfitCenter] = useState('');
    const [medidor, setMedidor] = useState('');
    const [comments, setComments] = useState('');
    const [profitCenterList, setProfitCenterList] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [item, setItem] = useState('');
    const [itemList, setItemList] = useState([]);
    const classes = CreateAndViewStyle();
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [serverError, setServerError] = useState({ isServerError: false, serverErrorMessage: '' });
    const [operadorList, setOperadorList] = useState([]);
    const [safra, SetSafra] = useState('');
    const [safraList, setSafraList] = useState([]);
    const [cultura, setCultura] = useState('');
    const [proprio, setProprio] = useState(0);
    const [items, setItems] = useState([{ ItemCode: '', ItemName: '', Quantity: 0 }]);
    const [warehouseToBranchList, setWarehouseToBranchList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const loc = await WarehouseLocations();
            setLocationsList(loc);

            const sf = await Safra();
            setSafraList(sf);

            const whs = await WarehouseToBranchList();
            setWarehouseToBranchList(whs);

        };
        fetchData();
    }, []);

    const handleNewItem = () => {

        setItems([...items, { ItemCode: '', ItemName: '', InventoryUOM: '', Quantity: 0 }]);
    };

    const handleDeleteItem = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    const setInputItem = (item, index) => {
        if (item) {

            let tempItems = [...items];
            tempItems[index].ItemCode = item.ItemCode;
            tempItems[index].ItemName = item.ItemName;
            tempItems[index].InventoryUOM = item.InventoryUOM;
            setItems(tempItems);
        }
    }

    const searchListItemWarehouses = async (item, index) => {
        
        if (item) {
            const tmpIt = await findByCode(item.ItemCode);
            
            let tempItems = [...items];
            tempItems[index].DepositosCusto = tmpIt.ItemWarehouseInfoCollection;

            const deps = await findWarehouseByLocation(item.ItemCode, selectedLocation);
            tempItems[index].ListaDepositos = deps.value;
            setItems([...tempItems]);
            

        }
    }

    const mapWarehouseToBranch = (WhsCode) => {
        let result = null;

        warehouseToBranchList.forEach((value) => {
            if (value.WarehouseCode === WhsCode)
                result = value.BusinessPlaceID
        })
        return result;
    }

    const mapItemWarehousePrice = (WarehouseList, WarehouseCode) => {

        for (let i = 0; i < WarehouseList.length; i++) {

            if (WarehouseList[i].WarehouseCode === WarehouseCode) {

                return WarehouseList[i].StandardAveragePrice
            }
        }
    }

    const handleLocationSelection = async (event) => {
        setSelectedLocation(event.target.value);

        setEquipament('')

        const oProfitCenters = await findActiveProfitCentersInFirstDimension2(event.target.value);
        setProfitCenterList(oProfitCenters);

        //Operador
        const opLst = await EmployeesInfo(event.target.value)
        setOperadorList(opLst)
    }

    const handleSubmit = async () => {
        setError({ isError: false, errorMessage: [] });
        setProgressBar(false);

        const refid = referenceid();

        const eqp = proprio === 0 ? `${equipament.ItemCode} - ${equipament.InventoryNumber} - ${equipament.ItemName}` : equipament;

        const warehouseExitRequest = {
            DocDate: documentDate,
            U_Equipamento: eqp,
            U_EquipMedidor: medidor,
            U_Operador: operador.EmployeeID,
            U_Safra: safra,
            Comments: comments,
            JournalMemo: `B2Agri - Saída Almoxarifado (${equipament.InventoryNumber})`,
            U_B2Obj: 'B2AGRI_WAREHOUSEEXIT',            
            Reference2: refid
        }

        for (let i = 0; i < items.length; i++) {

            let obj = { ...warehouseExitRequest };
            obj.BPL_IDAssignedToInvoice =  items[i].Branch;
            
            obj.DocumentLines = [];
            items[i].DepositosCusto = [];
            obj.DocumentLines.push({...items[i], CostingCode: profitCenter});
            console.log(obj)

            const out = await create(obj);

            if (out.data.error) {
                setError({ isError: true, errorMessage: [out.data.error.message.value] });
                return false;
            } 
        }

        if(!error.isError) {
            alert('APONTAMENTO REALIZADO COM SUCESSO!')
            history.push('/home/warehouseExit/list');
        }

    }

    const hasErrors = () => {

        let errorList = [];

        if (!documentDate)
            errorList.push('Data - Selecione uma data válida.');

        if (!selectedLocation)
            errorList.push('Localização - Selecione uma localização válida.');

        return errorList;
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Saída Almoxarifado
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="date"
                                label="Data da Saída"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setDocumentDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Localização</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="localizacao"
                                    id="localizacao"
                                    label="Local de Produção"
                                    value={selectedLocation}
                                    onChange={handleLocationSelection}
                                >
                                    {locationsList.map(location => {
                                        return (
                                            <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="model">Tipo Equipamento</InputLabel>
                                <Select
                                    labelId="tipo"
                                    id="tipo"
                                    label="Tipo Equipamento"
                                    value={proprio}
                                    onChange={(event) => {
                                        setProprio(event.target.value)
                                    }}
                                >
                                    <MenuItem value={0}>Próprio</MenuItem>
                                    <MenuItem value={1}>Terceiro</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            {proprio === 0 &&
                                <EquipmentsAutoComplete
                                    location={selectedLocation}
                                    setEquipment={setEquipament}
                                    addEquipmentField={equipament}
                                />
                            }
                            {proprio === 1 &&
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Equipamento Terceiro"
                                    size="small"
                                    value={equipament}
                                    onChange={(event) => {
                                        setEquipament(event.target.value)
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <CurrencyTextField
                                fullWidth
                                id="medidorvalue"
                                decimalPlaces={1}
                                currencySymbol=""
                                variant="outlined"
                                decimalCharacter=","
                                digitGroupSeparator=""
                                label={!equipament ? '' : equipament.U_Medidor === '' ? '' : (equipament.U_Medidor === 'H' ? 'Horímetro' : 'Odômetro')}
                                size="small"
                                value={medidor}
                                minimumValue="0"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event, value) => {
                                    setMedidor(value);
                                }}
                                disabled={!equipament ? true : equipament.U_Medidor === ''}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <EmployeeAutocomplete
                                label="Operador"
                                addItemField={operador}
                                setInputItem={(item) => {
                                    setOperador(item)
                                }}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="safra">Safra</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="safra"
                                    id="Safra"
                                    label="safra"
                                    defaultValue=""
                                    onChange={(event) => {
                                        SetSafra(event.target.value)
                                    }}
                                >
                                    {safraList.map(item => {
                                        return (
                                            <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                        );
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small" >
                                <InputLabel id="centro-de-custo">Centro de Custo</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="centro-de-custo"
                                    label="Centro de Custo"
                                    onChange={(event) => {
                                        setProfitCenter(event.target.value)
                                    }}
                                >
                                    {profitCenterList.map(profit => {
                                        return (
                                            <MenuItem value={profit.CenterCode} key={uuid()}>{profit.CenterCode + ' - ' + profit.CenterName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={16}>
                            <Button
                                variant="contained"
                                onClick={handleNewItem}
                                size='small'
                                color='primary'
                            >
                                Novo item
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Item</TableCell>
                                            <TableCell>U.M.</TableCell>
                                            <TableCell>Depósito/Estoque</TableCell>
                                            <TableCell>Quantidade</TableCell>
                                            <TableCell>Excluir</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <ItemsAutocomplete
                                                            idx={index}
                                                            addItemField={items[index]}
                                                            setInputItem={setInputItem}
                                                            onExecute={searchListItemWarehouses}
                                                            width={600}                                                            
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            disabled
                                                            variant="outlined"
                                                            label="U.M."
                                                            size="small"
                                                            value={items[index].InventoryUOM}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControl variant="outlined" size="small">
                                                            <Select
                                                                style={{ width: 120 }}
                                                                labelId="deposito"
                                                                id="deposito"
                                                                onChange={(event) => {
                                                                    let tempItems = [...items];                                                                    
                                                                    tempItems[index].WarehouseCode = event.target.value;

                                                                    const brc = mapWarehouseToBranch(event.target.value);
                                                                    console.log(brc)
                                                                    tempItems[index].Branch = brc;
                                                                    tempItems[index].UnitPrice = mapItemWarehousePrice(items[index].DepositosCusto, event.target.value);
                                                                    setItems([...tempItems]);
                                                                    console.log(items[index])
                                                                }}
                                                            >
                                                                {items[index].ListaDepositos?.map(deposito => {
                                                                    return (
                                                                        <MenuItem key={uuid()} value={deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode}>{deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode + ' / ' + deposito["Items/ItemWarehouseInfoCollection"].InStock + ' ' + items[index].InventoryUOM}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            label="Quantidade"
                                                            value={items[index].Quantity}
                                                            onChange={(event) => {
                                                                let tempItems = [...items];
                                                                tempItems[index].Quantity = event.target.value;
                                                                setItems([...tempItems]);
                                                            }}
                                                            InputLabelProps={{

                                                                shrink: true,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            data-key={index}
                                                            onClick={handleDeleteItem}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                                Adicionar
                            </Button>
                            <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home'}>
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress hidden={progressBar} />
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={error.isError}>
                                <Alert severity="error"
                                    action={
                                        <Button color="inherit" size="small"
                                            onClick={() => {
                                                setError({ isError: false, errorMessage: [] });
                                            }}
                                        >
                                            Fechar
                                        </Button>
                                    }
                                >
                                    {error.errorMessage.map(err => {
                                        return (
                                            <>- {err} <br /></>

                                        )
                                    })}
                                </Alert>
                            </Collapse>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Paper>
        </>
    )
}

export default FuelAndLubrification;