import api from '../../services/api.js';

export const findAll = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

    const options = {
        method: 'POST',
        url: '/InventoryGenExits',
        headers: {'Content-Type': 'application/json'},
        data: branchs
      };
      
      return api.request(options).then(function (response) {
        
        return response.data.value;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export const findOne = async (id) => {

  const options = {
      method: 'GET',
      url: `/InventoryGenExits/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const create = async (inventoryGenExitsObject) => {

  const options = {
    method: 'POST',
    url: '/InventoryGenExits/create',
    validateStatus: () => true,
    data: inventoryGenExitsObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    
    return error;
  });
};

export const findOpenProjects = async (id) => {

  const options = {
      method: 'GET',
      url: `/Projects`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findAllFuelAndLubRegisters = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/fuelAndLubrification/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findAllAnimalDeathsRegisters = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/animalDeaths/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findAllWarehouseExitsRegisters = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/warehouseExits/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findAllSeedTreatmentRegisters = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/seedTreatment/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findByReference2 = async (Reference2) => {

  const options = {
      method: 'GET',
      url: `/InventoryGenExits/Ref/${Reference2}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const createInventoryGenEntrie = async (inventoryGenEntrieObject) => {

  const options = {
    method: 'POST',
    url: '/InventoryGenEntries/create',
    validateStatus: () => true,
    data: inventoryGenEntrieObject
  };
  
  return api.request(options).then(function (response) {
    
    return response.data;
  }).catch(function (error) {
    
    return error;
  });
};

export const update = async (DocEntry, inventoryGenExitsObject) => {

  const options = {
    method: 'POST',
    url: `/InventoryGenExits/update/${DocEntry}`,
    validateStatus: () => true,
    data: inventoryGenExitsObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    
    return error;
  });
};

export const findAllOutgoingMailingRegisters = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/outgoingMailing/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};
export const findAllOwnShipmentRegisters = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/ownShipment/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const getEmployeesList = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'GET',
      url: '/EmployeesInfo/list/find',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};