import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import {
    Grid, Paper, TextField, Typography, TableContainer, LinearProgress, Collapse,
    Tabs, Tab, AppBar, Button, Table, TableHead, TableCell, TableBody, TableRow
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { findByReference2, createInventoryGenEntrie, update } from '../../pages/InventoryGenExits/InventoryGenExitsController';
import { findEntryByReference2, createInputsOut, updateInventoryGenEntrie } from './SeedTreatmentController';
import { v4 as uuid } from 'uuid';
import dateFormat from '../../services/dateFormat';
import Alert from '@material-ui/lab/Alert';
import moneyFormat from '../../services/moneyFormat';

const SeedTreatmentView = ({ history }) => {

    let { id } = useParams();

    const [tabs, setTabs] = useState(1);
    const [register, setRegister] = useState({});
    const [lines, setLines] = useState([]);
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [entry, setEntry] = useState({});
    const [docDate, setDocDate] = useState('');
    const [progressBar, setProgressBar] = useState(true);
    const [entryToCancel, setEntryToCancel] = useState({});
    const [cancelado, setCancelado] = useState(false)

    const classes = CreateAndViewStyle();


    useEffect(() => {
        async function fetchData() {
            const reg = await findByReference2(id)
            setLines(reg);

            if (reg[0]) {
                setRegister(reg[0])
            }

            const ent = await findEntryByReference2(id);
            setEntryToCancel(ent);
            if (ent[0].DocumentLines[0]) {
                setEntry(ent[0].DocumentLines[0]);
                setDocDate(ent[0].DocDate)
            }

            if(reg[0].JournalMemo.includes('CANCELADO')) {
                setCancelado(true)
            }

        };
        fetchData();
    }, [id]);

    const handleCancel = async () => {

        setProgressBar(false);
        
        for (const item of lines) {

            const obj = {
                DocDate: item.DocDate,
                JournalMemo: `Estorno B2Agri - Tratamento de Sementes (${item.Reference2})`,
                Reference2: `C${item.Reference2}`,
                U_B2Obj: 'B2AGRI_SEEDTREATCANCEL',
                U_B2AG_B2ObjectType: 'SeedTreatCancel',              
                BPL_IDAssignedToInvoice: item.BPL_IDAssignedToInvoice,
                U_RDA: item.U_RDA
            }

            const mapLines =  item.DocumentLines.map(line => {
                return {
                    ItemCode: line.ItemCode,
                    WarehouseCode: line.WarehouseCode,
                    Quantity: line.Quantity,
                    LineTotal: line.LineTotal,
                    AccountCode: line.AccountCode
                }
            })

            obj.DocumentLines = mapLines;

            console.log(obj)

            const result = await createInventoryGenEntrie(obj);
            console.log(result)

            if (result.error) {
                setError({ isError: true, errorMessage: [result.error.message.value] });
            }

            await update(item.DocEntry, {
                JournalMemo: `CANCELADO - ${item.JournalMemo}`
            })

            const res = await updateInventoryGenEntrie(entry.DocEntry, {
                JournalMemo: `CANCELADO - B2Agri - Tratamento de Sementes ${id}`,
            })
            console.log(res)
            if (res?.error) {

                setError({ isError: true, errorMessage: [result.error.message.value] });

            }
        }

        if (!error.isError) {

            const ent = entryToCancel[0];
            const line = entryToCancel[0].DocumentLines[0];

            const obj = {
                DocDate: ent.DocDate,
                Comments: ent.Comments,
                JournalMemo: `CANCELAMENTO - B2Agri - Tratamento de Sementes (${ent.Reference2})`,
                Reference2: `C${ent.Reference2}`,
                BPL_IDAssignedToInvoice: ent.BPL_IDAssignedToInvoice,
                U_B2Obj: 'B2AGRI_SEEDTREATCANCEL',
                U_B2AG_B2ObjectType: 'SeedTreatCancel',
                U_RDA: ent.U_RDA,
                DocumentLines: [{
                    ItemCode: line.ItemCode,
                    WarehouseCode: line.WarehouseCode,
                    Quantity: line.Quantity,
                    LineTotal: line.LineTotal,
                    AccountCode: line.AccountCode
                }]
            };

            console.log(obj)

            const entryResult = await createInputsOut(obj);

            console.log(entryResult);

            if (entryResult?.error) {

                setError({ isError: true, errorMessage: [entryResult.error.message.value] });

            }
        }

        if (!error.isError) {
            setProgressBar(true);
            alert('CANCELAMENTO REALIZADO COM SUCESSO!')
            history.push('/home/seedTreatment/list');
        }

    }


    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Tratamento de Semente - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="code"
                                label="Código"
                                value={id}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="date"
                                label="Data"
                                value={docDate ? dateFormat(docDate) : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="ItemSemente"
                                label="Item Semente Tratada"
                                value={entry ? `${entry.ItemCode} - ${entry.ItemDescription}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="Quantidade"
                                label="Quantidade"
                                value={entry ? entry.Quantity : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="UM"
                                label="Unidade de Medida"
                                value={entry ? entry.MeasureUnit : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="Deposito"
                                label="Depósito"
                                value={entry ? entry.WarehouseCode : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="RDA"
                                label="RDA"
                                value={register ? register.U_RDA : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>U.M.</TableCell>
                                            <TableCell align="right">Quantidade</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lines &&
                                            lines.map((item, index) => {
                                                return (
                                                    item.DocumentLines.map((it, index) => {
                                                        return (
                                                            <TableRow key={uuid()}>
                                                                <TableCell>{it.ItemCode}</TableCell>
                                                                <TableCell>{it.ItemDescription}</TableCell>
                                                                <TableCell>{it.MeasureUnit}</TableCell>
                                                                <TableCell align="right">{moneyFormat(it.Quantity)}</TableCell>
                                                            </TableRow>);
                                                    })
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                                value={`${register.JournalMemo} - ${register.Comments}`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button color="primary" className={classes.button} disabled={cancelado} variant="outlined" onClick={handleCancel}>
                                Cancelar Apontamento
                            </Button>
                            <Button variant="contained" color="primary" className={classes.button} component={Link} to={'/home/seedTreatment/list'}>
                                Voltar
                            </Button>
                        </Grid>

                    </Grid>
                </TabPanel>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    )
}

export default SeedTreatmentView;