import api from '../../services/api.js';

export default {

  getUserBranchs(email) {

    const options = {
        method: 'GET',
        url: `/UserBranchPermission/${email}`,
        headers: {'Content-Type': 'application/json'}
      };
      
      return api.request(options).then(function (response) {

        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
  },

  getUserBranchsInfo(branchsArray) {

    const data = {
      BranchsArray: branchsArray
    };

    const options = {
        method: 'POST',
        url: `/UserBranchPermission`,
        headers: {'Content-Type': 'application/json'},
        data: data
      };
      
      return api.request(options).then(function (response) {
        
        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
  },

}