import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import { viewOnePurchaseOrder, viewOneApReserveInvoice, create } from './PurchaseDeliveryNotesController';
import { findAll } from '../../components/AdditionalExpenses/AdditionalExpensesController';
import { createAttachment } from '../../services/AttachmentController';
import {
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Typography, Collapse,
    LinearProgress, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions,
    Tabs, Tab, AppBar, IconButton, Box
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moneyFormat from '../../services/moneyFormat';
import getTodayDate from '../../services/getTodayDate';
import { v4 as uuid } from 'uuid';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import FileUpload from '../../components/FileUpload/FileUpload';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { UnitOfMeasurementGroupsByAbsEntry, UnitOfMeasurementsList } from '../../pages/DefaultController';
import { findByCode } from '../Items/ItemsController'
import TabPanel from '../../components/TabPanel';
import { findByBranchId } from '../../components/Deposits/DepositsController';

const PurchaseDeliveryNotesCreate = ({ history }) => {

    let { id } = useParams();
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [nf, setNF] = useState('');
    const [nfSerie, setNfSerie] = useState('');
    const [incoterms, setIncoterms] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [vehicleState, setVehicleState] = useState('');
    const [sequenceCode, setSequenceCode] = useState(-2);
    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [comment, setComment] = useState('');
    const [acessKey, setAcessKey] = useState('');
    const [items, setItems] = useState([]);
    const [quantitys, setQuantitys] = useState([]);
    const [prices, setPrices] = useState([]);
    const [pricesTot, setPricesTot] = useState([]);
    const [additionalExpenses, setAdditionalExpenses] = useState([])
    const [additionalExpensesValues, setAdditionalExpensesValues] = useState([]);
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [progressBar, setProgressBar] = useState(true);
    const [open, setOpen] = useState(false);
    const [tabs, setTabs] = useState(1);
    const [fileList, setFileList] = useState([]);
    const classes = CreateAndViewStyle();
    const [totalGeral, setTotalGeral] = useState(0.0);
    const [totalGeralDespAdicional, setTotalGeralDespAdicional] = useState(0.0);
    const [deposits, setDeposits] = useState([]);
    const [baseType, setBaseType] = useState(0);

    useEffect(() => {


        async function fetchData() {

            //calculo do total geral (itens + desp. adic.)
            let totalAdic = additionalExpensesValues.reduce((accumulated, current) => accumulated + parseFloat(current ? current : 0), 0.0)
            setTotalGeralDespAdicional(totalAdic)
            let totalValuesItens = pricesTot.reduce((accumulated, current) => accumulated + parseFloat(current ? current : 0), 0.0)
            setTotalGeral((totalValuesItens ? totalValuesItens : 0) + (totalAdic ? totalAdic : 0))

            if (purchaseOrder.DocNum === undefined) {
                
                let oPurchaseOrder = {}

                const splitedId = id.split('-');
                console.log( splitedId)

                if (splitedId[0]==='PC') {
                    setBaseType(22);
                    oPurchaseOrder = await viewOnePurchaseOrder(splitedId[1])
                } else {
                    setBaseType(18);
                    oPurchaseOrder = await viewOneApReserveInvoice(splitedId[1])
                }

                setPurchaseOrder({
                    "DocNum": oPurchaseOrder.DocNum,
                    "DocDate": oPurchaseOrder.DocDate,
                    "CardCode": oPurchaseOrder.CardCode,
                    "CardName": oPurchaseOrder.CardName,
                    "FederalTaxID": oPurchaseOrder.FederalTaxID,
                    "BPLName": oPurchaseOrder.BPLName,
                    "DocEntry": oPurchaseOrder.DocEntry,
                    "DocRate": oPurchaseOrder.DocRate,
                    "DocCurrency": oPurchaseOrder.DocCurrency,
                    "DocumentLines": oPurchaseOrder.DocumentLines,
                    "BPL_IDAssignedToInvoice": oPurchaseOrder.BPL_IDAssignedToInvoice
                });

                //Carregar lista de depósitos.
                let branchDeposits = await findByBranchId(oPurchaseOrder.BPL_IDAssignedToInvoice)
                setDeposits(branchDeposits);

                // Não exibe itens totalmente recebidos
                let itemsWithQuantityOpen = [];
                oPurchaseOrder.DocumentLines.forEach((item) => {
                    if (item.RemainingOpenQuantity > 0) {
                        item.ListaUnidades = []
                        itemsWithQuantityOpen.push(item)

                    }
                });
                setItems(itemsWithQuantityOpen);
            }
        };
        fetchData();
    }, [id, purchaseOrder, totalGeral, totalGeralDespAdicional, additionalExpensesValues, pricesTot, items]);

    useEffect(() => {

        if (items.length > 0) {

            for (let i = 0; i < items.length; i++) {
                if (items[i].ListaUnidades.length === 0) {
                    findByCode(items[i].ItemCode)
                        .then(itemObj => {
                            UnitOfMeasurementGroupsByAbsEntry(itemObj.UoMGroupEntry)
                                .then(grupoUn => {
                                    let listaUnidades = ''
                                    grupoUn.UoMGroupDefinitionCollection?.forEach(element => {
                                        if (listaUnidades !== '') {
                                            listaUnidades = listaUnidades + `,${element.AlternateUoM}`
                                        } else {
                                            listaUnidades = element.AlternateUoM
                                        }
                                    })

                                    UnitOfMeasurementsList(listaUnidades)
                                        .then(unidades => {
                                            let tempItems = [...items];
                                            tempItems[i].ListaUnidades = unidades
                                            setItems(tempItems);
                                        })
                                })
                        })
                }
            }

        }

    }, [items]);

    useEffect(() => {
        async function fetchData() {
            const result = await findAll();
            setAdditionalExpenses(result);

        };

        fetchData();
    }, []);

    const handleDeleteItem = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...fileList];
        newItems.splice(index, 1);
        setFileList(newItems);
    }

    const sendAttachments = async () => {

        if (fileList.length > 0) {

            let formData = new FormData();

            await fileList.forEach((file, index) => {
                formData.append(`file_${index}`, file, file.name);
            });

            return await createAttachment(formData)
                .then((result) => {
                    return result;
                })
                .catch((error) => {
                    console.log('Caiu no error')
                    return error;
                });
        }
    }

    const handleSubmit = async () => {
        setProgressBar(false);

        const errorList = hasErrors();

        if (errorList.length === 0) {

            const attachmentResult = await sendAttachments();

            let finalItens = [];
            for (let i = 0; i < quantitys.length; i++) {
                finalItens.push({
                    "ItemCode": items[i].ItemCode,
                    "Quantity": quantitys[i],
                    "Currency": "R$",
                    "UnitPrice": prices[i],
                    "BaseEntry": purchaseOrder.DocEntry,
                    "BaseLine": items[i].LineNum,
                    "BaseType": baseType,
                    "UoMEntry": items[i].UoMEntry,
                    "WarehouseCode": items[i].WarehouseCode
                });
            }
            // Remove itens com quantidade zerada
            let finalItensWithoutZero = [];
            finalItens.forEach((item) => {
                if (item.Quantity > 0) {
                    finalItensWithoutZero.push(item);
                }
            });

            finalItens = finalItensWithoutZero;

            // Despesas Adicionais        
            let finalAdditionalExpenses = [];
            for (let i = 0; i < additionalExpenses.length; i++) {

                if (additionalExpensesValues[i] > 0) {

                    finalAdditionalExpenses.push({
                        "ExpenseCode": additionalExpenses[i].ExpensCode,
                        "LineTotal": parseFloat(additionalExpensesValues[i]),
                    });
                }
            }

            let finalPurchaseDeliveryNote = {
                "DocDate": documentDate,
                "BPL_IDAssignedToInvoice": purchaseOrder.BPL_IDAssignedToInvoice,
                "CardCode": purchaseOrder.CardCode,
                "CardName": purchaseOrder.CardName,
                "SequenceCode": sequenceCode,
                "SequenceSerial": nf,
                "SeriesString": nfSerie,
                "U_ChaveAcesso": acessKey,  // Definir nome do campo conforme addon
                "U_nfe_ChaveAcesso": acessKey,  // Definir nome do campo conforme addon            
                "Comments": comment,
                "DocumentLines": finalItens,
                "AttachmentEntry": attachmentResult ? attachmentResult.AbsoluteEntry : null,
                "DocumentAdditionalExpenses": finalAdditionalExpenses,
                "DocumentsOwner": localStorage.getItem('@SAP:EMPLOYEEID'),
                "TaxExtension": {
                    "Incoterms": incoterms,
                    "Vehicle": vehicle,
                    "VehicleState": vehicleState,
                }
            }

            const result = await create(finalPurchaseDeliveryNote);
            setProgressBar(true);

            if (result.data.error) {
                const errorList = [];
                errorList.push(result.data.error.message.value);
                setError({ isError: true, errorMessage: errorList })

            } else {
                alert('RECEBIMENTO REALIZADO COM SUCESSO!');
                history.push('/home/purchaseDeliveryNotes');
            }

        } else {
            setError({ isError: true, errorMessage: errorList });
            setProgressBar(true);
        }


    }

    const handleFileAdd = (file) => {
        const tempFiles = [...fileList, file];
        setFileList(tempFiles);
    }

    const handleQuantityReceived = (event, value) => {
        const index = event.target.dataset.key;
        let tempArray = [...quantitys];
        tempArray[index] = value;
        setQuantitys(tempArray);

    }

    const handleUnitPrice = (event, value) => {
        const index = event.target.dataset.key;

        if (value >= 0) {
            let tempArray = [...prices];
            tempArray[index] = value;
            setPrices(tempArray);
        }
    }


    const handleTotal = (event, value) => {

        const index = event.target.dataset.key;
        let tempArray = [...pricesTot];
        tempArray[index] = (quantitys[index] * prices[index]);
        setPricesTot(tempArray);
    }

    const handleAdditionalExpensesValues = (event, value) => {
        const index = event.target.dataset.key;

        if (value >= 0) {
            let tempArray = [...additionalExpensesValues];
            tempArray[index] = value;
            setAdditionalExpensesValues(tempArray);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleConfirmExpenses = () => {

        setOpen(false);
    }

    const hasErrors = () => {

        let errorList = [];

        if (!documentDate)
            errorList.push('Data do Documento - Selecione uma data válida para o recebimento.');

        if (!nf)
            errorList.push('Nota Fiscal - Informe o número da nota fiscal.');

        if (!nfSerie)
            errorList.push('Série - Informe o número da série da nota fiscal.');

        if (!acessKey)
            errorList.push('Chave de Acesso - Informe o número da chave de acesso da nota fiscal.');

        return errorList;

    }

    return (
        <>           
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Recebimento de Mercadoria - Adicionar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                    <Tab label="Anexos" value={2} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>


                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item >
                            <TextField
                                id="date"
                                label="Data do Documento"
                                type="date"
                                value={documentDate}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    setDocumentDate(event.target.value)
                                }}
                                helperText="* Obrigatório"
                            />
                        </Grid>
                        <Grid item >
                            <TextField
                                id="outlined-multiline-static"
                                label="Filial"
                                variant="outlined"
                                size="small"
                                value={purchaseOrder.BPLName}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item >
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="sequenceCode">Sequência</InputLabel>
                                <Select
                                    labelId="sequenceCode"
                                    id="sequenceCode"
                                    label="Sequência"
                                    defaultValue=""
                                    value={sequenceCode}
                                    onChange={event => {
                                        setSequenceCode(event.target.value)
                                    }}
                                >
                                    <MenuItem value={-2}>Externo</MenuItem>
                                    <MenuItem value={-1}>Manual</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item >
                            <TextField
                                autoFocus
                                id="nf"
                                label="Nota Fiscal"
                                type="text"
                                value={nf}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    setNF(event.target.value)
                                }}
                                helperText="* Obrigatório"
                            />
                        </Grid>
                        <Grid item >
                            <TextField
                                id="nfSeries"
                                label="Série"
                                type="text"
                                value={nfSerie}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    setNfSerie(event.target.value)
                                }}
                                helperText="* Obrigatório"
                            />
                        </Grid>
                        <Grid item >
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="tipoFrete">Tipo Frete</InputLabel>
                                <Select
                                    style={{ width: 180 }}
                                    labelId="tipoFrete"
                                    id="tipoFrete"
                                    label="Tipo Frete"
                                    defaultValue=""
                                    value={incoterms}
                                    onChange={event => {
                                        setIncoterms(event.target.value)
                                    }}
                                >
                                    <MenuItem value={0}>0 - Frete por conta do Remetente (CIF)</MenuItem>
                                    <MenuItem value={1}>1 - Frete por conta do Destinatário (FOB)</MenuItem>
                                    <MenuItem value={2}>2 - Frete por conta de Terceiros</MenuItem>
                                    <MenuItem value={3}>3 - Transporte Próprio por conta do Remetente</MenuItem>
                                    <MenuItem value={4}>4 - Transporte Próprio por conta do Destinatário</MenuItem>
                                    <MenuItem value={9}>9 - Sem Ocorrência de Transporte</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item >
                            <TextField
                                id="placaVeiculo"
                                label="Placa"
                                type="text"
                                value={vehicle}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    setVehicle(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="estadoVeiculo">UF Veículo</InputLabel>
                                <Select
                                    style={{ width: 180 }}
                                    labelId="estadoVeiculo"
                                    id="estadoVeiculo"
                                    label="UF Veiculo"
                                    defaultValue=""
                                    value={vehicleState}
                                    onChange={event => {
                                        setVehicleState(event.target.value)
                                    }}
                                >
                                    <MenuItem value={'AC'}>Acre</MenuItem>
                                    <MenuItem value={'AL'}>Alagoas</MenuItem>
                                    <MenuItem value={'AP'}>Amapá</MenuItem>
                                    <MenuItem value={'AM'}>Amazonas</MenuItem>
                                    <MenuItem value={'BA'}>Bahia</MenuItem>
                                    <MenuItem value={'CE'}>Ceará</MenuItem>
                                    <MenuItem value={'DF'}>Distrito Federal</MenuItem>
                                    <MenuItem value={'ES'}>Espírito Santo</MenuItem>
                                    <MenuItem value={'GO'}>Goiás</MenuItem>
                                    <MenuItem value={'MA'}>Maranhão</MenuItem>
                                    <MenuItem value={'MT'}>Mato Grosso</MenuItem>
                                    <MenuItem value={'MS'}>Mato Grosso do Sul</MenuItem>
                                    <MenuItem value={'MG'}>Minas Gerais</MenuItem>
                                    <MenuItem value={'PA'}>Pará</MenuItem>
                                    <MenuItem value={'PB'}>Paraíba</MenuItem>
                                    <MenuItem value={'PR'}>Paraná</MenuItem>
                                    <MenuItem value={'PE'}>Pernambuco</MenuItem>
                                    <MenuItem value={'PI'}>Piauí</MenuItem>
                                    <MenuItem value={'RJ'}>Rio de Janeiro</MenuItem>
                                    <MenuItem value={'RN'}>Rio Grande do Norte</MenuItem>
                                    <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
                                    <MenuItem value={'RO'}>Rondônia</MenuItem>
                                    <MenuItem value={'RR'}>Roraima</MenuItem>
                                    <MenuItem value={'SC'}>Santa Catarina</MenuItem>
                                    <MenuItem value={'SP'}>São Paulo</MenuItem>
                                    <MenuItem value={'SE'}>Sergipe</MenuItem>
                                    <MenuItem value={'TO'}>Tocantins</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="chavedeacesso"
                                label="Chave de Acesso NF"
                                type="text"
                                value={acessKey}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    setAcessKey(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="pn"
                                label="Fornecedor"
                                type="text"
                                value={purchaseOrder.CardCode + ' - ' + purchaseOrder.CardName + ' - ' + purchaseOrder.FederalTaxID}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="DocCurrency"
                                label="Moeda/Taxa"
                                type="text"
                                value={purchaseOrder.DocCurrency + (purchaseOrder.DocRate != 1 ? '  ' + purchaseOrder.DocRate : '')}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                            </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>Qtde/UM</TableCell>
                                            <TableCell>Quantidade Recebida</TableCell>
                                            <TableCell>Un. Med.</TableCell>
                                            <TableCell >Valor Unitário</TableCell>
                                            <TableCell>Depósito</TableCell>
                                            <TableCell>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{item.ItemCode}</TableCell>
                                                    <TableCell>
                                                        <Box style={{ width: 180 }}>
                                                            {item.ItemDescription}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>{item.RemainingOpenQuantity} / {item.UoMCode}</TableCell>
                                                    <TableCell>
                                                        <CurrencyTextField
                                                            id={item.LineNumber}
                                                            currencySymbol=""
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            decimalPlaces={"4"}
                                                            minimumValue="0"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                'data-key': index,
                                                            }}
                                                            value={quantitys[index]}
                                                            onChange={handleQuantityReceived}
                                                            onBlur={handleTotal}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControl variant="outlined" size="small">
                                                            <Select
                                                                style={{ width: 120 }}
                                                                labelId="und"
                                                                id="und"
                                                                onChange={(event) => {
                                                                    let tempItems = [...items];
                                                                    tempItems[index].UoMEntry = event.target.value;
                                                                    setItems([...tempItems]);
                                                                }}
                                                            >
                                                                {item.ListaUnidades.map(unidade => {
                                                                    return (
                                                                        <MenuItem value={unidade.AbsEntry}>{unidade.Code}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell >
                                                        <CurrencyTextField
                                                            style={{ width: '120px' }}
                                                            id={item.LineNumber}
                                                            variant="outlined"
                                                            currencySymbol="R$"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            decimalPlaces={"6"}
                                                            minimumValue="0"
                                                            placeholder={moneyFormat(item.Price).toString()}
                                                            size="small"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                'data-key': index,
                                                            }}
                                                            value={prices[index]}
                                                            onChange={handleUnitPrice}
                                                            onBlur={handleTotal}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControl variant="outlined" size="small">
                                                            <Select
                                                                style={{ width: 120 }}
                                                                labelId="deposito"
                                                                value={items[index].WarehouseCode}
                                                                title={items[index].WarehouseCode}
                                                                onChange={event => {
                                                                    let tempItems = [...items];
                                                                    tempItems[index].WarehouseCode = event.target.value;
                                                                    setItems(tempItems);
                                                                }}
                                                            >
                                                                {deposits.map(deposit => {
                                                                    return (
                                                                        <MenuItem value={deposit.WarehouseCode} key={uuid()}>{deposit.WarehouseCode + ' - ' + deposit.WarehouseName}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell>
                                                        <CurrencyTextField
                                                            style={{ width: '120px' }}
                                                            id={item.LineNumber}
                                                            variant="outlined"
                                                            currencySymbol="R$"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            decimalPlaces={"6"}
                                                            minimumValue="0"
                                                            size="small"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                'data-key': index,
                                                            }}
                                                            value={pricesTot[index]}
                                                            disabled
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={10} align="right">
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpen}
                                    size='small'
                                    color='primary'
                                >
                                    Despesas Adicionais
                                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <CurrencyTextField
                                    id="tdespadic"
                                    //variant="outlined"
                                    currencySymbol="R$"
                                    decimalCharacter=","
                                    digitGroupSeparator="."
                                    minimumValue="0"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={totalGeralDespAdicional}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={10} align="right">
                                <Box padding={1}>TOTAL GERAL</Box>
                            </Grid>
                            <Grid item xs={2}>
                                <CurrencyTextField
                                    id="tgeral"
                                    //variant="outlined"
                                    currencySymbol="R$"
                                    decimalCharacter=","
                                    digitGroupSeparator="."
                                    minimumValue="0"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={totalGeral}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                value={comment}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={(event) => {
                                    setComment(event.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabs} index={2}>
                    <FileUpload
                        handleFileAdd={handleFileAdd}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Arquivo</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fileList.map((file, index) => {
                                    return (
                                        <TableRow
                                            key={uuid()}
                                        >
                                            <TableCell>{file.name}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    component="span"
                                                    data-key={index}
                                                    onClick={handleDeleteItem}
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <Grid item xs={12} sm={6}>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Adicionar
                    </Button>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link}
                        to={'/home/purchaseDeliveryNotes'}
                    >
                        Voltar
                        </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                            </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Despesas Adicionais</DialogTitle>
                <DialogContent>
                    <Grid container>
                        {additionalExpenses.map((expense, index) => {
                            return (
                                <Grid item xs={12} className={classes.gridPadding}>
                                    <CurrencyTextField
                                        id={expense.Name}
                                        variant="outlined"
                                        currencySymbol="R$"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        minimumValue="0"
                                        placeholder="R$ 0,00"
                                        label={expense.Name}
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            'data-key': index,
                                        }}
                                        value={additionalExpensesValues[index]}
                                        onChange={handleAdditionalExpensesValues}
                                        onBlur={handleTotal}
                                    />
                                </Grid>
                            );
                        })}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmExpenses} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PurchaseDeliveryNotesCreate;