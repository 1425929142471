import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import {
    Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
    Tabs, Tab, AppBar, Button, LinearProgress, Collapse,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import Alert from '@material-ui/lab/Alert';
import TabPanel from '../../components/TabPanel';
import { searchByGroupCode } from '../../components/Items/ItemsController'
import { createInventoryGenEntrie } from './AnimalLifeCycleController';
import { findByLocationId } from '../../components/Deposits/DepositsController';
import referenceid from '../../services/referenceid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { WarehouseLocations, WarehouseToBranchList } from '../DefaultController';

const AnimalBirthRecordCreate = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [progressBar, setProgressBar] = useState(true);
    const classes = CreateAndViewStyle();
    const [error, setError] = useState({ isError: false, errorMessage: [] });

    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [item, setItem] = useState('');
    const [itemList, setItemList] = useState([]);
    const [quantity, setQuantity] = useState('');
    const [unitCost, setUnitCost] = useState(0);
    const [comments, setComments] = useState('');
    const [deposit, setDeposit] = useState('');
    const [depositList, setDepositList] = useState([]);
    const [warehouseToBranchList, setWarehouseToBranchList] = useState([]);


    useEffect(() => {
        async function fetchData() {

            const loc = await WarehouseLocations()
            setLocationsList(loc);
        };
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const it = await searchByGroupCode(132);
            setItemList(it)
        };
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {

            const whs = await WarehouseToBranchList()
            setWarehouseToBranchList(whs)
        };
        fetchData();
    }, []);


    const handleLocationSelection = async (event) => {
        setSelectedLocation(event.target.value);
        const whs = await findByLocationId(event.target.value)
        setDepositList(whs)
    }

    const mapWarehouseToBranch = (WhsCode) => {
        let result = null;

        warehouseToBranchList.forEach((value) => {
            if (value.WarehouseCode === WhsCode)
                result = value.BusinessPlaceID
        })
        return result;
    }

    const handleSubmit = async () => {

        setProgressBar(false);

        const refid = referenceid();

        const obj = {
            DocDate: documentDate,
            JournalMemo: `B2Agri - Registro de Nascimento de Animais (${refid})`,
            Reference2: refid,
            U_B2Obj: 'B2AGRI_ANIMALBIRTH',
            Comments: comments,
            DocumentLines: [{
                ItemCode: item,
                WarehouseCode: deposit,
                Quantity: quantity,
                UnitPrice: unitCost
            }],
            BPL_IDAssignedToInvoice: mapWarehouseToBranch(deposit)
        }
        
        if(!hasErrors()) {

            const result = await createInventoryGenEntrie(obj);
                
            if (result.data?.error) {
                
                setError({ isError: true, errorMessage: [result.data.error.message.value] });

            } else {
                alert('APONTAMENTO REALIZADO COM SUCESSO!')
                history.push('/home/animalbirthrecord/list');
            }
        }

        setProgressBar(true);
    }

    const hasErrors = () => {

        let errorList = [];

        if (!documentDate)
            errorList.push('Data - Selecione uma data válida.');

        if (!selectedLocation)
            errorList.push('Localização - Selecione uma localização válida.');

        if (!item)
            errorList.push('Item - Selecione um item válido.');

        if (quantity <= 0 || !quantity)
            errorList.push(`Item - Quantidade deve ser maior que zero.`);

        if (!unitCost)
            errorList.push(`Item - Custo unitário deve ser preenchido.`);

        if(errorList.length > 0) {
            setError({isError: true, errorMessage: errorList})
            return true
        } else {
            return false
        }
        
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Registro de Nascimento de Animal
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="date"
                                label="Data da Saída"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setDocumentDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Localização</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="localizacao"
                                    id="localizacao"
                                    label="Local de Produção"
                                    value={selectedLocation}
                                    onChange={handleLocationSelection}
                                >
                                    {locationsList.map(location => {
                                        return (
                                            <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="deposito">Depósito</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="deposito"
                                    label="Depósito"
                                    id="deposito-select"
                                    value={deposit}
                                    onChange={(event) => {
                                        setDeposit(event.target.value)
                                    }}
                                >
                                    {depositList?.map(deposito => {
                                        return (
                                            <MenuItem key={uuid()} value={deposito.WarehouseCode}>{deposito.WarehouseCode + ' - ' + deposito.WarehouseName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="item">Item</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="item"
                                    id="item"
                                    label="Item"
                                    defaultValue=""
                                    value={item}
                                    onChange={(event) => {
                                        setItem(event.target.value)
                                    }}
                                >
                                    {itemList.map(item => {
                                        return (
                                            <MenuItem value={item.ItemCode} key={uuid()}>{item.ItemCode + ' - ' + item.ItemName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <CurrencyTextField
                                fullWidth
                                variant="outlined"
                                currencySymbol=""
                                decimalCharacter=","
                                digitGroupSeparator="."
                                minimumValue="0"
                                label="Quantidade"
                                size="small"
                                value={quantity}
                                onChange={(event, value) => {
                                    setQuantity(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CurrencyTextField
                                fullWidth
                                variant="outlined"
                                currencySymbol="R$"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                minimumValue="0"
                                label="Custo Unitário"
                                size="small"
                                value={unitCost}
                                onChange={(event, value) => {
                                    setUnitCost(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                                Adicionar
                            </Button>
                            <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home'}>
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress hidden={progressBar} />
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={error.isError}>
                                <Alert severity="error"
                                    action={
                                        <Button color="inherit" size="small"
                                            onClick={() => {
                                                setError({ isError: false, errorMessage: [] });
                                            }}
                                        >
                                            Fechar
                                        </Button>
                                    }
                                >
                                    {error.errorMessage.map(err => {
                                        return (
                                            <>- {err} <br /></>

                                        )
                                    })}
                                </Alert>
                            </Collapse>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Paper>
        </>
    )
}

export default AnimalBirthRecordCreate;