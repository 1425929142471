import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findOperationsRegister } from './AgriculturalOperationsController';
import dateFormat from '../../services/dateFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 90,
    },
    {
        field: 'U_RDA',
        headerName: 'RDA',
        width: 120,
    },
    {
        field: 'Reference2',
        headerName: 'Código',
        width: 140,
    },
    {
        field: 'DocDate',
        headerName: 'Data',
        width: 140,
    },
    {
        field: 'U_OperacaoAgricola',
        headerName: 'Operação',
        width: 250,
    },
    {
        field: 'U_Talhao',
        headerName: 'Talhão',
        width: 140,
    },
    {
        field: 'U_AreaRealizada',
        headerName: 'Área Realizada',
        width: 140,
    },
    {
        field: 'U_Cultura',
        headerName: 'Cultura',
        width: 140,
    },
    {
        field: 'Comments',
        headerName: 'Observação',
        width: 300,
    }
];

const useStyles = ListDocumentsStyle;

const AgriculturalOperationsList = ({ history }) => {

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const result = await findOperationsRegister();
            
            if (result) {
                let final = result.data.map((item, index) => {
                    return {
                        id: index,
                        U_RDA: item.InventoryGenExits.U_RDA,
                        Reference2: item.InventoryGenExits.Reference2,
                        DocDate: dateFormat(item.InventoryGenExits.DocDate),
                        U_OperacaoAgricola: item.OperacoesAgricolas.Name,
                        U_Talhao: item.InventoryGenExits.U_Talhao,
                        U_AreaRealizada: item.InventoryGenExits.U_AreaRealizada,
                        U_Cultura: item.Cultura.Name,
                        Comments: item.InventoryGenExits.JournalMemo

                    }
                })

                let filtered = final.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.Reference2 === item.Reference2
                    ))
                )

                setRowsData(filtered)
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Operações Agrícolas
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    sumField={'U_AreaRealizada'}
                    sumFieldText={'Total Área Realizada'}
                    onRowClick={(event) => {
                        
                        history.push(`/home/agriculturalOperations/view/${event.row.Reference2}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/agriculturalOperations/create'}>
                            Nova Operação Agrícola
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default AgriculturalOperationsList;