import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import dateFormat from '../../services/dateFormat';
import {
    Grid, Paper, TextField, Typography, TableContainer, Collapse, LinearProgress,
    Tabs, Tab, AppBar, Button, Table, TableHead, TableCell, TableBody, TableRow
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { findOne, cancel } from './WeatherRecordController';
import Alert from '@material-ui/lab/Alert';

import { v4 as uuid } from 'uuid';

const WeatherRecordView = ({ history }) => {

    let { id } = useParams();

    const [tabs, setTabs] = useState(1);
    const [register, setRegister] = useState({});
    const [lines, setLines] = useState([]);
    const [progressBar, setProgressBar] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: [] });   

    const classes = CreateAndViewStyle();


    useEffect(() => {
        async function fetchData() {
            const reg = await findOne(id)

            if (reg) {
                setRegister(reg);
                setLines(reg.AP_CLIM_LINCollection)
            }

        };
        fetchData();
    }, [id]);

    const status = (code) => {
        if (code === 'Y') {
            return 'Cancelado'
        } else {
            return 'Aberto'
        }
    }

    const handleCancel = async () => {

        setProgressBar(false);

        const result = await cancel(id);

        if (result?.error) {               
            setError({ isError: true, errorMessage: [result.error.message.value] });
        } else {
            setProgressBar(true);
            alert('CANCELAMENTO REALIZADO COM SUCESSO!')
            history.push('/home/weatherRecord/list');
        }  
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Registro Climático - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="code"
                                label="Código"
                                value={id}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="data"
                                label="Data"
                                value={register.U_Data ? dateFormat(register.U_Data) : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                id="Posto"
                                label="Posto Meteorológico"
                                value={register ? register.U_PostoID : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="Status"
                                label="Status"
                                value={register.Canceled ? status(register.Canceled) : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Elemento</TableCell>
                                            <TableCell>Valor</TableCell>                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lines &&
                                            lines.map((item, index) => {
                                                return (
                                                    <TableRow key={uuid()}>
                                                        <TableCell>{item.U_Elemento}</TableCell>
                                                        <TableCell>{item.U_Valor}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                                value={register ? register.U_Obs : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button color="primary" className={classes.button} variant="outlined" onClick={handleCancel}>
                                Cancelar Apontamento
                            </Button>
                            <Button variant="contained" color="primary" className={classes.button} component={Link} to={'/home/weatherRecord/list'}>
                                Voltar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress hidden={progressBar} />
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={error.isError}>
                            <Alert severity="error"
                                action={
                                    <Button color="inherit" size="small"
                                        onClick={() => {
                                            setError({ isError: false, errorMessage: [] });
                                        }}
                                    >
                                        Fechar
                                    </Button>
                                }
                            >
                                {error.errorMessage.map(err => {
                                    return (
                                        <>- {err} <br /></>

                                    )
                                })}
                            </Alert>
                        </Collapse>
                    </Grid>
                </TabPanel>
            </Paper>
        </>
    )
}

export default WeatherRecordView;