import React, { useState } from 'react';
import { Button, TextField, Grid } from '@material-ui/core';
import { getDanfe, getXml } from '../../../components/OOBJ/NFeController';
import { Refresh, Note, Code } from '@material-ui/icons';
import NFeCancelDialog from './NFeCancelDialog';
import { cancelInvoice, updateInvoice } from './Controller';
import { useHistory } from 'react-router-dom';
import sanitizeString from '../../../services/sanitizeString'

const NfeData = (props) => {

    const [status, setStatus] = useState('');
    const [motivo, setMotivo] = useState('');
    const [chaveAcesso, setChaveAcesso] = useState('');
    const [XML, setXML] = useState('');
    const [autorizada, setAutorizada] = useState(true);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [progressBar, setProgressBar] = useState(true);
    const [cancelMotive, setCancelMotive] = useState('');

    let history = useHistory();

    const querySefaz = async () => {

        setAutorizada(true);

        let APIToken = '';
        let APISecret = '';

        let CpfCnpj = '';
        let Ambiente = '';
        let Modelo = '55';
        let Ano = props.nfObj.DocDate.substring(0, 4);
        let Numero = props.nfObj.SequenceSerial;
        let Serie = props.nfObj.SeriesString;

        const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));
        branchs.forEach((item) => {
            if (item.BPLID === props.nfObj.BPL_IDAssignedToInvoice) {
                CpfCnpj = item.TaxOffice.replace(/\D/g, "");
                APIToken = item.U_APIToken;
                APISecret = item.U_APISecret;
                Ambiente = item.EnvironmentType === 1 ? 'prod' : 'hom';
            }
        })

        const nf = await getXml(APIToken, APISecret, CpfCnpj, Modelo, Ano, Numero, Serie, Ambiente);

        if (!nf.error) {

            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(nf, "text/xml");

            setMotivo(xmlDoc.getElementsByTagName("xMotivo")[0].childNodes[0].nodeValue)
            setStatus(xmlDoc.getElementsByTagName("status")[0].childNodes[0].nodeValue)
            setChaveAcesso(xmlDoc.getElementsByTagName("chaveAcesso")[0].childNodes[0].nodeValue)
            setXML(xmlDoc.getElementsByTagName("conteudo")[0].childNodes[0].nodeValue)
            if (xmlDoc.getElementsByTagName("status")[0].childNodes[0].nodeValue === 'Autorizada') {
                setAutorizada(false);
            }
        } else {
            if (nf.error.message) {
                setStatus(nf.error.message)
            }
        }
    }

    const queryDanfe = async () => {

        let APIToken = '';
        let APISecret = '';

        let CpfCnpj = '';
        let Ambiente = '';
        let Modelo = '55';
        let Ano = props.nfObj.DocDate.substring(0, 4);
        let Numero = props.nfObj.SequenceSerial;
        let Serie = props.nfObj.SeriesString;

        const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));
        branchs.forEach((item) => {
            if (item.BPLID === props.nfObj.BPL_IDAssignedToInvoice) {
                CpfCnpj = item.TaxOffice.replace(/\D/g, "");
                APIToken = item.U_APIToken;
                APISecret = item.U_APISecret;
                Ambiente = item.EnvironmentType === 1 ? 'prod' : 'hom';
            }
        })

        const nf = await getDanfe(APIToken, APISecret, CpfCnpj, Modelo, Ano, Numero, Serie, Ambiente);

        var file = new Blob([nf], { type: 'application/pdf' });
        var fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
    }

    const queryXML = () => {
        var file = new Blob([XML], { type: 'text/xml' });
        var fileURL = window.URL.createObjectURL(file);
        let a = document.createElement('A');
        a.href = fileURL;
        a.download = fileURL.substr(fileURL.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }


    const cancelNFe = async () => {

        if (cancelMotive.length < 15) {
            alert('Motivo obrigatório - mínimo 15 caracteres');
        } else {

      
        if(props.nfObj.DocEntry) {

            setProgressBar(false);

            await updateInvoice({
                DocEntry: props.nfObj.DocEntry,
                U_TX_Just : sanitizeString(cancelMotive) 
            })
            
            const result = await cancelInvoice(props.nfObj.DocEntry)
            if(result.status === 200) {
                alert('NFE CANCELADA COM SUCESSO!');  
                            
                history.push(`/home/outboundListing/list`);
            } else {
                alert('ERRO AO CANCELAR NFE');
            }
        }  
        setProgressBar(true);  
        
        }


    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        id="SequenceSerial"
                        label="Nº NF"
                        value={props.nfObj.SequenceSerial || ''}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="SeriesString"
                        label="Serie"
                        value={props.nfObj.SeriesString || ''}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id="Status"
                        label="Status"
                        value={status || ''}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant={"contained"}
                        fullWidth
                        disabled={!props.nfObj.SequenceSerial}
                        startIcon={<Refresh />}
                        onClick={querySefaz}
                    >
                        Consulta Sefaz
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="Motivo"
                        label="Motivo"
                        value={motivo || ''}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="ChaveAcesso"
                        label="Chave de Acesso"
                        value={chaveAcesso || ''}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant={"contained"}
                        disabled={autorizada}
                        startIcon={<Code />}
                        onClick={queryXML}
                    >
                        Download XML
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant={"contained"}
                        disabled={autorizada}
                        startIcon={<Note />}
                        onClick={queryDanfe}
                    >
                        Download Danfe
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant={"contained"}
                        startIcon={<Note />}
                        onClick={() => { setCancelDialogOpen(true) }}
                    >
                        Cancelar NFe
                    </Button>
                </Grid>
            </Grid>

            <NFeCancelDialog
                cancelDialogOpen={cancelDialogOpen}
                setCancelDialogOpen={setCancelDialogOpen}
                action={cancelNFe}
                progressBar={progressBar}
                setProgressBar={setProgressBar}
                cancelMotive={cancelMotive}
                setCancelMotive={setCancelMotive}
            />

        </>
    )
}

export default NfeData;