import api from '../../services/api.js';

export const findByCode = async (id) => {

    const options = {
        method: 'GET',
        url: `/Items/${id}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data;
    }).catch(function (error) {

        console.error(error);
    });
};

export const searchByDescription = async (description) => {

    const options = {
        method: 'GET',
        url: `/Items/search/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};

export const searchByPartDescription = async (description) => {

    const options = {
        method: 'GET',
        url: `/Items/searchpart/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};

