import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { findOpenSalesOrders, findOneSalesOrder } from './Controller';
import Classification from './Classification';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { findCultureByItemCode, findBatchNumbersFromItem } from '../../OutgoingMailing/Components/OutgoingMailingController';
import { findItemByCode } from './Controller';
import BusinessPartnerAutocomplete from '../../../components/BusinessPartner/Autocomplete';

const GeneralData = (props) => {

    const [salesOrders, setSalesOrders] = useState([]);
    const [batchList, setBatchList] = useState([]); 

    useEffect(() => {
        async function fetchData() {
            const so = await findOpenSalesOrders();
            setSalesOrders(so);
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (props.vwObj.Vw_Item) {
                const itm = props.vwObj.Vw_Item.split(' - ')[0];

                const itemResult = await findItemByCode(itm);

                if (itemResult?.ManageBatchNumbers === 'tYES') {

                    const btc = await findBatchNumbersFromItem(itm);                  
                    setBatchList(btc);
                }
            }
        }

        fetchData();
    }, [props.vwObj.Vw_Item]);

    const findSalesOrderData = async (DocEntry) => {
        const so = await findOneSalesOrder(DocEntry);

        if (so) {
            props.updateVwObject('Vw_Filial', so.BPLName);
            props.updateVwObject('Vw_Deposito', so.DocumentLines[0].WarehouseCode);
            props.updateVwObject('Vw_Cliente', so.CardCode + ' - ' + so.CardName);
            props.updateVwObject('Vw_Cnpj', so.FederalTaxID);
            props.updateVwObject('Vw_Item', so.DocumentLines[0].ItemCode + ' - ' + so.DocumentLines[0].ItemDescription);
            props.updateVwObject('Vw_QuantidadePendente', so.DocumentLines[0].RemainingOpenQuantity);
            
            const classific = await findCultureByItemCode(so.DocumentLines[0].ItemCode);
            
            if (classific.length > 0) {
                props.setClassification(classific[0].CULT_ANALISESCollection)
            }
        }
    }

    const calculaPesoLiquido = () => {
        if (props.obj.U_PesoTara && props.obj.U_PesoBruto) {
            if (props.obj.U_PesoTara < props.obj.U_PesoBruto) {
                const value = props.obj.U_PesoBruto - props.obj.U_PesoTara;
                props.updateObject('U_PesoLiquido', value)
            } else {
                props.updateObject('U_PesoLiquido', '')
            }
        }
    }

    const status = () => {
        if (props.obj.Canceled === 'Y') {
            return 'Cancelado'
        }
        if (props.obj.Status === 'C') {
            return 'Encerrado'
        }
        if (props.obj.Status === 'O') {
            return 'Aberto'
        }
        return ''
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        id="DocEntry"
                        label="Código"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.obj.DocEntry}
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="U_Data"
                        label="Data"
                        type="date"
                        value={props.obj.U_Data}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(event) => {
                            props.updateObject('U_Data', event.target.value)
                        }}
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="U_Hora"
                        label="Hora"
                        type="time"
                        value={props.obj.U_Hora}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(event) => {
                            props.updateObject('U_Hora', event.target.value)
                        }}
                        InputLabelProps={{ shrink: true }}
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="Status"
                        label="Status"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={status()}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="U_PedidoVenda">Pedido de Venda</InputLabel>
                        <Select
                            fullWidth
                            disabled={props.obj.DocEntry}
                            labelId="U_PedidoVenda"
                            id="U_PedidoVenda"
                            label="Pedido de Venda"
                            defaultValue=""                            
                            value={props.obj.U_PedidoVenda}
                            onChange={(event) => {
                                props.updateObject('U_PedidoVenda', event.target.value);
                                findSalesOrderData(event.target.value);
                            }}
                        >
                            {salesOrders?.map(item => {

                                return (
                                    <MenuItem value={item.DocEntry} key={uuid()}>{`${item.DocNum} - ${item.CardCode} - ${item.CardName} - ${item.TaxExtension.TaxId0} ${ item.U_Contrato ? ('- Contrato: '+ item.U_Contrato) : ''  }`}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id="Vw_Filial"
                        label="Filial"
                        value={props.vwObj.Vw_Filial}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="Vw_Deposito"
                        label="Depósito"
                        value={props.vwObj.Vw_Deposito}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id="Vw_Cliente"
                        label="Cliente"
                        value={props.vwObj.Vw_Cliente}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="Vw_Cnpj"
                        label="CNPJ"
                        value={props.vwObj.Vw_Cnpj}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id="Vw_Item"
                        label="Item"
                        value={props.vwObj.Vw_Item}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <CurrencyTextField
                        id="Vw_QuantidadePendente"
                        label="Qtd. Pendente"
                        value={props.vwObj.Vw_QuantidadePendente}
                        variant="outlined"
                        size="small"
                        fullWidth
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="U_Motorista"
                        label="Motorista"
                        value={props.obj.U_Motorista}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(event) => {
                            props.updateObject('U_Motorista', event.target.value);
                        }}
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="U_Placa"
                        label="Placa"
                        value={props.obj.U_Placa}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(event) => {
                            props.updateObject('U_Placa', event.target.value);
                        }}
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                        <InputLabel id="estadoVeiculo" >UF</InputLabel>
                        <Select
                            labelId="estadoVeiculo"
                            id="estadoVeiculo"
                            label="UF"
                            defaultValue=""
                            value={props.obj.U_UFPlaca || ''}
                            defaultValue=""
                            onChange={event => {
                                props.updateObject('U_UFPlaca', event.target.value);
                            }}
                            disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        >
                            <MenuItem value={'AC'}>Acre</MenuItem>
                            <MenuItem value={'AL'}>Alagoas</MenuItem>
                            <MenuItem value={'AP'}>Amapá</MenuItem>
                            <MenuItem value={'AM'}>Amazonas</MenuItem>
                            <MenuItem value={'BA'}>Bahia</MenuItem>
                            <MenuItem value={'CE'}>Ceará</MenuItem>
                            <MenuItem value={'DF'}>Distrito Federal</MenuItem>
                            <MenuItem value={'ES'}>Espírito Santo</MenuItem>
                            <MenuItem value={'GO'}>Goiás</MenuItem>
                            <MenuItem value={'MA'}>Maranhão</MenuItem>
                            <MenuItem value={'MT'}>Mato Grosso</MenuItem>
                            <MenuItem value={'MS'}>Mato Grosso do Sul</MenuItem>
                            <MenuItem value={'MG'}>Minas Gerais</MenuItem>
                            <MenuItem value={'PA'}>Pará</MenuItem>
                            <MenuItem value={'PB'}>Paraíba</MenuItem>
                            <MenuItem value={'PR'}>Paraná</MenuItem>
                            <MenuItem value={'PE'}>Pernambuco</MenuItem>
                            <MenuItem value={'PI'}>Piauí</MenuItem>
                            <MenuItem value={'RJ'}>Rio de Janeiro</MenuItem>
                            <MenuItem value={'RN'}>Rio Grande do Norte</MenuItem>
                            <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
                            <MenuItem value={'RO'}>Rondônia</MenuItem>
                            <MenuItem value={'RR'}>Roraima</MenuItem>
                            <MenuItem value={'SC'}>Santa Catarina</MenuItem>
                            <MenuItem value={'SP'}>São Paulo</MenuItem>
                            <MenuItem value={'SE'}>Sergipe</MenuItem>
                            <MenuItem value={'TO'}>Tocantins</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {
                        props.selectedCarrier &&
                        <TextField                            
                            id="Vw_Transportadora"
                            label="Transportadora"
                            value={props.selectedCarrier}
                            variant="outlined"
                            size="small"
                            fullWidth
                            onClick={()=> {                                
                                props.setSelectedCarrier('')
                            }}
                            disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        />
                    }

                    {
                        !props.selectedCarrier &&
                        <BusinessPartnerAutocomplete
                            addItemField={props.obj.U_Transportadora}
                            setInputItem={(value) => {
                                props.updateObject('U_Transportadora', value)
                            }}
                            width={'100%'}
                            label="Transportadora"
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="U_Obs"
                        label="Observações"
                        multiline
                        rows={2}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.obj.U_Obs}
                        onChange={event => {
                            props.updateObject('U_Obs', event.target.value)
                        }}
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default GeneralData;