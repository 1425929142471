const status = (description, cancelled) => {

    if(description === 'bost_Close' && cancelled === 'tNO') {
        return 'Encerrado';
    } else if (description === 'bost_Close') {
        return 'Cancelado'
    } else {
        return 'Aberto'
    }
}

export default status;