import api from './api';

export const findAttachments = async (AttachmentEntry) => {

    const options = {
        method: 'GET',
        url: `/Attachments2/${AttachmentEntry}`,
        headers: {'Content-Type': 'application/json'}
      };
      
      return api.request(options).then(function (response) {

        return response.data;
      }).catch(function (error) {
        
        return error;       
      });
};

export const createAttachment = async (formData) => {

    const options = {
      method: 'POST',
      url: '/Attachments2',
      headers: {'Content-Type':`multipart/form-data; boundary=${formData._boundary}`},
      data: formData
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      return error;        
    });
};

export const updateAttachment = async (formData, AttachmentEntry) => {

    const options = {
      method: 'POST',
      url: `/Attachments2/${AttachmentEntry}`,
      headers: {'Content-Type':`multipart/form-data; boundary=${formData._boundary}`},
      data: formData
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      return error;        
    });
};