import React, { useState, useEffect } from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import { findByBranchIdWithThird } from '../../../components/Deposits/DepositsController';
import { findBrachInfo } from './OutgoingMailingController';

const SenderData = (props) => {

    const classes = CreateAndViewStyle();

    const [branchs, setBranchs] = useState([]);
    const [deposits, setDeposits] = useState([]);

    useEffect(() => {
        const brs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));
        setBranchs(brs);
    }, []);


    useEffect(() => {
        async function fetchData() {

            if (props.object.SenderData.selectedBranch) {
                const deps = await findByBranchIdWithThird(props.object.SenderData.selectedBranch);
                setDeposits(deps);

                const dropship = deps.find(x => x.DropShip === 'tYES');

                const brc = await findBrachInfo(props.object.SenderData.selectedBranch);
                props.setObject({
                    ...props.object,
                    SenderData: {
                        ...props.object.SenderData,
                        branchInfo: brc,
                        dropshipWarehouse: dropship
                    }
                })
            }
        };

        fetchData();
    }, [props.object.SenderData.selectedBranch]);

    useEffect(() => {
        async function fetchData() {

            if (props.object.SenderData.selectedDeposit) {
                               
                const depObj = deposits.find(x => x.WarehouseCode === props.object.SenderData.selectedDeposit);

                if(depObj) {
                    props.setObject({
                        ...props.object,
                        Location: depObj.Location
                    })
                }
            }
        };

        fetchData();
    }, [props.object.SenderData.selectedDeposit]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                        <Select
                            labelId="filial"
                            id="filial"
                            label="Filial"
                            defaultValue = ""
                            value={props.object.SenderData.selectedBranch || ''}
                            onChange={event => {
                                props.setObject({
                                    ...props.object,
                                    SenderData: {
                                        ...props.object.SenderData,
                                        selectedBranch: event.target.value
                                    }
                                })
                            }}
                        >
                            {branchs.map(branch => {
                                return (
                                    <MenuItem value={branch.BPLID}>{branch.BPLName}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Depósito</InputLabel>
                        <Select
                            labelId="deposito"
                            id="deposito"
                            label="Depósito"
                            value={props.object.SenderData.selectedDeposit || ''}
                            defaultValue = ""
                            onChange={event => {
                                props.setObject({
                                    ...props.object,
                                    SenderData: {
                                        ...props.object.SenderData,
                                        selectedDeposit: event.target.value
                                    }
                                })
                            }}
                        >
                            {deposits.map(dep => {
                                return (
                                    <MenuItem value={dep.WarehouseCode}>{dep.WarehouseName}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>                
                <Grid item xs={8}>
                    <TextField
                        id="razao"
                        label="Razão Social"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.BPLName
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="fantasia"
                        label="Nome Fantasia"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.AliasName
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="tipo"
                        label="Tipo"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.AddressType
                            : ''
                        }

                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="logradouro"
                        label="Logradouro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.Street
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="numero"
                        label="Número"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.StreetNo
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="bairro"
                        label="Bairro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.Block
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="cidade"
                        label="Cidade"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.City
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="cep"
                        label="CEP"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.ZipCode
                            : ''
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default SenderData;