import React, { useState, useEffect } from 'react';
import {
    Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Typography, Button, TablePagination, TextField, Grid
} from '@material-ui/core';
import { findAll } from './InventoryGenExitsController';
import dateFormat from '../../services/dateFormat';
import { Link } from 'react-router-dom';
import status from '../../services/documentStatus';
import { v4 as uuid } from 'uuid';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';

const useStyles = ListDocumentsStyle;

const GoodsIssue = () => {

    const [inventoryGenExits, setInventoryGenExits] = useState([]);
    const [paramConsulta, setParamConsulta] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const result = await findAll();
            setInventoryGenExits(result);
        };

        fetchData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    };

    const handleList = async => {
        if (paramConsulta === '') {
            return inventoryGenExits
        }
        const filteredList = inventoryGenExits.filter((p) => {
            return p.DocNum.toString().toLowerCase().includes(paramConsulta.toLowerCase());
        })

        if (filteredList) {
            return filteredList
        }
        return []
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                    Saída de Mercadoria
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>
                <TableContainer component={Paper}>
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <TextField
                                        label="Pesquisar"
                                        type="text"
                                        variant="standard"
                                        size="small"
                                        inputProps={{ maxLength: 20 }}
                                        fullWidth
                                        value={paramConsulta}
                                        onChange={(event) => {
                                            setParamConsulta(event.target.value)
                                        }}

                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tablehead}>
                                <TableCell>Nº</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Filial</TableCell>
                                <TableCell>Observações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {handleList().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                                return (
                                    <TableRow component={Link} to={`/home/inventoryGenExits/view/${item.DocNum}`} className={classes.tablerow} hover key={uuid()}>
                                        <TableCell>{item.DocNum}</TableCell>
                                        <TableCell>{dateFormat(item.DocDate)}</TableCell>
                                        <TableCell>{status(item.DocumentStatus, item.Cancelled)}</TableCell>
                                        <TableCell>{item.BPLName}</TableCell>
                                        <TableCell>{item.Comments}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container spacing={2} justify="space-between">
                    <Grid item>                        
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/inventoryGenExits/create'}>
                            Nova Saída de Mercadorias
                        </Button>
                    </Grid>
                    <Grid item>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={inventoryGenExits.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage='Registros por página'
                            backIconButtonText='Página anterior'
                            nextIconButtonText='Próxia página'
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />                        
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default GoodsIssue;