import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { equipments } from '../Items/ItemsController';

const EquipmentsAutoComplete = (props) => {

    const [listItems, setListItems] = useState([]);
    const [addInputValue, setAddInputValue] = useState('');

    const handleChangeFilterOption = (event, newInputValue) => {

        setAddInputValue(newInputValue.toUpperCase());

        if (newInputValue.length >= 2) {
            equipments(props.location, newInputValue.toUpperCase())
                .then((result => {
                    if (result) {
                        setListItems(result)
                    }
                }))
        } else {
            setListItems([])
        }
    }

    return (
        <>
            <Autocomplete
                fullWidth
                value={props.addEquipmentField}
                getOptionSelected={(option, value) => {
                    return option.ItemCode === value.ItemCode;
                }}
                getOptionLabel={(option) => option.ItemCode ? option.InventoryNumber + ' - ' + option.ItemCode + ' - ' + option.ItemName : ''}
                onChange={(event, value) => { props.setEquipment(value) }}
                inputValue={addInputValue}
                onInputChange={handleChangeFilterOption}
                options={listItems}
                size="small"
                renderInput={(params) => <TextField {...params} label="Equipamento" variant="outlined" />}
            />
        </>
    );
}

export default EquipmentsAutoComplete;