import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import {
    Grid, Paper, TextField, Typography, LinearProgress,
    Tabs, Tab, AppBar, Button, Collapse
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { findByReference2, createInventoryGenEntrie, update } from '../../pages/InventoryGenExits/InventoryGenExitsController';
import { findOneEmployeesInfo, findOneSafra, findOneCultura, findOneProfitCenter } from '../DefaultController';
import Alert from '@material-ui/lab/Alert';

const FuelAndLubrificationView = ({ history }) => {

    let { id } = useParams();

    const [tabs, setTabs] = useState(1);
    const [register, setRegister] = useState({});
    const [operator, setOperator] = useState('');
    const [safra, setSafra] = useState('');
    const [cultura, setCultura] = useState('');
    const [profitCenter, setProfitCenter] = useState('');
    const [progressBar, setProgressBar] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: [] });

    const classes = CreateAndViewStyle();


    useEffect(() => {
        async function fetchData() {
            const reg = await findByReference2(id)
            let obj = null;
            if (reg[0]) {
                obj = reg[0];
                setRegister(reg[0])
            }

            if (obj.U_Operador) {
                const op = await findOneEmployeesInfo(obj.U_Operador)
                setOperator(op)
            }

            if (obj.U_Safra) {
                const saf = await findOneSafra(obj.U_Safra)
                setSafra(saf)
            }

            if (obj.U_Cultura) {
                const cul = await findOneCultura(obj.U_Cultura)
                setCultura(cul)
            }

            if (obj.DocumentLines[0].CostingCode) {
                const cc = await findOneProfitCenter(obj.DocumentLines[0].CostingCode)
                setProfitCenter(cc)
            }

        };
        fetchData();
    }, [id]);

    const handleCancel = async () => {

        setProgressBar(false);

        const line = register.DocumentLines[0];

        const obj = {
            DocDate: register.DocDate,
            JournalMemo: `Estorno B2Agri - Abastecimento/Lubrificação (${register.Reference2})`,
            Reference2: register.Reference2,
            DocumentLines: [{
                ItemCode: line.ItemCode,
                WarehouseCode: line.WarehouseCode,
                Quantity: line.Quantity,
                UnitPrice: line.Price,
                AccountCode: line.AccountCode
            }],
            BPL_IDAssignedToInvoice: register.BPL_IDAssignedToInvoice
        }

        const result = await createInventoryGenEntrie(obj);

        await update(register.DocEntry, {
            JournalMemo: `CANCELADO - ${register.JournalMemo}`
        })

        if (result?.error) {

            setError({ isError: true, errorMessage: [result.error.message.value] });

        }

        if (!error.isError) {
            setProgressBar(true);
            alert('CANCELAMENTO REALIZADO COM SUCESSO!')
            history.push('/home/fuelAndLubrification/list');
        }
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Abastecimentos / Lubrificações - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="code"
                                label="Código"
                                value={id}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="date"
                                label="Data da Saída"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={register?.DocDate}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="U_Equipamento"
                                label="Equipamento"
                                value={register?.U_Equipamento}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="U_EquipMedidor"
                                label="Medidor"
                                value={register?.U_EquipMedidor}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="U_Operador"
                                label="Operador"
                                value={operator ? `${operator.FirstName} ${operator.LastName}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="Safra"
                                label="Safra"
                                value={safra ? `${safra.Code} - ${safra.Name}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="Culturs"
                                label="Cultura"
                                value={cultura ? `${cultura.Code} - ${cultura.Name}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="Tipo"
                                label="Tipo"
                                value={register.DocumentLines ? (register.DocumentLines[0].ItemsGroupCode === 116 ? 'LUBRIFICAÇÃO' : 'ABASTECIMENTO') : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                id="Item"
                                label="Item"
                                value={register.DocumentLines ? `${register.DocumentLines[0].ItemCode} - ${register.DocumentLines[0].ItemDescription}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="UM"
                                label="U.M."
                                value={register.DocumentLines ? register.DocumentLines[0].MeasureUnit : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="Quantity"
                                label="Quantidade"
                                value={register.DocumentLines ? register?.DocumentLines[0].Quantity : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="CentroCusto"
                                label="Centro de Custo"
                                value={profitCenter ? `${profitCenter.CenterCode} - ${profitCenter.CenterName}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                                value={`${register.Comments} - ${register.JournalMemo}`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button color="primary" className={classes.button} variant="outlined" onClick={handleCancel}>
                                Cancelar Apontamento
                            </Button>
                            <Button variant="contained" color="primary" className={classes.button} component={Link} to={'/home'}>
                                Voltar
                            </Button>
                        </Grid>

                    </Grid>
                </TabPanel>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    )
}

export default FuelAndLubrificationView;