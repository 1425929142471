import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
    ShoppingCart as ShoppingCartIcon,
    EventAvailable as EventAvailableIcon,
    LocalShipping as LocalShippingIcon,
    Build as BuildIcon
} from '@material-ui/icons';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    cardLink: {
        textDecoration: 'none'
    },
    card: {
        border: 'none',
        width: '90%',
        minHeight: 200
    },
    cardSeeLink: {
        color: '#EDAC01'
    }
});

const Home = () => {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={3} justify="flex-start" >
                {/*<Grid item xs={3}>
                    <Link to="/home/PurchaseRequest/create" className={classes.cardLink}>
                        <Card className={classes.card} >
                            <CardContent>
                                <ShoppingCartIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Solicitação de Compras
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={3}>
                    <Link to="/home/purchaseDeliveryNotes/openPurchaseOrders" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <ShoppingCartIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Recebimento de Mercadorias
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
            </Grid>

            <Grid container spacing={3} justify="flex-start" >
                <Grid item xs={3}>
                    <Link to="/home/agriculturalOperations/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <EventAvailableIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Operações Agrícolas
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={3}>
                    <Link to="/home/fuelAndLubrification/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <EventAvailableIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Abastecimento e Lubrificação
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
    </Grid> */}
                <Grid item xs={3}>
                    <Link to="/home/seedTreatment/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <EventAvailableIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Tratamento de Sementes
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                {/*<Grid item xs={3}>
                    <Link to="/home/weatherRecord/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <EventAvailableIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Registro Climático
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="flex-start" >
                <Grid item xs={3}>
                    <Link to="/home/outgoingMailing/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <LocalShippingIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Romaneio de Remessa para Armazém de Terceiros
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={3}>
                    <Link to="/home/ownShipment/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <LocalShippingIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Romaneio de Remessa para Armazém Próprio
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={3}>
                    <Link to="/home/warehouseExit/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <BuildIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Saída Almoxarifado
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <Link to="/home/outboundListing/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <LocalShippingIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Romaneio de Saída Armazém
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={3}>
                    <Link to="/home/plantationShipment/create" className={classes.cardLink}>
                        <Card className={classes.card}>
                            <CardContent>
                                <LocalShippingIcon color="disabled" />
                                <Typography variant="subtitle1">
                                    Romaneio de Saída Lavoura
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.cardSeeLink} size="small">Ver</Button>
                            </CardActions>
                        </Card>
                    </Link>
</Grid>*/}
            </Grid>
        </>
    );
}

export default Home;