import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';


const RecipientData = (props) => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        id="destinatario"
                        label="Destinatário"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.CardName}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="deposito"
                        label="Depósito Destino"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.ToWarehouse}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="cliente"
                        label="Cód. Cliente"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.CardCode}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="documento"
                        label="CPF/CNPJ"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.FederalTaxID}
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id="endereco"
                        label="Endereço"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.Address}
                    />
                </Grid>                
            </Grid>
        </>
    )
}

export default RecipientData;