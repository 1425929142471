import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { create } from './PurchaseRequestController';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import {
    Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Typography, LinearProgress, Collapse,
    FormControl, InputLabel, Select, MenuItem, Tabs, Box, AppBar, Tab, IconButton, FormControlLabel, Switch
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import getTodayDate from '../../services/getTodayDate';
import { findByBranchId } from '../../components/Deposits/DepositsController';
import ItemsAutocomplete from '../../components/Items/ItemsAutocomplete';
import { EmployeesInfo } from '../DefaultController';
import FileUpload from '../../components/FileUpload/FileUpload';
import { createAttachment } from '../../services/AttachmentController';
import EmployeeAutocomplete from '../../components/EmployeesInfo/EmployeesInfoAutoComplete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import BusinessPartnerAutocomplete from '../../components/BusinessPartner/Autocomplete';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

const PurchaseRequest = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [requiredDate, setRequiredDate] = useState(getTodayDate());
    const [docDueDate, setDocDueDate] = useState(getTodayDate());
    const [comments, setComments] = useState('');
    const [branchs, setBranchs] = useState([]);
    const [deposits, setDeposits] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [progressBar, setProgressBar] = useState(true);
    const [items, setItems] = useState([{ ItemCode: '', ItemName: '', Quantity: 0, WarehouseCode: '', UoMGroupEntry: 0, UoMEntry: 0, FreeText: '', ListaUnidades: [] }]);
    const [operador, setOperador] = useState('');
    const classes = CreateAndViewStyle();
    const [operadorList, setOperadorList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [fileList, setFileList] = useState([]);
    const [numeroFiscalEletronico, setNumeroFiscalEletronico] = useState('');
    const [businessPartner, setBusinessPartner] = useState('');
    const [urgentPurchase, setUrgentPurchase] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));
        setBranchs(branchs);
    }, []);

    const handleNewItem = () => {

        setItems([...items, { ItemCode: '', ItemName: '', Quantity: 0, WarehouseCode: '', UoMGroupEntry: 0, UoMEntry: 0, FreeText: '', ListaUnidades: [] }]);
    };

    const handleDeleteFile = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...fileList];
        newItems.splice(index, 1);
        setFileList(newItems);
    }

    const handleDeleteItem = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    const handleFileAdd = (file) => {

        if (file) {
            const tempFiles = [...fileList, file];
            setFileList(tempFiles);
        }
    }

    const sendAttachments = async () => {

        if (fileList.length > 0) {

            let formData = new FormData();

            await fileList.forEach((file, index) => {
                formData.append(`file_${index}`, file, file.name);
            });

            return await createAttachment(formData)
                .then((result) => {
                    return result;
                })
                .catch((error) => {
                    console.log('Caiu no error')
                    return error;
                });
        }
    }

    const handleSubmit = async () => {

        setProgressBar(false);
        setSubmitDisabled(true);

        const errorList = hasErrors();

        if (errorList.length === 0) {

            if (urgentPurchase) {
                let tmpItens = items;

                for (let i = 0; i < tmpItens.length; i++) {
                    tmpItens[i].LineVendor = businessPartner ? businessPartner.CardCode : ''
                }

                setItems(tmpItens)
            }

            const attachmentResult = await sendAttachments();

            let comm = '';
            if(urgentPurchase && businessPartner && numeroFiscalEletronico) {
                comm = `${businessPartner.CardCode} - ${businessPartner.CardName} - NF ${numeroFiscalEletronico} - ${comments}`
            } else {
                comm = comments
            }

            const finalPurchaseRequest = {
                DocDate: documentDate,
                DocDueDate: docDueDate,
                RequriedDate: requiredDate,
                Comments: comm,
                ReqType: 171,
                Requester: operador.EmployeeID,
                DocumentsOwner: localStorage.getItem('@SAP:EMPLOYEEID'),
                DocumentLines: items,
                BPL_IDAssignedToInvoice: selectedBranch,
                U_CompraEfetuada: urgentPurchase ? 'Y' : 'N',
                U_TX_NDfe: numeroFiscalEletronico ? numeroFiscalEletronico : '',
                AttachmentEntry: attachmentResult ? attachmentResult.AbsoluteEntry : null,
            }

            const result = await create(finalPurchaseRequest);
            console.log(result)
            setProgressBar(true);

            if (result.data.error.message.value) {
                let errorList = [];
                errorList.push(result.data.error.message.value)
                setError({ isError: true, errorMessage: errorList })
                setSubmitDisabled(false);

            } else {
                alert('SOLICITAÇÃO REALIZADA COM SUCESSO!');
                history.push('/home/purchaseRequest');                
            }

        } else {
            setError({ isError: true, errorMessage: errorList });
            setProgressBar(true);
            setSubmitDisabled(false);
        }

    }

    const handleBranchSelection = async (event) => {
        setSelectedBranch(event.target.value);
        const branchDeposits = await findByBranchId(event.target.value)
        setDeposits(branchDeposits);

        //Operador
        const globalLocationNumber = branchs.find((b) => {
            return b.BPLID === event.target.value
        }).GlobalLocationNumber
        setSelectedLocation('')
        if (globalLocationNumber) {
            const opLst = await EmployeesInfo(globalLocationNumber)
            setOperadorList(opLst)
            setSelectedLocation(globalLocationNumber)
        } else {
            setOperadorList([])
        }
    }

    const setInputItem = (item, index) => {
        if (item) {
            let tempItems = [...items];
            tempItems[index] = item;
            setItems([...tempItems])
        }
    }

    const hasErrors = () => {

        let errorList = [];

        if (!documentDate || !docDueDate || !requiredDate)
            errorList.push('Datas - Selecione datas válidas para a solicitação.');

        if (!selectedBranch)
            errorList.push('Filial - Selecione uma filial válida.');

        if (items.length <= 0 || !items[0].ItemCode)
            errorList.push('Itens - Selecione ao menos um item para a solicitação.');

        if (urgentPurchase && !businessPartner)
            errorList.push('Necessário informar fornecedor para compra já efetuada');

        if (urgentPurchase && !numeroFiscalEletronico)
            errorList.push('Necessário informar numero da NF para compra já efetuada');

        items.forEach((item, index) => {

            if (item.Quantity <= 0)
                errorList.push(`Linha ${index} - Quantidade deve ser maior que zero.`);

            if (!item.WarehouseCode)
                errorList.push(`Linha ${index} - Selecione o depósito.`);
        });

        return errorList;

    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Solicitação de Compra - Adicionar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                    <Tab label="Anexos" value={2} />
                </Tabs>
            </AppBar>

            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                id="date"
                                label="Data da Solicitação"
                                type="date"
                                fullWidth
                                defaultValue={getTodayDate()}
                                value={documentDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setDocumentDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="requireddate"
                                label="Data Necessária"
                                type="date"
                                fullWidth
                                defaultValue={getTodayDate()}
                                value={requiredDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setRequiredDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="date"
                                label="Valido Até"
                                type="date"
                                fullWidth
                                defaultValue={getTodayDate()}
                                value={docDueDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setDocDueDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                                <Select
                                    labelId="filial"
                                    id="filial"
                                    label="Filial"
                                    value={selectedBranch}
                                    onChange={handleBranchSelection}
                                >
                                    {branchs.map(branch => {
                                        return (
                                            <MenuItem value={branch.BPLID}>{branch.BPLName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <EmployeeAutocomplete
                                label="Solicitante"
                                addItemField={operador}
                                setInputItem={(item) => {
                                    setOperador(item)
                                }}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid item xs={4} sm={16}>
                            <Button
                                variant="contained"
                                onClick={handleNewItem}
                                size='small'
                                color='primary'
                                style={{ marginRight: '20px' }}
                            >
                                Novo item
                            </Button>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={urgentPurchase}
                                        onChange={(event) => {
                                            setUrgentPurchase(event.target.checked)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Compra já efetuada"
                            />
                        </Grid>
                        {
                            urgentPurchase &&

                            <Grid item xs={3}>
                                <TextField
                                    id="numNf"
                                    label="NF"
                                    fullWidth
                                    value={numeroFiscalEletronico}
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => {
                                        setNumeroFiscalEletronico(event.target.value)
                                    }}
                                />
                            </Grid>
                        }
                        {
                            urgentPurchase &&
                            <Grid item xs={5}>
                                <BusinessPartnerAutocomplete
                                    addItemField={businessPartner}
                                    setInputItem={(value) => {
                                        setBusinessPartner(value)
                                    }}
                                    width={'100%'}
                                    label="Fornecedor"
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Item</TableCell>
                                            <TableCell>Descrição comp.</TableCell>
                                            <TableCell>Quantidade</TableCell>
                                            <TableCell>Un. Med</TableCell>
                                            <TableCell>Depósito</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <ItemsAutocomplete
                                                            idx={index}
                                                            addItemField={items[index]}
                                                            setInputItem={setInputItem}
                                                            width={500}
                                                            purchaseRequest
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <TextField
                                                            style={{ width: 200 }}
                                                            variant="outlined"
                                                            size={"small"}
                                                            value={items[index].FreeText}
                                                            onChange={(event) => {
                                                                let tempItems = [...items];
                                                                tempItems[index].FreeText = event.target.value;
                                                                setItems([...tempItems]);
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <CurrencyTextField
                                                            style={{ width: 120 }}
                                                            currencySymbol=""
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            decimalPlaces={4}
                                                            minimumValue="0"
                                                            variant="outlined"
                                                            size={"small"}
                                                            value={items[index].Quantity}
                                                            onChange={(event, value) => {
                                                                let tempItems = [...items];
                                                                tempItems[index].Quantity = value;
                                                                setItems([...tempItems]);
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <FormControl variant="outlined" className={classes.formControl} size="small">
                                                            <Select
                                                                fullWidth
                                                                id="und"
                                                                value={items[index].UoMEntry}
                                                                onChange={(event) => {
                                                                    let tempItems = [...items];
                                                                    tempItems[index].UoMEntry = event.target.value;
                                                                    setItems([...tempItems]);
                                                                }}
                                                            >
                                                                {items[index].ListaUnidades.map(unidade => {
                                                                    return (
                                                                        <MenuItem value={unidade.AbsEntry}>{unidade.Code}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <FormControl variant="outlined" className={classes.formControl} size="small">
                                                            <Select
                                                                style={{ width: 300 }}
                                                                value={items[index].WarehouseCode}
                                                                onChange={event => {
                                                                    let tempItems = [...items];
                                                                    tempItems[index].WarehouseCode = event.target.value;
                                                                    setItems([...tempItems]);
                                                                }}
                                                            >
                                                                {deposits.map(deposit => {
                                                                    return (
                                                                        <MenuItem value={deposit.WarehouseCode} key={uuid()}>{deposit.WarehouseCode + ' - ' + deposit.WarehouseName}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell className={classes.tablePaddingRemove}>
                                                        <Button
                                                            data-key={index}
                                                            onClick={handleDeleteItem}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>

                </TabPanel>

                <TabPanel value={tabs} index={2}>
                    <FileUpload
                        handleFileAdd={handleFileAdd}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Arquivo</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fileList.map((file, index) => {
                                    return (
                                        <TableRow
                                            key={uuid()}
                                        >
                                            <TableCell>{file ? file.name : ''}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    component="span"
                                                    data-key={index}
                                                    onClick={handleDeleteFile}
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" disabled={submitDisabled} color="primary" className={classes.button} onClick={handleSubmit}>
                            Adicionar
                        </Button>
                        <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home/purchaseRequest'}>
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    );
}

export default PurchaseRequest;