import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import {
    Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
    Tabs, Tab, AppBar, Button, LinearProgress, Collapse, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import { findActiveProfitCentersInFirstDimension2 } from '../../components/ProfitCenters/ProfitCentersController';
import { v4 as uuid } from 'uuid';
import Alert from '@material-ui/lab/Alert';
import TabPanel from '../../components/TabPanel';
import { WarehouseLocations, EmployeesInfo } from '../DefaultController'
import EquipmentsAutoComplete from '../../components/Equipments/EquipmentsAutoComplete'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import BusinessPartnerAutocomplete from '../../components/BusinessPartner/Autocomplete';
import ItemsAutocomplete from './ItemsAutocomplete';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { create, createServiceCallItens } from './ServiceCallsController';

const ServiceOrder = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [progressBar, setProgressBar] = useState(true);
    const [equipament, setEquipament] = useState('');
    const [tecnico, setTecnico] = useState('');
    const [profitCenter, setProfitCenter] = useState('');
    const [medidor, setMedidor] = useState('');
    const [comments, setComments] = useState('');
    const [solution, setSolution] = useState('');
    const [profitCenterList, setProfitCenterList] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const classes = CreateAndViewStyle();
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [tecnicoList, setTecnicoList] = useState([]);
    const [documentInitialDate, setDocumentInitialDate] = useState(getTodayDate());
    const [documentFinalDate, setDocumentFinalDate] = useState(getTodayDate());
    const [initialHour, setInitialHour] = useState('');
    const [finalHour, setFinalHour] = useState('');
    const [tipoChamado, setTipoChamado] = useState(0);
    const [subject, setSubject] = useState('');
    const [businessPartner, setBusinessPartner] = useState('');
    const [items, setItems] = useState([{}]);
    const [status, setStatus] = useState(-3);

    useEffect(() => {
        async function fetchData() {
            const loc = await WarehouseLocations()
            setLocationsList(loc);

        };
        fetchData();
    }, []);

    const handleLocationSelection = async (event) => {
        setSelectedLocation(event.target.value);

        setEquipament('')

        const oProfitCenters = await findActiveProfitCentersInFirstDimension2(event.target.value);
        setProfitCenterList(oProfitCenters);

        const opLst = await EmployeesInfo(event.target.value)
        setTecnicoList(opLst)
    }

    const setInputItem = (item, index) => {
        if (item) {

            let tempItems = [...items];
            tempItems[index].ItemCode = item.ItemCode;
            tempItems[index].ItemName = item.ItemName;
            tempItems[index].InventoryUOM = item.InventoryUOM;
            setItems(tempItems);
        }
    }

    const handleNewItem = () => {

        setItems([...items, { ItemCode: '', ItemName: '', InventoryUOM: '', Quantity: 0 }]);
    };

    const handleDeleteItem = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    const handleSubmit = async () => {

        setProgressBar(false);

        const obj = {
            ServiceBPType: "srvcPurchasing",
            CustomerCode: businessPartner.CardCode,
            CreationDate: documentInitialDate,
            CreationTime: initialHour,
            ClosingDate: documentFinalDate,
            ClosingTime: finalHour,
            ItemCode: equipament.ItemCode,
            U_EquipMedidor: medidor,
            TechnicianCode: tecnico,
            U_CentroDeCusto: profitCenter,
            Subject: subject,
            Description: comments,
            Resolution: solution
        }
        
        const result = await create(obj);

        if (result.data.error) {
            setError({ isError: true, errorMessage: [result.data.error.message.value] });
        } else {
            
            let finalItems = [];
    
            for(let i = 0; i < items.length; i++) {
                finalItems.push({
                    U_ItemCode: items[i].ItemCode,
                    U_ItemName: items[i].ItemName,
                    U_Quantity: items[i].Quantity,
                    U_Requested: 'N'
                })
            }

            const serviceCallItems = {
                U_ServiceCallID: result.data.ServiceCallID,
                OS_ITEM_LINCollection: finalItems
            }

            const resultItem = await createServiceCallItens(serviceCallItems);
            if (resultItem.data.error) {
                setError({ isError: true, errorMessage: [result.data.error.message.value] });
            } else {
                alert('APONTAMENTO REALIZADO COM SUCESSO!');
                history.push('/home');
            }
        }
        setProgressBar(true);
        
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Ordem de Serviço - Definição
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                    <Tab label="Itens e Serviços" value={2} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                id="date"
                                label="Data Inicial"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentInitialDate}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                    setDocumentInitialDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="initialHour"
                                label="Hora Inicial"
                                type="time"
                                value={initialHour}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                    setInitialHour(event.target.value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="date"
                                label="Data Final"
                                type="date"
                                value={documentFinalDate}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                    setDocumentFinalDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="finalHour"
                                label="Hora Final"
                                type="time"
                                value={finalHour}
                                variant="outlined"
                                size="small"
                                style={{ width: "100%" }}
                                onChange={(event) => {
                                    setFinalHour(event.target.value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="model">Tipo do Chamado</InputLabel>
                                <Select
                                    labelId="tipo"
                                    id="tipo"
                                    label="Tipo do Chamado"
                                    value={tipoChamado}
                                    onChange={(event) => {
                                        setTipoChamado(event.target.value)
                                    }}
                                >
                                    <MenuItem value={0}>Interno</MenuItem>
                                    <MenuItem value={1}>Externo</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="model">Status do Chamado</InputLabel>
                                <Select
                                    labelId="status"
                                    id="status"
                                    label="Status do Chamado"
                                    value={status}
                                    onChange={(event) => {
                                        setStatus(event.target.value)
                                    }}
                                >
                                    <MenuItem value={-3}>Aberto</MenuItem>
                                    <MenuItem value={-2}>Pendente</MenuItem>
                                    <MenuItem value={-1}>Fechado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Localização</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="localizacao"
                                    id="localizacao"
                                    label="Local de Produção"
                                    value={selectedLocation}
                                    onChange={handleLocationSelection}
                                >
                                    {locationsList.map(location => {
                                        return (
                                            <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                            <EquipmentsAutoComplete
                                location={selectedLocation}
                                setEquipment={setEquipament}
                                addEquipmentField={equipament}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CurrencyTextField
                                fullWidth
                                id="medidorvalue"
                                decimalPlaces={1}
                                currencySymbol=""
                                variant="outlined"
                                decimalCharacter=","
                                digitGroupSeparator=""
                                label={!equipament ? '' : equipament.U_Medidor === '' ? '' : (equipament.U_Medidor === 'H' ? 'Horímetro' : 'Odômetro')}
                                size="small"
                                value={medidor}
                                minimumValue="0"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event, value) => {
                                    setMedidor(value);
                                }}
                                disabled={!equipament ? true : equipament.U_Medidor === ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BusinessPartnerAutocomplete
                                addItemField={businessPartner}
                                setInputItem={(value) => {
                                    setBusinessPartner(value)
                                }}
                                width={'100%'}
                                label="Parceiro de Negócio"
                                type='cSupplier'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="tecnico">Tecnico</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="tecnico"
                                    id="tecnico"
                                    label="Tecnico"
                                    defaultValue=""
                                    value={tecnico}
                                    onChange={(event) => {
                                        setTecnico(event.target.value)
                                    }}
                                >
                                    {tecnicoList.map(item => {
                                        return (
                                            <MenuItem value={item.EmployeesInfo.EmployeeID} key={uuid()}>{item.EmployeesInfo.EmployeeID + ' - ' + (item.EmployeesInfo.ExternalEmployeeNumber ? item.EmployeesInfo.ExternalEmployeeNumber + ' - ' : '') + item.EmployeesInfo.FirstName + ' ' + item.EmployeesInfo.LastName}</MenuItem>
                                        );
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth size="small" >
                                <InputLabel id="centro-de-custo">Centro de Custo</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="centro-de-custo"
                                    label="Centro de Custo"
                                    value={profitCenter}
                                    onChange={(event) => {
                                        setProfitCenter(event.target.value)
                                    }}
                                >
                                    {profitCenterList.map(profit => {
                                        return (
                                            <MenuItem value={profit.CenterCode} key={uuid()}>{profit.CenterCode + ' - ' + profit.CenterName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Assunto"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                size="small"
                                value={subject}
                                onChange={(event) => {
                                    setSubject(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Resolução"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={solution}
                                onChange={(event) => {
                                    setSolution(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabs} index={2}>


                    <Grid item xs={12} sm={16}>
                        <Button
                            variant="contained"
                            onClick={handleNewItem}
                            size='small'
                            color='primary'
                        >
                            Novo item
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow className={classes.tablehead}>
                                        <TableCell>Item</TableCell>
                                        <TableCell>U.M.</TableCell>
                                        <TableCell>Quantidade</TableCell>                                        
                                        <TableCell>Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <ItemsAutocomplete
                                                        idx={index}
                                                        addItemField={items[index]}
                                                        setInputItem={setInputItem}
                                                        width={500}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        variant="outlined"
                                                        label="U.M."
                                                        size="small"
                                                        value={items[index].InventoryUOM}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        label="Quantidade"
                                                        value={items[index].Quantity}
                                                        onChange={(event) => {
                                                            let tempItems = [...items];
                                                            tempItems[index].Quantity = event.target.value;
                                                            setItems([...tempItems]);
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </TableCell>                                                
                                                <TableCell>
                                                    <Button
                                                        data-key={index}
                                                        onClick={handleDeleteItem}
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>


                </TabPanel>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                        Adicionar
                    </Button>
                    <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home'}>
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    )
}

export default ServiceOrder;