import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import {
    Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
    Tabs, Tab, AppBar, Button, LinearProgress, Collapse, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { findProductionUnits, findPost, create } from './WeatherRecordController';
import { v4 as uuid } from 'uuid';
import Alert from '@material-ui/lab/Alert';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const WeatherRecord = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [postList, setPostList] = useState([]);
    const [selectedPost, setSelectedPost] = useState('');
    const [elements, setElements] = useState([]);
    const [comments, setComments] = useState('');
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [progressBar, setProgressBar] = useState(true);

    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {
            const loc = await findProductionUnits()
            setLocationsList(loc);

        };
        fetchData();
    }, []);

    const handlePostList = (arr) => {
        setPostList([]);
        let finalList = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].U_PostoMeteorologico)
                finalList.push(arr[i].U_PostoMeteorologico)
        }
        const unique = [...new Set(finalList)];
        setPostList(unique);

    }

    const handleElements = async (post) => {

        const elms = await findPost(post)
        console.log(elms)
        if (elms)
            setElements(elms[0].ELE_CLIMCollection);
    }

    const handleSubmit = async () => {

        if (selectedPost && elements.length > 0) {

            setProgressBar(false);

            let elms = [];
            for (let i = 0; i < elements.length; i++) {
                elms.push(
                    {
                        U_Elemento: elements[i].U_Elemento,
                        U_Valor: elements[i].U_Valor
                    }
                )
            }

            const obj = {

                U_Data: documentDate,
                U_Obs: comments,
                U_PostoID: selectedPost,
                AP_CLIM_LINCollection: elms
            }


            const result = await create(obj);
            if (result.data.error) {
                setError({ isError: true, errorMessage: [result.data.error.message.value] });
            } else {
                setProgressBar(true);
                alert('APONTAMENTO REALIZADO COM SUCESSO!');
                history.push('/home/weatherRecord/list');
            }
        } else {
            setError({ isError: true, errorMessage: ['Preencha os campos'] });
        }

    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Registro Climático
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                id="date"
                                style={{ width: "100%" }}
                                label="Data do Registro"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setDocumentDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="localizacao">Localização</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="localizacao"
                                    id="localizacao"
                                    label="Local de Produção"
                                    value={selectedLocation}
                                    onChange={(event) => {
                                        setElements([])
                                        setSelectedLocation(event.target.value)
                                        handlePostList(event.target.value.TALHOESCollection)
                                    }}
                                >
                                    {locationsList?.map(location => {
                                        return (
                                            <MenuItem value={location}>{`${location.Code} - ${location.Name}`}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="post">Posto</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="post"
                                    label="Posto"
                                    id="post-select"
                                    value={selectedPost}
                                    onChange={(event) => {
                                        setSelectedPost(event.target.value)
                                        handleElements(event.target.value)
                                    }}
                                >
                                    <MenuItem key={uuid()} value={''}>{''}</MenuItem>
                                    {postList?.map(post => {
                                        return (
                                            <MenuItem key={uuid()} value={post}>{post}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Elemento</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>U.M.</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            elements.map((el, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {el.U_Elemento}
                                                        </TableCell>
                                                        <TableCell>
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                size="small"
                                                                currencySymbol=""
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                minimumValue="0"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                value={elements[index].U_Valor}
                                                                onChange={(event, value) => {
                                                                    let temp = [...elements];
                                                                    temp[index].U_Valor = value;
                                                                    setElements(temp);
                                                                }}

                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {el.U_UM}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="comments"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <Grid item xs={12} sm={6}>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Adicionar
                    </Button>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link}
                        to={'/home'}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    );
}

export default WeatherRecord;