import api from '../../services/api.js';

export const findProductionUnits = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

    const options = {
        method: 'POST',
        url: `/WeatherRecord/ProductionUnits`,
        headers: { 'Content-Type': 'application/json' },
        data: branchs
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};

export const findPost = async (post) => {

    const options = {
        method: 'GET',
        url: `/WeatherRecord/PostByUnit/${post}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};

export const create = async (registerObject) => {

    const options = {
        method: 'POST',
        url: '/WeatherRecord/create',
        validateStatus: () => true,
        data: registerObject
    };

    return api.request(options).then(function (response) {

        return response;
    }).catch(function (error) {
        return error;
    });
};

export const findAllWeatherRegisters = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

    const options = {
        method: 'POST',
        url: '/WeatherRecord/list',
        headers: { 'Content-Type': 'application/json' },
        data: branchs
    };

    return api.request(options).then(function (response) {

        return response.data;
    }).catch(function (error) {

        console.error(error);
    });
};

export const findOne = async (Code) => {

    const options = {
        method: 'GET',
        url: `/WeatherRecord/view/${Code}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
            return response.data
    }).catch((error) => {
        console.error(error)
    })
};

export const cancel = async (code) => {

    const options = {
        method: 'POST',
        url: `/WeatherRecord/Cancel/${code}`,
        validateStatus: () => true
    };

    return api.request(options).then(function (response) {

        return response;
    }).catch(function (error) {
        return error;
    });
};