import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findAllOutgoingMailingRegisters } from '../InventoryGenExits/InventoryGenExitsController';
import dateFormat from '../../services/dateFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';

const columns = [
    {
        field: 'id',
        headerName: '#',
        width: 90,
    },
    {
        field: 'Reference2',
        headerName: 'Código',
        width: 140,
    },
    {
        field: 'DocDate',
        headerName: 'Data',
        width: 140,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: 140,
    },
    {
        field: 'U_CCG',
        headerName: 'CCG',
        width: 140,
    },
    {
        field: 'U_ItemCode',
        headerName: 'Item',
        width: 140,
    },
    {
        field: 'U_Variedade',
        headerName: 'Variedade',
        width: 300, 
    },
    {
        field: 'U_Talhao',
        headerName: 'Talhão',
        width: 140,
    },    
    {
        field: 'U_Quantidade',
        headerName: 'Quantidade',
        width: 200,
    },
];

const useStyles = ListDocumentsStyle;

const WarehouseExitsList = ({ history }) => {

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    const status = (StatusValue, CanceledValue) => {
        if (CanceledValue === 'Y') {
            return 'Cancelado'
        }
        if (StatusValue === 'C') {
            return 'Encerrado'
        }
        if (StatusValue === 'O') {
            return 'Aberto'
        }
        return ''
    }

    useEffect(() => {
        async function fetchData() {
            const result = await findAllOutgoingMailingRegisters();
            
            if (result) {
                let final = result.map((item, index) => {
                    return {
                        id: index,
                        Reference2: item.StockTransfers.Reference2,
                        DocDate: dateFormat(item.StockTransfers.DocDate)  ,
                        U_ItemCode: item['RomaneioDetalhes/B2ROMANEIODETALHESCollection'].U_ItemCode,                  
                        U_Variedade: item['RomaneioDetalhes/B2ROMANEIODETALHESCollection'].U_Variedade,                  
                        U_Talhao: item['RomaneioDetalhes/B2ROMANEIODETALHESCollection'].U_Talhao,                  
                        U_Quantidade: item['RomaneioDetalhes/B2ROMANEIODETALHESCollection'].U_Quantidade,                  
                        Status: status(item.RomaneioDetalhes.Status, item.RomaneioDetalhes.Canceled),
                        U_CCG: item.StockTransfers.U_CCG
                    }
                })

                let filtered = final.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.Reference2 === item.Reference2
                    ))
                )

                setRowsData(filtered)
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Romaneios de Remessa para Armazem de Terceiros
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}                    
                    onRowClick={(event) => {                        
                        history.push(`/home/outgoingMailing/view/${event.row.Reference2}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/outgoingMailing/create'}>
                            Novo Romaneio de Saída para Terceiros
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default WarehouseExitsList;