import api from '../../services/api.js';

const getUserCurrentBase = async () => {

    const options = {
        method: 'GET',
        url: `/GetUserCurrentBase`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data;
    }).catch(function (error) {

        console.error(error);
    });
};

export default getUserCurrentBase;