import api from '../../services/api.js';

export const findAll = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

    const options = {
        method: 'POST',
        url: '/PurchaseDeliveryNotes',
        headers: {'Content-Type': 'application/json'},
        data: branchs
      };
      
      return api.request(options).then(function (response) {
        
        return response.data.value;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export const findOne = async (id) => {

  const options = {
      method: 'GET',
      url: `/PurchaseDeliveryNotes/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findOpenPurchaseOrders = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/PurchaseDeliveryNotes/openPurchaseOrders',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findOpenApReserveInvoices = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/PurchaseDeliveryNotes/openApReserveInvoices',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const viewOnePurchaseOrder = async (id) => {

  const options = {
      method: 'GET',
      url: `/PurchaseDeliveryNotes/viewPurchaseOrders/${id}`,
      headers: {'Content-Type': 'application/json'} 
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const viewOneApReserveInvoice = async (id) => {

  const options = {
      method: 'GET',
      url: `/PurchaseDeliveryNotes/viewOneApReserveInvoice/${id}`,
      headers: {'Content-Type': 'application/json'} 
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const create = async (purchaseDeliveryObject) => {

  const options = {
    method: 'POST',
    url: '/PurchaseDeliveryNotes/create',
    validateStatus: () => true,
    data: purchaseDeliveryObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const cancel = async (id) => {

  const options = {
      method: 'GET',
      url: `/PurchaseDeliveryNotes/cancel/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {
      
      return response;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const updateAttachments = async (purchaseDeliveryDocEntry, AttachmentEntry) => {

  const options = {
    method: 'PATCH',
    url: `/PurchaseDeliveryNotes/UpdateAttachments/${purchaseDeliveryDocEntry}`,
    validateStatus: () => true,
    data: {
      AttachmentEntry: AttachmentEntry
    }
  };
  
  return api.request(options).then(function (response) {
    
    return {status: 204};
  }).catch(function (error) {
    return error;
  });
};