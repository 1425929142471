import api from '../../services/api';

export const findAll = async () => {

    const options = {
        method: 'GET',
        url: `/AdditionalExpenses`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};



