const getTodayDate = () => {
    let d = new Date();

    let iso = d.getFullYear().toString() + "-";
    iso += (d.getMonth()+1).toString().padStart(2, '0') + "-";
    iso += d.getDate().toString().padStart(2, '0');

    return iso;
}

export default getTodayDate;