import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import { findOneRomaneioSaida, findOneSalesOrder, createRomaneioSaida, updateRomaneioSaida, createInvoice, closeRomaneioSaida, closeDetalhesRomaneio, findBrachInfo, findBPByCode, findInvoice } from './Components/Controller';
import TabPanel from '../../components/TabPanel';
import { Paper, Grid, Typography, AppBar, Tabs, Tab, Button, LinearProgress, Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import GeneralData from './Components/GeneralData';
import NfeData from './Components/NfeData';
import ItemData from './Components/ItemData';
import getTodayDate from '../../services/getTodayDate';
import getCurrentHour from '../../services/getCurrentHour';
import sanitizeString from '../../services/sanitizeString';
import { Print as PrintIcon } from '@material-ui/icons';
import printPDF from './Components/printPDF';
import { createDetailsRomaneio, findDetalhesByRomaneioSaida, updateDetalhesRomaneio } from './Components/PlantationShipmentController';
import { getWTDataByCombinationId } from '../OutboundListing/Components/Controller';

const PlantationShipment = ({ history }) => {

    let { Code } = useParams();

    const [obj, setObj] = useState({
        U_Data: getTodayDate(),
        U_Hora: getCurrentHour(),
        Status: '',
        DocEntry: '',
        U_PedidoVenda: '',
        U_PesoTara: '',
        U_PesoBruto: '',
        U_PesoNeto: '',
        U_PesoLiquido: '',
        U_DescontoKg: '',
        U_Motorista: '',
        U_Placa: '',
        U_UFPlaca: '',
        U_SequenceSerial: '',
        U_Obs: '',
        U_LoteItem: '',
        U_Transportadora: '',
        U_PesoEmbalagem: '',
        U_NumeroTicket: '',
        U_CCG: '',
        U_Safra: '',
        U_B2Obj: 'B2ROM_LAV',
        U_ItemDescComplementar: 'NENHUMA'

    });

    const [vwObj, setVwObj] = useState({
        Vw_FilialID: '',
        Vw_Filial: '',
        Vw_Deposito: '',
        Vw_Cliente: '',
        Vw_Cnpj: '',
        Vw_Item: '',
        Vw_QuantidadePendente: '',
        Vw_Transportadora: '',
        Vw_Location: ''
    });

    const [classification, setClassification] = useState([]);
    const [variedades, setVariedades] = useState([{ U_Variedade: '', U_Talhao: '', U_Quantidade: '' }])
    const [selectedSalesOrder, setSelectedSalesOrder] = useState({});
    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [selectedBranch, setSelectedBranch] = useState({});
    const [tabs, setTabs] = useState(1);
    const [progressBar, setProgressBar] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);
    const [btnInvoiceDisabled, setBtnInvoiceDisabled] = useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [romaneioDetalhesDocEntry, setRomaneioDetalhesDocEntry] = useState('');
    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {

            if(Code) {
                const romaneio = await findOneRomaneioSaida(Code);
                setObj(romaneio.data)
                setClassification(romaneio.data.B2ROMSAIDAANALISECollection);
            }

        }
        fetchData();
    }, [Code]);

    useEffect(() => {        

            if(obj.U_NFSaida) {
                setBtnInvoiceDisabled(true)
            }
            
    }, [obj.U_NFSaida]);

    useEffect(() => {
        async function fetchData() {

            if(Code) {
                const detalhes = await findDetalhesByRomaneioSaida(Code);   
                if(detalhes) {
                    
                    setRomaneioDetalhesDocEntry(detalhes[0].DocEntry)
                    if(detalhes[0].B2ROMANEIODETALHESCollection.length > 0) {
                        
                        setVariedades(detalhes[0].B2ROMANEIODETALHESCollection);
                    } else {
                        
                        setVariedades([{ U_Variedade: '', U_Talhao: '', U_Quantidade: '' }])
                    }
                }                     
            }

        }
        fetchData();
    }, [Code]);

    useEffect(() => {
        async function fetchData() {

            if(obj.U_PedidoVenda) {
                
                const so = await findOneSalesOrder(obj.U_PedidoVenda);
                if (so) {
                    setSelectedSalesOrder(so);
                    setVwObj({
                        Vw_FilialID: so['BPL_IDAssignedToInvoice'],
                        Vw_Filial: so.BPLName,
                        Vw_Deposito: so.DocumentLines[0].WarehouseCode,
                        Vw_Cliente: so.CardCode + ' - ' + so.CardName,
                        Vw_Cnpj: so.TaxExtension.TaxId0,
                        Vw_Item: so.DocumentLines[0].ItemCode + ' - ' + so.DocumentLines[0].ItemDescription,
                        Vw_QuantidadePendente: so.DocumentLines[0].RemainingOpenQuantity
                    });
    
                    const branch = await findBrachInfo(so['BPL_IDAssignedToInvoice']);
                    
                    setSelectedBranch(branch);
                }
            }
        }
        fetchData();
    }, [obj.U_PedidoVenda]);

    useEffect(() => {
        async function fetchData() {

            if(obj.U_Transportadora) {

                const carrier = await findBPByCode(obj.U_Transportadora);
                setSelectedCarrier(`${carrier.CardCode} - ${carrier.CardName} - ${carrier.FederalTaxID}`);
            }
        }
        fetchData();
    }, [obj.U_Transportadora]);

    useEffect(() => {
        async function fetchData() {

            if(obj.U_NFSaida) {

                const nf = await findInvoice(obj.U_NFSaida);
                if (nf) {
                    setSelectedInvoice(nf);
                }
            }
        }
        fetchData();
    }, [obj.U_NFSaida]);

    const updateObject = (attribute, value) => {
        const tmpObj = obj;
        tmpObj[attribute] = value;
        setObj({ ...tmpObj });
    }

    const updateVwObject = (attribute, value) => {
        const tmpObj = vwObj;
        tmpObj[attribute] = value;
        setVwObj({ ...tmpObj });
    }

    const addRomaneio = async () => {

        
        setProgressBar(false);

        let tempObj = obj;

        let tmpTransportadora = obj.U_Transportadora?.CardCode;
        tempObj.U_Transportadora = tmpTransportadora;
        tempObj.B2ROMSAIDAANALISECollection = classification.map((item) => {
            return {
                U_Code: item.U_Code,
                U_Description: item.U_Description,
                U_Value: item.U_Value,
            }
        });

        const result = await createRomaneioSaida(tempObj);

        if (result.data.error) {
            setError({ isError: true, errorMessage: [result.data.error.message.value] })

        } else {
            const details = await detalhesRomaneio(null, result.data.DocEntry)
            console.log(details)
            if (details.data.error) {
                setError({ isError: true, errorMessage: [details.data.error.message.value] })
            } else {
                alert('ROMANEIO REGISTRADO COM SUCESSO!');
                history.push(`/home`);
                history.push(`/home/plantationShipment/create/${result.data.DocEntry}`);
            }
        }

        setProgressBar(true);       

    }

    const updateRomaneio = async () => {
        setProgressBar(false);

        const tmpObj = {
            U_Data: obj.U_Data,
            U_Hora: obj.U_Hora,
            Status: obj.Status,
            DocEntry: obj.DocEntry,
            U_PedidoVenda: obj.U_PedidoVenda,
            U_PesoTara: obj.U_PesoTara,
            U_PesoBruto: obj.U_PesoBruto,
            U_PesoLiquido: obj.U_PesoLiquido,
            U_PesoNeto: obj.U_PesoNeto,
            U_Motorista: sanitizeString(obj.U_Motorista),
            U_Placa: sanitizeString(obj.U_Placa),
            U_UFPlaca: obj.U_UFPlaca,
            U_Obs: sanitizeString(obj.U_Obs),
            U_Transportadora: obj.U_Transportadora?.CardCode,
            U_PesoEmbalagem: obj.U_PesoEmbalagem,
            U_DescontoKg: obj.U_DescontoKg,
            U_CCG: obj.U_CCG,
            U_NumeroTicket: obj.U_NumeroTicket,
            U_ItemDescComplementar: obj.U_ItemDescComplementar,
            U_Safra: obj.U_Safra
        };
        tmpObj.B2ROMSAIDAANALISECollection = classification.map((item) => {
            return {
                U_Code: item.U_Code,
                U_Description: item.U_Description,
                U_Value: item.U_Value,
            }
        });
        
        const result = await updateRomaneioSaida(tmpObj, Code);
        
        if (result.data.error) {
            setError({ isError: true, errorMessage: [result.data.error.message.value] })            
        } else {

            const detalhes = await updateDetalhes(null);
            if (detalhes.data.error) {
                setError({ isError: true, errorMessage: [detalhes.data.error.message.value] })
                console.log('Erro aqui', detalhes)
            } else { 


                alert('ROMANEIO ATUALIZADO COM SUCESSO!');
                history.push(`/home`);
                history.push(`/home/plantationShipment/create/${Code}`);
            }

        }

        setProgressBar(true);
    }

    const handleSubmit = async () => {
        setBtnSubmitDisabled(true);
        
        if (!Code) {
            await addRomaneio();
        } else {
            await updateRomaneio();
        }
     
        setBtnSubmitDisabled(false);
    }

    const detalhesRomaneio = async (refId, RomaneioSaidaDocentry) => {
        const tempArray = [...variedades]
        const tempClass = [...classification]
        let finalDetails = [];
        let classificationDetails = [];

        const itemCode = vwObj.Vw_Item.split(' - ')[0];

        tempArray.forEach(item => {
            if (item.U_Variedade && item.U_Talhao && item.U_Quantidade) {
                finalDetails.push(
                    {
                        U_ItemCode: itemCode,
                        U_Variedade: item.U_Variedade,
                        U_Talhao: item.U_Talhao,
                        U_Quantidade: item.U_Quantidade
                    }
                )
            }
        })

        tempClass.forEach(item => {
            if (item.U_Code && item.U_Description && item.U_Value) {
                classificationDetails.push(
                    {
                        U_Code: item.U_Code,
                        U_Description: item.U_Description,
                        U_Value: item.U_Value                        
                    }
                )
            }
        })
   
        const obj = {
            U_RomaneioSaida: RomaneioSaidaDocentry,
            U_UnProdCode: selectedBranch.GlobalLocationNumber,
            U_Ref2: refId,
            B2ROMANEIODETALHESCollection: finalDetails,
            ROM_ANALISECollection: classificationDetails
        }
              
        const result = await createDetailsRomaneio(obj);
       
        return result;
    }

    const updateDetalhes = async (refId) => {
        const tempArray = [...variedades]
        const tempClass = [...classification]
        let finalDetails = [];
        let classificationDetails = [];
        
        const itemCode = vwObj.Vw_Item.split(' - ')[0];

        tempArray.forEach(item => {
            if (item.U_Variedade && item.U_Talhao && item.U_Quantidade) {
                finalDetails.push(
                    {
                        U_ItemCode: itemCode,
                        U_Variedade: item.U_Variedade,
                        U_Talhao: item.U_Talhao,
                        U_Quantidade: item.U_Quantidade
                    }
                )
            }
        })

        tempClass.forEach(item => {
            if (item.U_Code && item.U_Description && item.U_Value) {
                classificationDetails.push(
                    {
                        U_Code: item.U_Code,
                        U_Description: item.U_Description,
                        U_Value: item.U_Value                        
                    }
                )
            }
        })

        const obj = {  
            U_UnProdCode: selectedBranch.GlobalLocationNumber,                      
            B2ROMANEIODETALHESCollection: finalDetails,
            ROM_ANALISECollection: classificationDetails
        }
        
        const result = await updateDetalhesRomaneio(obj, romaneioDetalhesDocEntry);        
        return result;
    }

    const geraNfe = async () => {

        setBtnInvoiceDisabled(true); 
        setProgressBar(false);

        let wtValue = null
        if(selectedSalesOrder.U_B2AG_WTCode) {
            const wt = await getWTDataByCombinationId(selectedSalesOrder.U_B2AG_WTCode)
            const mappedData = mapWTtoInvoice(wt, obj.U_PesoLiquido)
            wtValue = mappedData
        }   

        const freeTxt = obj.U_ItemDescComplementar === 'INTACTA' ? 'INTACTA' : '';
       
        let tmpObj = {
            
            BPL_IDAssignedToInvoice: selectedSalesOrder.BPL_IDAssignedToInvoice,
            CardCode: selectedSalesOrder.CardCode,
            CardName: selectedSalesOrder.CardName,
            Comments: sanitizeString(obj.U_Obs),
            DocDueDate: selectedSalesOrder.U_VctoFixo,
            DocumentLines: [{
                ItemCode: selectedSalesOrder.DocumentLines[0].ItemCode,
                Quantity: obj.U_PesoLiquido,
                UnitPrice: selectedSalesOrder.DocumentLines[0].UnitPrice,
                BaseEntry: selectedSalesOrder.DocEntry,
                BaseLine: selectedSalesOrder.DocumentLines[0].LineNum,
                FreeText: freeTxt,
                BaseType: 17,
                UoMEntry: selectedSalesOrder.DocumentLines[0].UoMEntry,
                WarehouseCode: selectedSalesOrder.DocumentLines[0].WarehouseCode,
                WithholdingTaxLines: wtValue,
                BatchNumbers: [
                    {
                        BatchNumber: obj.U_LoteItem,
                        Quantity: obj.U_PesoLiquido
                    }
                ]
            }],
            DocumentsOwner: localStorage.getItem('@SAP:EMPLOYEEID'),
            TaxExtension: {
                Vehicle: sanitizeString(obj.U_Placa),
                VehicleState: obj.U_UFPlaca,
                Carrier: obj.U_Transportadora,
                Incoterms: selectedSalesOrder.TaxExtension.Incoterms,
                NetWeight: obj.U_PesoLiquido,
                GrossWeight: obj.U_PesoLiquido + obj.U_PesoEmbalagem,
            },
            OpeningRemarks: generateOpeningRemarks()

        }

        if (selectedSalesOrder.DocumentLines[0].Usage === 14) {
            tmpObj.SequenceCode = 29;
        }
        const result = await createInvoice(tmpObj);              

        if (result.data.error) {
            setError({ isError: true, errorMessage: [result.data.error.message.value] })
            
        } else {
            await updateRomaneioSaida({
                U_NFSaida: result.data.DocEntry,
                U_SequenceSerial: result.data.SequenceSerial
            }, Code);
            
            alert('NF GERADA COM SUCESSO!');
            history.push(`/home`);
            history.push(`/home/plantationShipment/create/${Code}`);

        }

        setProgressBar(true);
        setBtnInvoiceDisabled(false);            
        
    }

    function mapWTtoInvoice(wtData, liquidWeight) {

        const data = wtData.map(item => {
            if(item['WTCombination/B2AG_WHT1Collection'].U_B2AG_WTBase === 'VLR') {
                return {
                    WTCode: item['WTCombination/B2AG_WHT1Collection'].U_B2AG_WTCode
                }
            } else {
                return {
                    WTCode: item['WTCombination/B2AG_WHT1Collection'].U_B2AG_WTCode,
                    WTAmount: (item.WithholdingTaxCodes.U_B2AG_Pauta * liquidWeight).toFixed(2)
                }
            }
    
        })
    
        return data
    
    }

    const closeRomaneios = async () => {
        setProgressBar(false);
        await closeRomaneioSaida(Code);
        await closeDetalhesRomaneio(romaneioDetalhesDocEntry)
        setProgressBar(true);
        alert('APONTAMENTO ENCERRADO COM SUCESSO!');
        history.push(`/home`);
        history.push(`/home/plantationShipment/create/${Code}`);
    }

    const generateOpeningRemarks = () => {
        let obs = '';
        if (obj.U_Motorista) {
            obs += `Motorista: ${sanitizeString(obj.U_Motorista)} - `;
        }
        if (obj.U_Placa) {
            obs += `Placa: ${sanitizeString(obj.U_Placa)}-${obj.U_UFPlaca} - `;
        }
        if (obj.U_Obs) {
            obs += `${sanitizeString(obj.U_Obs)} - `;
        }
        if (selectedSalesOrder.OpeningRemarks) {
            obs += `${sanitizeString(selectedSalesOrder.OpeningRemarks)}`;
        }

        return obs;
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5">
                        Romaneio de Saída Lavoura
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                    <Tab label="Dados do Item" value={2} />
                    <Tab label="Nota Fiscal Eletrônica" value={3} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <GeneralData
                        obj={obj}
                        updateObject={updateObject}
                        vwObj={vwObj}
                        updateVwObject={updateVwObject}
                        selectedCarrier={selectedCarrier}
                        setSelectedCarrier={setSelectedCarrier}
                        classification={classification}
                        setClassification={setClassification}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={2}>
                    <ItemData
                        obj={obj}
                        updateObject={updateObject}
                        vwObj={vwObj}
                        updateVwObject={updateVwObject}
                        classification={classification}
                        setClassification={setClassification}
                        variedades={variedades}
                        setVariedades={setVariedades}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={3}>
                    <NfeData
                        obj={obj}
                        updateObject={updateObject}
                        nfObj={selectedInvoice}
                    />
                </TabPanel>
                <Grid item xs={12} sm={6}>
                    {
                        obj.Status !== 'C' &&

                        <Button
                            color="primary"
                            disabled={btnSubmitDisabled}
                            className={classes.button}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            {Code ? 'Atualizar' : 'Adicionar'}
                        </Button>

                    }
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link}
                        to={'/home/outboundListing/list'}
                    >
                        Voltar
                    </Button>

                    {
                        obj.Status === 'O' &&

                        <Button
                            color="primary"
                            disabled={btnInvoiceDisabled}
                            className={classes.button}
                            variant="contained"
                            onClick={geraNfe}
                        >
                            Gerar NF
                        </Button>

                    }

                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        startIcon={<PrintIcon />}
                        onClick={() => {
                            const rom = {
                                Romaneio: { ...obj, ...vwObj },
                                Pedido: selectedSalesOrder,
                                Classificacao: classification,
                                Filial: selectedBranch,
                                Transportadora: selectedCarrier
                            };
                            printPDF(rom)
                        }}
                    >
                        Imprimir
                    </Button>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={closeRomaneios}
                        disabled={obj.DocEntry && obj.Status !== 'O'}
                    >
                        Encerrar Romaneio
                    </Button>

                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>

            </Paper>
        </>
    )
}

export default PlantationShipment;