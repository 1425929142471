import React, { useState } from 'react';
import { TextField, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const Classification = (props) => {

    const classes = CreateAndViewStyle();

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow className={classes.tablehead}>
                            <TableCell>Código</TableCell>
                            <TableCell>Defeito</TableCell>
                            <TableCell>%</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.list.map((el, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={el.U_Code}
                                                disabled
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={el.U_Description}
                                                disabled
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CurrencyTextField
                                                currencySymbol=""
                                                decimalCharacter=","
                                                digitGroupSeparator="."
                                                minimumValue="0"
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={props.object?.ClassificationData ?
                                                    props.object.ClassificationData[index.Valor]
                                                    : ''
                                                }
                                                onChange={(event, value) => {
                                                    let tempArray = [];
                                                    if (props.object.ClassificationData) {
                                                        tempArray = [...props.object.ClassificationData];
                                                    }

                                                    tempArray[index] = { Defeito: el, Valor: value };

                                                    props.setObject({
                                                        ...props.object,
                                                        ClassificationData: tempArray
                                                    })
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    )
}

export default Classification;