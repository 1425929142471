import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    userName: {
        color: 'white',
        fontSize: '10px',
    },
});

const Logout = (props) => {

    const classes = useStyles();

    const handleLogout = () => {

        localStorage.removeItem('@SAP:EMAIL');
        localStorage.removeItem('@SAP:USER');
        localStorage.removeItem('@SAP:USERBRANCHS');
        localStorage.removeItem('@SAP:CURRENTBASE');
        localStorage.removeItem('@SAP:EMPLOYEEID');
        props.history.push('/');
    };

    return (

        <Grid container justify="flex-end">
            <Grid item>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item>
                        <Typography className={classes.userName} variant="button" color="inherit" noWrap>
                            {localStorage.getItem('@SAP:USER')}
                        </Typography>

                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleLogout}
                            variant="text"
                            className={classes.root}
                        >
                            Sair
                </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(Logout);