import api from '../../services/api.js';

export const findWarehouseByLocation = async (ItemCode, LocationCode) => {

    const options = {
        method: 'GET',
        url: `/OperacoesAgricolas/${ItemCode}/${LocationCode}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data;
    }).catch(function (error) {

        console.error(error);
    });
};

export const findAllWarehouseByLocation = async (ItemCode, LocationCode) => {

  const options = {
      method: 'GET',
      url: `/OperacoesAgricolas/all/${ItemCode}/${LocationCode}`,
      headers: { 'Content-Type': 'application/json' }
  };

  return api.request(options).then(function (response) {

      return response.data;
  }).catch(function (error) {

      console.error(error);
  });
};

export const createInputsOut = async (inventoryGenExitsObject) => {

  const options = {
    method: 'POST',
    url: '/InventoryGenExits/create',
    validateStatus: () => true,
    data: inventoryGenExitsObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    
    return error;
  });
};

export const createSeedIn = async (inventoryGenEntrieObject) => {

  const options = {
    method: 'POST',
    url: '/InventoryGenEntries/create',
    validateStatus: () => true,
    data: inventoryGenEntrieObject
  };
  
  return api.request(options).then(function (response) {
    
    return response.data;
  }).catch(function (error) {
    
    return error;
  });
};

export const findSeedTreatmentsRegister = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/seedTreatment/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findEntryByReference2 = async (Reference2) => {

  const options = {
      method: 'GET',
      url: `/InventoryGenEntries/Ref/${Reference2}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const updateInventoryGenEntrie = async (DocEntry, inventoryGenEntriesObject) => {

  const options = {
    method: 'POST',
    url: `/InventoryGenEntries/update/${DocEntry}`,
    validateStatus: () => true,
    data: inventoryGenEntriesObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    
    return error;
  });
};
