import React, { useState } from 'react';
import { Switch, Link } from 'react-router-dom';
import Route from '../../routes/Route';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import './style.css';
import useStyles from './LayoutUseStyles';
import Logo from '../../assets/img/logo-head.png';
import Logout from '../../components/Logout';
import Home from '../../pages/Home';
import { MenuItens, MenuRoutes } from '../../components/Menu/Menu';

const Layout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar   
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/home">
            <img src={Logo} alt="Logo" /> 
          </Link>
          <Grid container justify="flex-end">
            <Grid item>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                  <Typography className={classes.userBase} variant="button" color="inherit" noWrap>
                    {localStorage.getItem('@SAP:CURRENTBASE')}
                  </Typography>  
                </Grid>
              </Grid>
            </Grid>
          </Grid>  
          <Logout />     
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          
          <MenuItens />
         
        </List>        
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Container >           
          <Switch>
            <Route path="/home" exact component={Home} isPrivate />
            <MenuRoutes />         
          </Switch>     
        </Container>
      </main>
    </div>
  );
}

export default Layout;
