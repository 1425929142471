import api from '../../services/api';

export const searchByPartDescription = async (description, type) => {

    const options = {
        method: 'GET',
        url: type ? `/BusinessPartners/searchpart/${description}/${type}` : `/BusinessPartners/searchpart/${description}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then((response) => {
        return response.data.value;
    }).catch(function (error) {
        console.error(error);
    });
};