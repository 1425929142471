import React, { useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import BusinessPartnerAutocomplete from '../../../components/BusinessPartner/Autocomplete';
import { findWarehouseFromBranchAndBP } from '../Components/OutgoingMailingController';

const RecipientData = (props) => {

    const classes = CreateAndViewStyle();

    const handleBPSelection = (value) => {
        props.setObject({
            ...props.object,
            RecipientData: {
                ...props.object.RecipientData,
                destinatario: value
            }
        })
    }

    useEffect(() => {
        async function fetchData() {

            if (props.object.SenderData.selectedBranch && props.object.RecipientData.destinatario) {
                const whs = await findWarehouseFromBranchAndBP(props.object.SenderData.selectedBranch, props.object.RecipientData.destinatario.CardCode);
                if(!whs){
                    alert('NÃO EXISTE DEPÓSITO.')
                }
                props.setObject({
                    ...props.object,
                    RecipientData: {
                        ...props.object.RecipientData,
                        depositoDestino: whs ? whs[0]?.WarehouseCode : ''
                    }
                })
            }

        };

        fetchData();
    }, [props.object.SenderData.selectedBranch, props.object.RecipientData.destinatario]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <BusinessPartnerAutocomplete
                        addItemField={props.object.RecipientData.destinatario}
                        setInputItem={handleBPSelection}
                        width={'100%'}
                        label="Destinatário"
                        type='cSupplier'
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="deposito"
                        label="Depósito Destino"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.depositoDestino}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="cliente"
                        label="Cód. Cliente"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={
                            props.object.RecipientData.destinatario ?
                                props.object.RecipientData.destinatario.LinkedBusinessPartner
                                : ''
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="documento"
                        label="CPF/CNPJ"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={
                            props.object.RecipientData.destinatario ?
                                props.object.RecipientData.destinatario.FederalTaxID
                                : ''
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="fantasia"
                        label="Nome Fantasia"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.destinatario ?
                            props.object.RecipientData.destinatario.AliasName
                            : ''}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="tipo"
                        label="Tipo"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.destinatario ?
                            props.object.RecipientData.destinatario.BPAddresses[1].TypeOfAddress
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="logradouro"
                        label="Logradouro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.destinatario ?
                            props.object.RecipientData.destinatario.BPAddresses[1].Street
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="numero"
                        label="Número"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.destinatario ?
                            props.object.RecipientData.destinatario.BPAddresses[1].StreetNo
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="bairro"
                        label="Bairro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.destinatario ?
                            props.object.RecipientData.destinatario.BPAddresses[1].Block
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="cidade"
                        label="Cidade"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.destinatario ?
                            props.object.RecipientData.destinatario.BPAddresses[1].City
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="cep"
                        label="CEP"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.RecipientData.destinatario ?
                            props.object.RecipientData.destinatario.BPAddresses[1].ZipCode
                            : ''
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default RecipientData;