const moneyFormat = (valueReceived) => {

    
    const result =  valueReceived.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

    return result.toString().replace('R$', '').replace(' ', '');
}

export default moneyFormat;