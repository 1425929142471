import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import {
    Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
    Tabs, Tab, AppBar, Button, LinearProgress, Collapse,
} from '@material-ui/core';
import { findActiveProfitCentersInFirstDimension2 } from '../../components/ProfitCenters/ProfitCentersController';
import { v4 as uuid } from 'uuid';
import Alert from '@material-ui/lab/Alert';
import TabPanel from '../../components/TabPanel';
import { searchByPartDescriptionFuelAndLubrification } from '../../components/Items/ItemsController'
import { create } from '../../pages/InventoryGenExits/InventoryGenExitsController';
import { findByLocationId } from '../../components/Deposits/DepositsController';
import { WarehouseLocations, Safra, Cultura, EmployeesInfo } from '../DefaultController'
import EquipmentsAutoComplete from '../../components/Equipments/EquipmentsAutoComplete'
import EmployeeAutocomplete from '../../components/EmployeesInfo/EmployeesInfoAutoComplete'
import referenceid from '../../services/referenceid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const FuelAndLubrification = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [progressBar, setProgressBar] = useState(true);
    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [operador, setOperador] = useState('');
    const [profitCenter, setProfitCenter] = useState('');
    const [medidor, setMedidor] = useState('');
    const [quantity, setQuantity] = useState('');
    const [comments, setComments] = useState('');
    const [profitCenterList, setProfitCenterList] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [item, setItem] = useState('');
    const [inventoryUOM, setInventoryUOM] = useState('');
    const [itemList, setItemList] = useState([]);
    const classes = CreateAndViewStyle();
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [operadorList, setOperadorList] = useState([]);
    const [safra, SetSafra] = useState('');
    const [safraList, setSafraList] = useState([]);
    const [cultura, setCultura] = useState('');
    const [culturaList, setCulturaList] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [proprio, setProprio] = useState('P');
    const [equipament, setEquipament] = useState({});
    const [thirdEquipament, setThirdEquipament] = useState('');
    const [colaborador, setColaborador] = useState({});
    const [eqp, setEqp] = useState('');

    useEffect(() => {
        async function fetchData() {
            const loc = await WarehouseLocations()
            setLocationsList(loc);

            const sf = await Safra()
            setSafraList(sf)

            const ct = await Cultura()
            setCulturaList(ct)
        };
        fetchData();
    }, []);

    useEffect(() => {
        setEqp('');
        if (proprio === 'P' && equipament) {
            setEqp(`${equipament.ItemCode} - ${equipament.InventoryNumber} - ${equipament.ItemName}`);
        } else if (proprio === 'T') {
            setEqp(thirdEquipament);
        } else if (proprio === 'C' && colaborador) {
            setEqp(`${colaborador.FirstName} ${colaborador.LastName}`);
        }
    });

    const handleItem = async (groupcode) => {
        searchByPartDescriptionFuelAndLubrification(groupcode)
            .then(items => {
                setItemList(items)
            })
    }

    const handleLocationSelection = async (event) => {
        setSelectedLocation(event.target.value);

        setEquipament('')

        const oProfitCenters = await findActiveProfitCentersInFirstDimension2(event.target.value);
        setProfitCenterList(oProfitCenters);

        //Operador
        const opLst = await EmployeesInfo(event.target.value)
        setOperadorList(opLst)
    }

    const handleSubmit = async () => {
        setError({ isError: false, errorMessage: [] });
        setProgressBar(false);
        setSubmitDisabled(true);


        const errorList = hasErrors();

        if (errorList.length === 0) {
            //Seleção do depósito com estoque suficiente para realizar a baixa.
            const depoisitsInLocation = await findByLocationId(selectedLocation)
            const itemObj = itemList.find((i) => {
                return i.ItemCode === item
            })
            let oSelectedDeposit = ''
            let oSelectedBranch = ''
            for (let i = 0; i < itemObj?.ItemWarehouseInfoCollection.length; i++) {
                if (oSelectedDeposit === '') {

                    let validaQuantidade = itemObj.ItemWarehouseInfoCollection[i].InStock >= parseFloat(quantity)
                    if (validaQuantidade) {
                        //console.log(`VALIDA QUANTIDADE: ${itemObj.ItemWarehouseInfoCollection[i].WarehouseCode}`)
                        let dep = depoisitsInLocation.find((d) => {
                            return d.WarehouseCode === itemObj.ItemWarehouseInfoCollection[i].WarehouseCode
                        })

                        if (dep !== undefined) {
                            //console.log('VALIDA DEPOSITO')
                            oSelectedDeposit = itemObj.ItemWarehouseInfoCollection[i].WarehouseCode
                            oSelectedBranch = dep.BusinessPlaceID
                        }
                    }
                }
            }

            if (!oSelectedDeposit) {
                setError({ isError: true, errorMessage: ['Depósito - Quantidade em estoque insuficiente.'] });
                setProgressBar(true);
                setSubmitDisabled(false);
                return
            }

            const refid = referenceid();

            console.log(eqp)

            const finalFulAndLubrificationRequest = {
                DocDate: documentDate,
                U_Equipamento: eqp,
                U_EquipMedidor: medidor,
                U_Operador: operador.EmployeeID,
                U_Safra: safra,
                U_Cultura: cultura,
                U_TipoAbastecimento: proprio,
                Comments: comments,
                JournalMemo: `B2Agri - Abastecimento/Lubrificação (${equipament.InventoryNumber})`,
                U_B2Obj: 'B2AGRI_FUELLUBR',
                BPL_IDAssignedToInvoice: oSelectedBranch,
                Reference2: refid,
                DocumentLines: [
                    {
                        ItemCode: item,
                        Quantity: quantity,
                        WarehouseCode: oSelectedDeposit,
                        CostingCode: profitCenter
                    }
                ]
            }
          
            const result = await create(finalFulAndLubrificationRequest);
            setProgressBar(true);
            setSubmitDisabled(false);

            if (result.data.error) {
                //setServerError({ isServerError: true, serverErrorMessage: result.data.error.message.value })
                setError({ isError: true, errorMessage: [result.data.error.message.value] });

            } else {
                alert('APONTAMENTO REALIZADO COM SUCESSO!')
                history.push('/home/fuelAndLubrification/list');
            }
      
        } else {
            setError({ isError: true, errorMessage: errorList });
            setProgressBar(true);
            setSubmitDisabled(false);
        }
    }

    const hasErrors = () => {

        let errorList = [];

        if (!documentDate)
            errorList.push('Data - Selecione uma data válida.');

        if (!selectedLocation)
            errorList.push('Localização - Selecione uma localização válida.');

        if (!item)
            errorList.push('Item - Selecione um item válido.');

        if (quantity <= 0 || !quantity)
            errorList.push(`Item - Quantidade deve ser maior que zero.`);

        if (proprio === 'P' && !equipament.ItemCode) {
            errorList.push(`Equipamento - Selecione um equipamento ou colaborador.`);
        }

        return errorList;
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Abastecimentos / Lubrificações
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="date"
                                label="Data da Saída"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setDocumentDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Localização</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="localizacao"
                                    id="localizacao"
                                    label="Local de Produção"
                                    value={selectedLocation}
                                    onChange={handleLocationSelection}
                                >
                                    {locationsList.map(location => {
                                        return (
                                            <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="model">Tipo Equipamento</InputLabel>
                                <Select
                                    labelId="tipo"
                                    id="tipo"
                                    label="Tipo Equipamento"
                                    value={proprio}
                                    onChange={(event) => {
                                        setProprio(event.target.value)
                                        setEqp('')
                                    }}
                                >
                                    <MenuItem value={'P'}>Próprio</MenuItem>
                                    <MenuItem value={'T'}>Terceiro</MenuItem>
                                    <MenuItem value={'C'}>Colaborador</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            {proprio === 'P' &&
                                <EquipmentsAutoComplete
                                    location={selectedLocation}
                                    setEquipment={setEquipament}
                                    addEquipmentField={equipament}
                                />
                            }
                            {proprio === 'T' &&
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Equipamento Terceiro"
                                    size="small"
                                    value={thirdEquipament}
                                    onChange={(event) => {
                                        setThirdEquipament(event.target.value)
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            }
                            {proprio === 'C' &&
                                <EmployeeAutocomplete
                                    label="Colaborador"
                                    addItemField={colaborador}
                                    setInputItem={(item) => {
                                        setColaborador(item)
                                    }}
                                    width={'100%'}
                                />
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <CurrencyTextField
                                fullWidth
                                id="medidorvalue"
                                decimalPlaces={1}
                                currencySymbol=""
                                variant="outlined"
                                decimalCharacter=","
                                digitGroupSeparator=""
                                label={!equipament ? '' : equipament.U_Medidor === '' ? '' : (equipament.U_Medidor === 'H' ? 'Horímetro' : 'Odômetro')}
                                size="small"
                                value={medidor}
                                minimumValue="0"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event, value) => {
                                    setMedidor(value);
                                }}
                                disabled={!equipament ? true : equipament.U_Medidor === ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <EmployeeAutocomplete
                                label="Operador"
                                addItemField={operador}
                                setInputItem={(item) => {
                                    setOperador(item)
                                }}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="safra">Safra</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="safra"
                                    id="Safra"
                                    label="safra"
                                    defaultValue=""
                                    onChange={(event) => {
                                        SetSafra(event.target.value)
                                    }}
                                >
                                    {safraList.map(item => {
                                        return (
                                            <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                        );
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="cultura">Cultura</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="cultura"
                                    id="cultura"
                                    label="Cultura"
                                    defaultValue=""
                                    onChange={(event) => {
                                        setCultura(event.target.value)
                                    }}
                                >
                                    {culturaList.map(item => {
                                        return (
                                            <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                        );
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="tipo">Tipo</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="tipo"
                                    id="tipo"
                                    label="Tipo"
                                    defaultValue=""
                                    onChange={(event) => {
                                        handleItem(event.target.value)
                                    }}
                                >
                                    <MenuItem value={103}>ABASTECIMENTO</MenuItem>
                                    <MenuItem value={116}>LUBRIFICAÇÃO</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="item">Item</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="item"
                                    id="item"
                                    label="Item"
                                    defaultValue=""
                                    value={item}
                                    onChange={(event) => {
                                        setItem(event.target.value)
                                        setInventoryUOM(itemList.find(i => i.ItemCode === event.target.value).InventoryUOM)
                                    }}
                                >
                                    {itemList.map(item => {
                                        return (
                                            <MenuItem value={item.ItemCode} key={uuid()}>{item.ItemCode + ' - ' + item.ItemName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                disabled
                                variant="outlined"
                                label="U.M."
                                size="small"
                                value={inventoryUOM}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CurrencyTextField
                                fullWidth
                                variant="outlined"
                                currencySymbol=""
                                decimalCharacter=","
                                digitGroupSeparator="."
                                minimumValue="0"
                                label="Quantidade"
                                size="small"
                                value={quantity}
                                onChange={(event, value) => {
                                    setQuantity(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small" >
                                <InputLabel id="centro-de-custo">Centro de Custo</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="centro-de-custo"
                                    label="Centro de Custo"
                                    onChange={(event) => {
                                        setProfitCenter(event.target.value)
                                    }}
                                >
                                    {profitCenterList.map(profit => {
                                        return (
                                            <MenuItem value={profit.CenterCode} key={uuid()}>{profit.CenterCode + ' - ' + profit.CenterName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" disabled={submitDisabled} color="primary" className={classes.button} onClick={handleSubmit}>
                                Adicionar
                            </Button>
                            <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home'}>
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress hidden={progressBar} />
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={error.isError}>
                                <Alert severity="error"
                                    action={
                                        <Button color="inherit" size="small"
                                            onClick={() => {
                                                setError({ isError: false, errorMessage: [] });
                                            }}
                                        >
                                            Fechar
                                        </Button>
                                    }
                                >
                                    {error.errorMessage.map(err => {
                                        return (
                                            <>- {err} <br /></>

                                        )
                                    })}
                                </Alert>
                            </Collapse>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Paper>
        </>
    )
}

export default FuelAndLubrification;