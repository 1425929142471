import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import { findOne } from './InventoryGenExitsController';
import status from '../../services/documentStatus';
import { 
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell, 
    TableContainer, Button, Typography, Tabs, AppBar, Tab
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import TabPanel from '../../components/TabPanel';

const InventoryGenExitsView = () => {

    let { id } = useParams();
    const [tabs, setTabs] = useState(1);
    const [inventoryGenExit, setInventoryGenExit] = useState({});
    const [items, setItems] = useState([]);
    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {
            const result = await findOne(id);
            const docStatus = status(result.DocumentStatus, result.Cancelled);
            setInventoryGenExit({
                "DocNum": result.DocNum,
                "DocDate": result.DocDate,
                "Comments": result.Comments,
                "BPLName": result.BPLName, 
                "ProjectCode": result.ProjectCode,              
                "DocumentStatus": docStatus             
            });
            setItems(result.DocumentLines);
        };

        fetchData();
    }, [id, inventoryGenExit]);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                    Saída de Mercadoria - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Código"
                                type="text"
                                value={inventoryGenExit.DocNum}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="date"
                                label="Data da Saída"
                                type="date"
                                value={inventoryGenExit.DocDate}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="status"
                                label="Status"
                                type="text"
                                value={inventoryGenExit.DocumentStatus}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Filial"
                                variant="outlined"
                                size="small"
                                value={inventoryGenExit.BPLName}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={16}>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>Quantidade</TableCell>
                                            <TableCell>Projeto</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item) => {
                                            return (
                                                <TableRow key={uuid()}>
                                                    <TableCell>{item.ItemCode}</TableCell>
                                                    <TableCell>{item.ItemDescription}</TableCell>
                                                    <TableCell>{item.Quantity}</TableCell>                                                
                                                    <TableCell>{item.ProjectCode}</TableCell>                                                
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                value={inventoryGenExit.Comments}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {inventoryGenExit.DocumentStatus === 'Aberto' && 
                            <>
                            <Button 
                                color="primary" 
                                className={classes.button} 
                                variant="outlined" 
                                component={Link} 
                                to={'/home/inventoryGenExits'}
                            >
                                Voltar
                            </Button>
                            </>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
            </Paper>
        </>
    );
};

export default InventoryGenExitsView;