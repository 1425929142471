import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import getTodayDate from '../../services/getTodayDate';
import referenceid from '../../services/referenceid';
import {
    Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
    Tabs, Tab, AppBar, Button, LinearProgress, Collapse, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import ItemsAutocomplete from './ItemsAutocomplete';
import { v4 as uuid } from 'uuid';
import TabPanel from '../../components/TabPanel';
import { WarehouseLocations, WarehouseToBranchList } from '../DefaultController';
import { findWarehouseByLocation, createInputsOut, findAllWarehouseByLocation, createSeedIn } from './SeedTreatmentController';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const SeedTreatment = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [documentDate, setDocumentDate] = useState(getTodayDate());
    const [seedItem, setSeedItem] = useState({});
    const [seedItemDeposits, setSeedItemDeposits] = useState([]);
    const [seedItemSelectedDeposit, setSeedItemSelectedDeposit] = useState('');
    const [comments, setComments] = useState('');
    const [progressBar, setProgressBar] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [items, setItems] = useState([{ ItemCode: '', ItemName: '', Quantity: 0 }]);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [quantity, setQuantity] = useState('');
    const [rda, setRda] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [warehouseToBranchList, setWarehouseToBranchList] = useState([]);
    let tempPrice = 0;
    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {
            const loc = await WarehouseLocations()
            setLocationsList(loc);

            const whs = await WarehouseToBranchList()
            setWarehouseToBranchList(whs)
        };
        fetchData();
    }, []);

    const handleNewItem = () => {

        setItems([...items, { ItemCode: '', ItemName: '', InventoryUOM: '', Quantity: 0 }]);
    };

    const handleDeleteItem = (event) => {

        const index = event.currentTarget.dataset.key;
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    }

    const setInputItem = (item, index) => {
        if (item) {

            let tempItems = [...items];
            tempItems[index].ItemCode = item.ItemCode;
            tempItems[index].ItemName = item.ItemName;
            tempItems[index].InventoryUOM = item.InventoryUOM;
            setItems(tempItems);
        }
    }

    const searchItemWarehouses = async (item, index) => {
        setSeedItemDeposits([]);

        if (item) {
            const deps = await findAllWarehouseByLocation(item.ItemCode, selectedLocation);
            setSeedItemDeposits(deps.value);

        }
    }

    const searchListItemWarehouses = async (item, index) => {

        if (item) {
            let tempItems = [...items];
            //tempItems[index].DepositosCusto = item.ItemWarehouseInfoCollection;

            const deps = await findWarehouseByLocation(item.ItemCode, selectedLocation);
            tempItems[index].ListaDepositos = deps.value;
            setItems([...tempItems]);
            console.log(tempItems)

        }
    }

    const mapWarehouseToBranch = (WhsCode) => {
        let result = null;

        warehouseToBranchList.forEach((value) => {
            if (value.WarehouseCode === WhsCode)
                result = value.BusinessPlaceID
        })
        return result;
    }

    const validateStocks = () => {

        if (items) {

            for (let i = 0; i < items.length; i++) {

                for (let j = 0; j < items[i]?.ListaDepositos?.length; j++) {
                    let item = items[i].ListaDepositos[j]["Items/ItemWarehouseInfoCollection"];
                    if (items[i].WarehouseCode === item.WarehouseCode) {

                        if (items[i].Quantity > item.InStock) {
                            setError({ isError: true, errorMessage: [`Quantidade maior que saldo disponível na linha [${++i}]`] });
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    const unique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const uniqueWarehouses = () => {

        let whs = items.map(item => {
            return item.WarehouseCode
        })

        let uniqueWhs = whs.filter(unique);

        return uniqueWhs;
    }

    const createItemListByWarehouses = () => {

        const uniqueWhs = uniqueWarehouses();

        const objList = [];

        uniqueWhs.forEach((whs, whsIndex) => {

            objList[whsIndex] = [];

            items.forEach((item, itemIndex) => {

                if (whs === item.WarehouseCode) {
                    let it = item;
                    it.DepositosCusto = null;
                    it.ListaDepositos = null;

                    objList[whsIndex].push(it);
                }
            })
        })

        return objList;
    }

    const handleInputsOut = async (refid) => {

        if (validateStocks()) {

            const inputsOut = {
                DocDate: documentDate,
                Comments: comments,
                JournalMemo: `B2Agri - Tratamento de Sementes (${refid})`,
                U_B2Obj: 'B2AGRI_SEEDTREAT',
                U_B2AG_B2ObjectType: 'SeedTreat',
                GroupNumber: -2,
                Reference2: refid,
                U_RDA: rda
            }

            const itemList = createItemListByWarehouses();

            for( const item of itemList ) {
                let obj = { ...inputsOut };
                obj.BPL_IDAssignedToInvoice = item[0].Branch;

                obj.DocumentLines = item;
                console.log(obj)
                const inputsOutResult = await createInputsOut(obj);
                
                if (inputsOutResult.data.error) {
                    setError({ isError: true, errorMessage: [inputsOutResult.data.error.message.value] });
                    return false;
                } else {
                    tempPrice += inputsOutResult.data.DocTotal;   
                                  
                }
            }
            return true;
        }

    }

    const handleSeedIn = async (refId) => {

        const seedIn = {
            DocDate: documentDate,
            JournalMemo: `B2Agri - Tratamento de Sementes (${refId})`,
            Comments: comments,
            Reference2: refId,
            U_B2Obj: 'B2AGRI_SEEDTREAT',
            U_B2AG_B2ObjectType: 'SeedTreat',
            U_RDA: rda,
            DocumentLines: [{
                ItemCode: seedItem.ItemCode,
                ItemName: seedItem.ItemName,
                WarehouseCode: seedItemSelectedDeposit,
                Quantity: quantity,                
                LineTotal: tempPrice
            }],
            BPL_IDAssignedToInvoice: mapWarehouseToBranch(seedItemSelectedDeposit)
        }

        const seedInResult = await createSeedIn(seedIn);
        return seedInResult;
    }

    const hasErrors = () => {

        let errorList = [];

        if (!documentDate)
            errorList.push('Data - Selecione uma data válida.');

        if (!selectedLocation)
            errorList.push('Localização - Selecione uma localização válida.');

        if (!seedItem.ItemCode)
            errorList.push('Item - Selecione um item de Semente Tratada válido.');

        if (!seedItemSelectedDeposit)
            errorList.push('Depósito - Selecione um depósito válido.');


        return errorList;
    }

    const handleSubmit = async () => {

        console.log(seedItem)

        setProgressBar(false);
        setSubmitDisabled(true);

        const errorList = hasErrors();

        if (errorList.length === 0) { 

            const refid = referenceid();
    
            const inputsOutResult = await handleInputsOut(refid);
    
            
            if (inputsOutResult) {
                
                const seedInResult = await handleSeedIn(refid);
    
                if (seedInResult?.error) {
    
                    setError({ isError: true, errorMessage: [seedInResult.error.message.value] });
                    setSubmitDisabled(false);
    
                } else {
                    setProgressBar(true);
                    setSubmitDisabled(false);
                    alert('APONTAMENTO REALIZADO COM SUCESSO!')
                    history.push('/home/seedTreatment/list');
                }
            }
        } else {
            setError({ isError: true, errorMessage: errorList });
        }

        
        setProgressBar(true);
        setSubmitDisabled(false);
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Tratamento de Sementes
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                    <Tab label="Insumos" value={2} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                id="date"
                                style={{ width: "100%" }}
                                label="Data do Tratamento"
                                type="date"
                                defaultValue={getTodayDate()}
                                value={documentDate}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    setDocumentDate(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="localizacao">Localização</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="localizacao"
                                    id="localizacao"
                                    label="Local de Produção"
                                    value={selectedLocation}
                                    onChange={(event) => {
                                        setSelectedLocation(event.target.value)
                                    }}
                                >
                                    {locationsList.map(location => {
                                        return (
                                            <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="rda"
                                label="RDA"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={rda}
                                onChange={(event) => {
                                    setRda(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <ItemsAutocomplete
                                label="Item Semente Tratada"
                                addItemField={seedItem}
                                setInputItem={(item) => {
                                    setSeedItem(item)
                                }}
                                othersInputs
                                onExecute={searchItemWarehouses}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                disabled
                                variant="outlined"
                                label="Unidade de Medida"
                                size="small"
                                value={seedItem?.InventoryUOM}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CurrencyTextField
                                fullWidth
                                currencySymbol=""
                                decimalCharacter=","
                                digitGroupSeparator="."
                                minimumValue="0"
                                variant="outlined"
                                label="Quantidade"
                                size="small"
                                value={quantity}
                                onChange={(event, value) => {
                                    setQuantity(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="deposito">Depósito</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="deposito"
                                    label="Depósito"
                                    id="deposito-select"
                                    value={seedItemSelectedDeposit}
                                    onChange={(event) => {
                                        setSeedItemSelectedDeposit(event.target.value)
                                    }}
                                >
                                    {seedItemDeposits?.map(deposito => {
                                        return (
                                            <MenuItem key={uuid()} value={deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode}>{deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode + ' / ' + deposito["Items/ItemWarehouseInfoCollection"].InStock + ' ' + seedItem?.InventoryUOM}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="comments"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                value={comments}
                                onChange={(event) => {
                                    setComments(event.target.value);
                                }}
                            />
                        </Grid>

                    </Grid>
                </TabPanel>
                <TabPanel value={tabs} index={2}>
                    <Grid item xs={12} sm={16}>
                        <Button
                            variant="contained"
                            onClick={handleNewItem}
                            size='small'
                            color='primary'
                        >
                            Novo item
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow className={classes.tablehead}>
                                        <TableCell>Item</TableCell>
                                        <TableCell>U.M.</TableCell>
                                        <TableCell>Depósito/Estoque</TableCell>
                                        <TableCell>Quantidade</TableCell>
                                        <TableCell>Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <ItemsAutocomplete
                                                        idx={index}
                                                        addItemField={items[index]}
                                                        setInputItem={setInputItem}
                                                        othersInputs
                                                        onExecute={searchListItemWarehouses}
                                                        width={600}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        variant="outlined"
                                                        label="U.M."
                                                        size="small"
                                                        value={items[index].InventoryUOM}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl variant="outlined" size="small">
                                                        <Select
                                                            value={items[index].WarehouseCode ?? ''}
                                                            style={{ width: 120 }}
                                                            labelId="deposito"
                                                            id="deposito"
                                                            onChange={(event) => {
                                                                let tempItems = [...items];
                                                                
                                                                tempItems[index].WarehouseCode = event.target.value;
                                                                tempItems[index].Branch = mapWarehouseToBranch(event.target.value);                                                                
                                                                setItems([...tempItems]);                                                                
                                                            }}
                                                        >
                                                            {items[index].ListaDepositos?.map(deposito => {
                                                                return (
                                                                    <MenuItem key={uuid()} value={deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode}>{deposito["Items/ItemWarehouseInfoCollection"].WarehouseCode + ' / ' + deposito["Items/ItemWarehouseInfoCollection"].InStock + ' ' + items[index].InventoryUOM}</MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <CurrencyTextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        label="Quantidade"
                                                        currencySymbol=""
                                                        decimalCharacter=","
                                                        digitGroupSeparator="."
                                                        minimumValue="0"
                                                        value={items[index].Quantity}
                                                        onChange={(event, value) => {
                                                            let tempItems = [...items];
                                                            tempItems[index].Quantity = value;
                                                            tempItems[index].Branch = mapWarehouseToBranch(items[index].WarehouseCode);
                                                            setItems([...tempItems]);
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        data-key={index}
                                                        onClick={handleDeleteItem}
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </TabPanel>

                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                        Adicionar
                    </Button>
                    <Button color="primary" className={classes.button} variant="outlined" component={Link} to={'/home'}>
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    )
}

export default SeedTreatment;