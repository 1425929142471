import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { close } from './PurchaseRequestController';
import { 
    Paper, 
    Grid,
    Typography,
    Button

} from '@material-ui/core';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';


const PurchaseRequestClose = ({history}) => {

    let { id } = useParams();
    const classes = CreateAndViewStyle();

    const handleClose = async () => {

        await close(id);
        history.push('/home/purchaseRequest'); 
    };

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                    Solicitação de Compra - Encerrar
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}> 
                        <Typography variant="h6">
                            Tem certeza que deseja encerrar a Solicitação de Compras <strong>{id}</strong> ?
                        </Typography>     
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <Button 
                        color="primary" 
                        className={classes.button} 
                        variant="contained"   
                        onClick={handleClose}                     
                    >
                            Encerrar
                    </Button>
                    <Button 
                        color="primary" 
                        className={classes.button} 
                        variant="outlined"
                        component={Link} 
                        to={`/home/purchaseRequest/view/${id}`}                         
                    >
                            Voltar
                    </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default PurchaseRequestClose;