const validate = (obj, item) => {
    let errorList = [];

    if (!obj.SenderData.selectedBranch)
        errorList.push('Dados do Remetente - Selecione uma filial válida.');

    if (!obj.SenderData.selectedDeposit)
        errorList.push('Dados do Remetente - Selecione um depósito válido.');


    if (obj.DadosCultivo.length <= 0 || !obj.DadosCultivo[0].Talhao)
        errorList.push('Dados do Item - Selecione um talhão válido.');

    if (!obj.RecipientData.depositoDestino)
        errorList.push('Dados do Destinatário - Não existe depósito configurado para este remetente.');

    if (!item)
        errorList.push('Dados do Item - Selecione um item válido.');


    if (!obj.ItemData.pesoTara)
        errorList.push('Dados do Item - Informe o peso tara.');

    if (!obj.ItemData.numeroTicket)
        errorList.push('Dados do Item - Informe o número do ticket.');

    if (!obj.ItemData.pesoBruto)
        errorList.push('Dados do Item - Informe o peso bruto.');

    const pLiquido = parseFloat(obj.ItemData.pesoLiquido ? obj.ItemData.pesoLiquido : 0)
    const pCultivo = Object.values(obj.DadosCultivo).reduce((t, { Quantidade }) => t + parseFloat(Quantidade ? Quantidade : '0'), 0)

    if (pLiquido !== pCultivo) {
        errorList.push(`Dados do Item - Quantidades informadas nos talhões (${pCultivo}) divergem do peso líquido calculado (${pLiquido}).`);
    }

    return errorList;
}

export default validate;