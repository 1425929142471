import React, { useState, useEffect } from 'react';
import {
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Typography,
    FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import ItemsAutocomplete from '../../../components/Items/ItemsAutocomplete';
import { findBatchNumbersFromItem, findCultureByItemCode, findItemByCode } from './OwnShipmentController';
import { findByBranchIdWithThird } from '../../../components/Deposits/DepositsController';
import { Talhoes, Cultura } from '../../DefaultController';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import moneyFormat from '../../../services/moneyFormat';
import { Safra } from '../../DefaultController';
import { v4 as uuid } from 'uuid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { findDiscountByAnalises } from './OwnShipmentController';

const ItemData = (props) => {

    const classes = CreateAndViewStyle();

    const [batchs, setBatchs] = useState([]);
    const [batchEnabled, setBatchEnabled] = useState(false);
    const [talhoes, setTalhoes] = useState([]);
    const [varieties, setVarieties] = useState([]);
    const [totalQtdeTalhoes, setTotalQtdeTalhoes] = useState(0.0)
    const [safraList, setSafraList] = useState([]);

    useEffect(() => {
        async function fetchData() {

            atualizaSomatoria()
        }

        fetchData();
    });

    useEffect(() => {
        async function fetchData() {

            const sf = await Safra()
            setSafraList(sf)

        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {

            if (props.item) {
                const iCode = props.item.ItemCode;

                const ct = await Cultura()
                const tempCulture = ct.filter((atual) => {
                    if (atual.U_ItemCode === iCode)
                        return atual;
                })

                if (tempCulture[0]?.VARIEDADESCollection?.length > 0) {

                    setVarieties(tempCulture[0].VARIEDADESCollection)
                } else {

                    setVarieties([]);
                }
            }
        };

        fetchData();
    }, [props.object.ItemData.item]);

    useEffect(() => {
        async function fetchData() {
            const iCode = props.item.ItemCode;
            setBatchs([]);
            const itemResult = await findItemByCode(iCode);

            if (itemResult?.ManageBatchNumbers === 'tYES') {
                setBatchEnabled(true)
            }
            const btc = await findBatchNumbersFromItem(iCode);
            setBatchs(btc);
        };

        fetchData();
    }, [props.object.ItemData]);

    useEffect(() => {
        async function fetchData() {

            if (props.object.SenderData.selectedDeposit) {
                const deposits = await findByBranchIdWithThird(props.object.SenderData.selectedBranch);
                const depObj = deposits.find(x => x.WarehouseCode === props.object.SenderData.selectedDeposit);
                if (depObj) {

                    const tal = await Talhoes(depObj.Location)
                    if (tal[0]) {

                        setTalhoes(tal[0].TALHOESCollection);
                    }
                }
            }
        };

        fetchData();
    }, [props.object.SenderData.selectedDeposit]);

    const atualizaSomatoria = () => {

        const dCultivo = props.object.DadosCultivo
        if (dCultivo) {
            const pCultivo = Object.values(dCultivo).reduce((t, { Quantidade }) => t + parseFloat(Quantidade ? Quantidade : 0), 0)
            setTotalQtdeTalhoes(pCultivo)
        }
    }

    const handleItemSelection = async (value) => {

        props.setItem(value)
        handleClassificationList(value.ItemCode)

        const ct = await Cultura()
        const tempCulture = ct.filter((atual) => {
            if (atual.U_ItemCode === value.ItemCode)
                return atual;
        })

        if (tempCulture[0]?.VARIEDADESCollection?.length > 0) {

            setVarieties(tempCulture[0].VARIEDADESCollection)
        } else {

            setVarieties([]);
        }
    }

    const handleClassificationList = async (ItemCode) => {
        const classific = await findCultureByItemCode(ItemCode);
        if (classific.length > 0) {

            props.setObject({
                ...props.object,
                ClassificationData: classific[0].CULT_ANALISESCollection
            })
        }
    }

    const handlePesoNeto = () => {
        if (props.object.ItemData.pesoBruto && props.object.ItemData.pesoTara) {

            // Limpa classificacao caso os pesos mudem
            for (let i = 0; i < props.object.ClassificationData.length; i++) {
                let tempArray = [];
                if (props.object.ClassificationData) {
                    tempArray = [...props.object.ClassificationData];
                }
                tempArray[i].DescontoPercentual = 0;
                tempArray[i].DescontoKilos = 0;
                tempArray[i].Valor = 0;

                props.setObject({
                    ...props.object,
                    ClassificationData: [
                        ...tempArray
                    ]
                })

            }

            const neto = props.object.ItemData.pesoBruto - props.object.ItemData.pesoTara;

            props.setObject({
                ...props.object,
                ItemData: {
                    ...props.object.ItemData,
                    pesoNeto: neto
                }
            })
        }
    }

    const handlePesoLiquido = () => {

        const liquido = (props.object.ItemData.pesoNeto - props.discountKg).toFixed(2);

        props.setObject({
            ...props.object,
            ItemData: {
                ...props.object.ItemData,
                pesoLiquido: liquido
            }
        })

    }

    const handleDeleteItem = (event) => {
        const index = event.currentTarget.dataset.key;

        let tempArray = [];
        if (props.object.DadosCultivo) {
            tempArray = [...props.object.DadosCultivo]
            tempArray.splice(index, 1);
            props.setObject({
                ...props.object,
                DadosCultivo: tempArray
            })
        }

        atualizaSomatoria()
    }

    const calculateDiscount = () => {
        let totalDiscount = 0;
        if (props.object.ClassificationData) {
            for (let i = 0; i < props.object.ClassificationData.length; i++) {
                if (props.object.ClassificationData[i].DescontoKilos) {
                    totalDiscount += props.object.ClassificationData[i].DescontoKilos;
                }
            }

            props.setDiscountKg(totalDiscount)
        }

    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Dados do Item</Typography>
                </Grid>
                <Grid item xs={6}>
                    <ItemsAutocomplete
                        idx={0}
                        addItemField={props.item}
                        setInputItem={handleItemSelection}
                        width={'100%'}
                        graos
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        id="um"
                        label="U.M."
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={
                            props.item ?
                                props.item.InventoryUOM
                                : ''
                        }
                    />
                </Grid>
                <Grid item xs={5}>
                    {
                        batchEnabled &&

                        <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                            <InputLabel id="loteLabel">Lote</InputLabel>
                            <Select
                                labelId="loteLabel"
                                id="lote"
                                label="Lote"
                                value={props.object.ItemData.lote || ''}
                                defaultValue=""
                                onChange={event => {
                                    props.setObject({
                                        ...props.object,
                                        ItemData: {
                                            ...props.object.ItemData,
                                            lote: event.target.value
                                        }
                                    })
                                }}
                            >
                                {batchs.map(lote => {
                                    return (
                                        <MenuItem value={lote.Batch}>{lote.Batch}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography>Dados de Cultivo</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="safra-select-input">Safra</InputLabel>
                        <Select
                            fullWidth
                            labelId="safra-select-input"
                            id="safra-select"
                            label="Safra"
                            defaultValue=""
                            value={props.object.ItemData.safra || ''}
                            onChange={event => {
                                props.setObject({
                                    ...props.object,
                                    ItemData: {
                                        ...props.object.ItemData,
                                        safra: event.target.value
                                    }
                                })
                            }}
                        >
                            {safraList.map(item => {
                                return (
                                    <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} disabled>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Variedade</TableCell>
                                    <TableCell>Talhão</TableCell>
                                    <TableCell align='right'>Quantidade</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.object.DadosCultivo.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={classes.tablePaddingRemove} style={{ width: '40%' }}>
                                                <FormControl variant="outlined" size="small" fullWidth>
                                                    <Select
                                                        labelId="variedadeInput"
                                                        value={props.object.DadosCultivo ? props.object.DadosCultivo[index].Variedade : ''}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            'data-key': index,
                                                        }}
                                                        onChange={event => {
                                                            let tempArray = [];
                                                            if (props.object.DadosCultivo) {
                                                                tempArray = [...props.object.DadosCultivo];
                                                            }

                                                            tempArray[index] = {
                                                                Talhao: tempArray[index].Talhao,
                                                                Variedade: event.target.value,
                                                                Quantidade: tempArray[index].Quantidade
                                                            };

                                                            props.setObject({
                                                                ...props.object,
                                                                DadosCultivo: tempArray
                                                            })
                                                        }}
                                                    >
                                                        {varieties.map(variety => {
                                                            return (
                                                                <MenuItem value={variety.U_Variedades}>{variety.U_Variedades}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={classes.tablePaddingRemove} style={{ width: '30%' }}>
                                                <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                                                    <Select
                                                        labelId="talhao"
                                                        id={index}

                                                        value={props.object.DadosCultivo ? props.object.DadosCultivo[index].Talhao : ''}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            'data-key': index,
                                                        }}
                                                        onChange={event => {
                                                            let tempArray = [];
                                                            if (props.object.DadosCultivo) {
                                                                tempArray = [...props.object.DadosCultivo];
                                                            }

                                                            tempArray[index] = {
                                                                Talhao: event.target.value,
                                                                Variedade: tempArray[index].Variedade,
                                                                Quantidade: tempArray[index].Quantidade
                                                            };

                                                            props.setObject({
                                                                ...props.object,
                                                                DadosCultivo: tempArray
                                                            })
                                                        }}
                                                    >
                                                        {talhoes.map(talhao => {
                                                            return (
                                                                <MenuItem value={talhao.U_TalhaoCode}>{talhao.U_TalhaoCode}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='right' className={classes.tablePaddingRemove} style={{ width: '30%' }}>
                                                <CurrencyTextField
                                                    align='right'
                                                    id={index}
                                                    currencySymbol=""
                                                    decimalCharacter=","
                                                    digitGroupSeparator="."
                                                    minimumValue="0"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={props.object.DadosCultivo ? props.object.DadosCultivo[index].Quantidade : ''}
                                                    onChange={(event, value) => {
                                                        let tempArray = [];
                                                        if (props.object.DadosCultivo) {
                                                            tempArray = [...props.object.DadosCultivo];
                                                        }

                                                        tempArray[index] = {
                                                            Talhao: tempArray[index].Talhao,
                                                            Variedade: tempArray[index].Variedade,
                                                            Quantidade: value
                                                        };

                                                        props.setObject({
                                                            ...props.object,
                                                            DadosCultivo: tempArray
                                                        })

                                                        atualizaSomatoria()
                                                    }}
                                                    onBlur={() => {
                                                        let tempArray = [];
                                                        if (props.object.DadosCultivo) {
                                                            tempArray = [...props.object.DadosCultivo];
                                                            const existeLinhaEmBranco = tempArray.find((o) => {
                                                                return o.Talhao === '' || o.Quantidade === ''
                                                            })

                                                            if (existeLinhaEmBranco === undefined) {
                                                                props.setObject({
                                                                    ...props.object,
                                                                    DadosCultivo:
                                                                        [...props.object.DadosCultivo, { Variedade: '', Talhao: '', Quantidade: '' }]

                                                                })
                                                            }
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    data-key={index}
                                                    onClick={handleDeleteItem}
                                                >
                                                    <DeleteOutlineIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>

                                    );
                                })}
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align='right'>{moneyFormat(totalQtdeTalhoes)}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Dados de Pesagem</Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="ticket"
                        label="Nº Ticket"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.numeroTicket}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    numeroTicket: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="ccg"
                        label="CCG"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.ccg}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    ccg: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoBruto"
                        label="Peso Bruto"
                        fullWidth
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoBruto ?? ''}
                        onBlur={handlePesoNeto}
                        onChange={(event, value) => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    pesoBruto: value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoTara"
                        label="Peso Tara"
                        fullWidth
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoTara ?? ''}
                        onBlur={handlePesoNeto}
                        onChange={(event, value) => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    pesoTara: value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoNeto"
                        label="Peso Neto"
                        fullWidth
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoNeto ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="desconto"
                        label="Desconto KG"
                        fullWidth
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        InputLabelProps={{ shrink: true }}
                        value={props.discountKg ?? ''}
                        disabled
                    />
                </Grid>

                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoLiquido"
                        label="Peso Liquido"
                        fullWidth
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        InputLabelProps={{ shrink: true }}
                        value={props.object.ItemData.pesoLiquido ?? ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Classificação</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.tablehead}>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Defeito</TableCell>
                                    <TableCell>%</TableCell>
                                    <TableCell>Desconto %</TableCell>
                                    <TableCell>Desconto KG</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.object.ClassificationData.map((el, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={el.U_Code}
                                                        disabled
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={el.U_Description}
                                                        disabled
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <CurrencyTextField
                                                        variant="outlined"
                                                        size="small"
                                                        currencySymbol=""
                                                        decimalCharacter=","
                                                        digitGroupSeparator="."
                                                        minimumValue="0"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={props.object.ClassificationData ?
                                                            props.object.ClassificationData[index].Valor
                                                            : ''
                                                        }
                                                        onBlur={async () => {

                                                            if (props.object.ClassificationData[index]) {

                                                                const code = props.object.ClassificationData[index].U_Code;
                                                                const percentual = props.object.ClassificationData[index].Valor;

                                                                if (percentual > 0) {

                                                                    const perc = await findDiscountByAnalises(code, percentual);
                                                                    if (perc[0]) {
                                                                        const percentValue = perc[0].["TiposAnalises/T_ANALISES_LINHASCollection"].U_Desconto;

                                                                        let tempArray = [];
                                                                        if (props.object.ClassificationData) {
                                                                            tempArray = [...props.object.ClassificationData];
                                                                        }
                                                                        tempArray[index].DescontoPercentual = percentValue;
                                                                        tempArray[index].DescontoKilos = ((props.object.ItemData.pesoNeto * props.object.ClassificationData[index].DescontoPercentual) / 100);

                                                                        props.setObject({
                                                                            ...props.object,
                                                                            ClassificationData: [
                                                                                ...tempArray
                                                                            ]
                                                                        })
                                                                    }
                                                                }
                                                                calculateDiscount()

                                                            }
                                                            handlePesoLiquido()

                                                        }}
                                                        onChange={async (event, value) => {

                                                            let tempArray = [];
                                                            if (props.object.ClassificationData) {
                                                                tempArray = [...props.object.ClassificationData];
                                                            }

                                                            tempArray[index].Valor = value;

                                                            props.setObject({
                                                                ...props.object,
                                                                ClassificationData: [
                                                                    ...tempArray
                                                                ]
                                                            })

                                                            handlePesoLiquido()
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <CurrencyTextField
                                                        variant="outlined"
                                                        size="small"
                                                        currencySymbol=""
                                                        decimalCharacter=","
                                                        digitGroupSeparator="."
                                                        minimumValue="0"
                                                        disabled
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={props.object.ClassificationData ?
                                                            props.object.ClassificationData[index].DescontoPercentual
                                                            : ''
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <CurrencyTextField
                                                        variant="outlined"
                                                        size="small"
                                                        currencySymbol=""
                                                        decimalCharacter=","
                                                        digitGroupSeparator="."
                                                        minimumValue="0"
                                                        disabled
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}

                                                        value={props.object.ClassificationData ?
                                                            props.object.ClassificationData[index].DescontoKilos
                                                            : ''
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Observações"
                        multiline
                        rows={2}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={props.object.ItemData.comments}
                        onBlur={handlePesoLiquido}
                        onChange={event => {
                            props.setObject({
                                ...props.object,
                                ItemData: {
                                    ...props.object.ItemData,
                                    comments: event.target.value
                                }
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ItemData;