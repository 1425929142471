import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import referenceid from '../../services/referenceid';
import {
    Grid, Paper, Typography, Tabs, Tab, AppBar, Button, LinearProgress, Collapse
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import Alert from '@material-ui/lab/Alert';
import SenderData from './Components/SenderData'
import RecipientData from './Components/RecipientData';
import ItemData from './Components/ItemData';
import validate from './Components/validateOwnShipment';
import { createStockTransfer, createDetailsRomaneio, closeDetalhesRomaneio } from './Components/OwnShipmentController';

const OutgoingMailing = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [progressBar, setProgressBar] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [deposits, setDeposits] = useState([]);

    const classes = CreateAndViewStyle();

    // Object State
    const [object, setObject] = useState({
        SenderData: {},
        RecipientData: {},
        ItemData: {},
        InvoiceData: { geraNf: true },
        Location: '',
        DadosCultivo: [{ Variedade: '', Talhao: '', Quantidade: '' }],
        ClassificationData: [],
    });
    const [discountKg, setDiscountKg] = useState(0);
    const [item, setItem] = useState({});

    const handleSubmit = async () => {

        const ref2 = referenceid();

        setProgressBar(false);

        const val = validate(object, item);

        if (val.length > 0) {
            setError({ isError: true, errorMessage: val });
            setProgressBar(true);
            return;
        }

        // Gera Transferencia de Estoque
        const invt = await inventoryTransfer(ref2, null);

        if (invt.data.error) {
            //setServerError({ isServerError: true, serverErrorMessage: result.data.error.message.value })
            setError({ isError: true, errorMessage: [invt.data.error.message.value] });

        } else {


            if (invt.data.DocEntry) {
                // Gera Detalhes do Romaneio
                const detailRom = await detalhesRomaneio(ref2, invt.data.DocEntry);
                console.log(detailRom)
                await closeDetalhesRomaneio(detailRom.data.DocEntry)
                if (!detailRom.data.error) {
                    alert('ROMANEIO REGISTRADO COM SUCESSO!');
                    history.push('/home/ownShipment/list');
                } else {
                    setError({ isError: true, errorMessage: [detailRom.data.error.message.value] });
                }
            };
        }



        setProgressBar(true);
        
    }

    const inventoryTransfer = async (refId, NfDocEntry) => {

        let classification = '';

        if (object.ClassificationData) {

            object.ClassificationData.forEach(item => {
                if (item.Valor) {
                    classification += `${item.U_Description}:${item.Valor}, `
                }
            })
        }

        let obj = {
            BPLID: object.SenderData.selectedBranch,
            Reference2: refId,
            FromWarehouse: object.SenderData.selectedDeposit,
            ToWarehouse: object.RecipientData.depositoDestino,
            U_PesoBruto: object.ItemData.pesoBruto,
            U_PesoLiquido: object.ItemData.pesoLiquido,
            U_PesoTara: object.ItemData.pesoTara,
            U_PesoNeto: object.ItemData.pesoNeto,
            U_NumeroTicket: object.ItemData.numeroTicket,
            U_DescontoKg: discountKg,
            Comments: object.ItemData.comments,            
            U_Classificacao: classification ? classification : '',
            JournalMemo: `B2Agri - Remessa para armazem (${refId})`,
            U_B2Obj: 'B2AGRI_ROMREMPROPRIO',
            U_Safra: object.ItemData.safra,
            U_CCG: object.ItemData.ccg,
            U_Motorista: object.SenderData.motorista,
            StockTransferLines: [{
                ItemCode: item.ItemCode,
                WarehouseCode: object.RecipientData.depositoDestino,
                FromWarehouseCode: object.SenderData.selectedDeposit,
                Quantity: object.ItemData.pesoLiquido,
                BatchNumbers: [
                    {
                        BatchNumber: object.ItemData.lote,
                        Quantity: object.ItemData.pesoLiquido
                    }
                ]
            }]
        };

        const result = await createStockTransfer(obj);
        return result;
    }

    const detalhesRomaneio = async (refId, invtDocEntry) => {
        const tempArray = [...object.DadosCultivo]
        const tempClass = [...object.ClassificationData]
        let finalDetails = [];
        let classificationDetails = [];

        tempArray.forEach(itm => {
            if (itm.Variedade && itm.Talhao && itm.Quantidade) {
                finalDetails.push(
                    {
                        U_ItemCode: item.ItemCode,
                        U_Variedade: itm.Variedade,
                        U_Talhao: itm.Talhao,
                        U_Quantidade: itm.Quantidade
                    }
                )
            }
        })

        tempClass.forEach(item => {
            if (item.Valor) {
                classificationDetails.push(
                    {
                        U_Code: item.U_Code,
                        U_Description: item.U_Description,
                        U_Value: item.Valor,
                        U_DescontoKg: item.DescontoKilos
                    }
                )
            }
        })

        const obj = {
            U_TransfCode: invtDocEntry,
            U_UnProdCode: object.Location,
            U_Ref2: refId,
            U_Placa: object.SenderData.placaCaminhao,
            U_UFPlaca: object.SenderData.estadoPlaca,
            U_Transportadora: object.SenderData.transportadora?.CardCode,
            B2ROMANEIODETALHESCollection: finalDetails,
            ROM_ANALISECollection: classificationDetails
        }
        
        const result = await createDetailsRomaneio(obj);
        return result;
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5">
                        Romaneio de Remessa para Armazém Próprio
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados do Remetente" value={1} />
                    <Tab label="Dados do Destinatário" value={2} />
                    <Tab label="Dados do Item" value={3} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <SenderData
                        object={object}
                        setObject={setObject}
                        deposits={deposits}
                        setDeposits={setDeposits}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={2}>
                    <RecipientData
                        object={object}
                        setObject={setObject}
                        deposits={deposits}
                        setDeposits={setDeposits}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={3}>
                    <ItemData
                        object={object}
                        setObject={setObject}
                        discountKg={discountKg}
                        setDiscountKg={setDiscountKg}
                        item={item}
                        setItem={setItem}
                    />
                </TabPanel>
                <Grid item xs={12} sm={6}>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Adicionar
                    </Button>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link} 
                        to={'/home'}

                    >
                        Voltar
                    </Button>

                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    );
}

export default OutgoingMailing;