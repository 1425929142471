import api from '../../services/api.js';

export const findAllWarehouseByLocation = async (ItemCode, LocationCode) => {

    const options = {
        method: 'GET',
        url: `/OperacoesAgricolas/all/${ItemCode}/${LocationCode}`,
        headers: { 'Content-Type': 'application/json' }
    };
  
    return api.request(options).then(function (response) {
  
        return response.data;
    }).catch(function (error) {
  
        console.error(error);
    });
  };

  export const createInventoryGenEntrie = async (inventoryGenEntrieObject) => {

    const options = {
      method: 'POST',
      url: '/InventoryGenEntries/create',
      validateStatus: () => true,
      data: inventoryGenEntrieObject
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      return error;
    });
  };

  export const findAllAnimalBirthsRegisters = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));
  
    const options = {
        method: 'POST',
        url: '/animalBirths/list',
        headers: {'Content-Type': 'application/json'},
        data: branchs
      };
      
      return api.request(options).then(function (response) {
        
        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
  };

  export const findOneDeath = async (id) => {

    const options = {
        method: 'GET',
        url: `/InventoryGenExits/${id}`,
        headers: {'Content-Type': 'application/json'}
      };
      
      return api.request(options).then(function (response) {
  
        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
  };

  export const findOneBirth = async (id) => {

    const options = {
        method: 'GET',
        url: `/InventoryGenEntries/${id}`,
        headers: {'Content-Type': 'application/json'}
      };
      
      return api.request(options).then(function (response) {
  
        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
  };

  export const updateInventoryGenEntrie = async (DocEntry, inventoryGenEntriesObject) => {

    const options = {
      method: 'POST',
      url: `/InventoryGenEntries/update/${DocEntry}`,
      validateStatus: () => true,
      data: inventoryGenEntriesObject
    };
    
    return api.request(options).then(function (response) {
      
      return response;
    }).catch(function (error) {
      
      return error;
    });
  };