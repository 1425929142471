import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findAllAnimalBirthsRegisters } from './AnimalLifeCycleController';
import dateFormat from '../../services/dateFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 90,
    },
    {
        field: 'Reference2',
        headerName: 'Código',
        width: 140,
    },
    {
        field: 'BPLName',
        headerName: 'Filial',
        width: 400,
    },
    {
        field: 'DocDate',
        headerName: 'Data',
        width: 140,
    },
    {
        field: 'ItemCode',
        headerName: 'Item',
        width: 140,
    },
    {
        field: 'ItemDescription',
        headerName: 'Descrição',
        width: 300,
    },
    {
        field: 'Quantity',
        headerName: 'Quantidade',
        width: 200,
    },
    {
        field: 'Comments',
        headerName: 'Observação',
        width: 300,
    }
];

const useStyles = ListDocumentsStyle;

const AnimalBirthRecorsdList = ({ history }) => {

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const result = await findAllAnimalBirthsRegisters();
            
            if (result) {
                let final = result.map((item, index) => {
                    return {
                        id: item.InventoryGenEntries.DocEntry,
                        Reference2: item.InventoryGenEntries.Reference2,
                        DocDate: dateFormat(item.InventoryGenEntries.DocDate),
                        ItemCode: item["InventoryGenEntries/DocumentLines"].ItemCode,
                        ItemDescription: item["InventoryGenEntries/DocumentLines"].ItemDescription,
                        Quantity: item["InventoryGenEntries/DocumentLines"].Quantity,
                        Comments: item.InventoryGenEntries.Comments,
                        BPLName: item.InventoryGenEntries.BPLName,

                    }
                })

                let filtered = final.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.Reference2 === item.Reference2
                    ))
                )

                setRowsData(filtered)
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Registros de Nascimentos de Animais
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}                   
                    onRowClick={(event) => {                        
                        history.push(`/home/animalbirthrecord/view/${event.row.id}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/animalbirthrecord/create'}>
                            Novo Registro de Nascimento de Animais
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default AnimalBirthRecorsdList;