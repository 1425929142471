import api from '../../../services/api.js';

export const findOpenSalesOrders = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

    const options = {
        method: 'POST',
        url: '/OutboundListing/openSalesOrders',
        headers: {'Content-Type': 'application/json'},
        data: branchs
      };
      
      return api.request(options).then(function (response) {
        
        return response.data.value;
      }).catch(function (error) {
        
        console.error(error);        
      });
};
export const findOneSalesOrder = async (DocEntry) => {   

    const options = {
        method: 'GET',
        url: `/OutboundListing/salesOrder/${DocEntry}`,
        headers: {'Content-Type': 'application/json'}
      };
      
      return api.request(options).then(function (response) {
        
        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export const createRomaneioSaida = async (romaneio) => {

  const options = {
    method: 'POST',
    url: '/OutboundListing/createRomaneioSaida',
    validateStatus: () => true,
    data: romaneio
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const updateRomaneioSaida = async (romaneio, DocEntry) => {

  const options = {
    method: 'POST',
    url: `/OutboundListing/updateRomaneioSaida/${DocEntry}`,
    validateStatus: () => true,    
    data: romaneio
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const closeRomaneioSaida = async (DocEntry) => {

  const options = {
    method: 'GET',
    url: `/OutboundListing/closeRomaneioSaida/${DocEntry}`,
    validateStatus: () => true
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const closeDetalhesRomaneio = async (DocEntry) => {

  const options = {
    method: 'GET',
    url: `/DetalhesRomaneio/close/${DocEntry}`,
    validateStatus: () => true
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const findOneRomaneioSaida = async (DocEntry) => {

  const options = {
    method: 'GET',
    url: `/OutboundListing/findOneRomaneioSaida/${DocEntry}`,
    validateStatus: () => true
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const findOutboundListingList = async () => {

  const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS'));

  const options = {
      method: 'POST',
      url: '/OutboundListing/list',
      headers: {'Content-Type': 'application/json'},
      data: branchs
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      return error;        
    });
};

export const createInvoice = async (invoice) => {

  const options = {
    method: 'POST',
    url: '/outboundListing/createInvoice',
    validateStatus: () => true,
    data: invoice
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const findBatchNumbersFromItem = async (ItemCode) => {

  const options = {
      method: 'GET',
      url: `/Items/batchNumbers/${ItemCode}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {
      
      return response.data.value;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findBrachInfo = async (id) => {

  const options = {
      method: 'GET',
      url: `/BusinessPlaces/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findItemByCode = async (id) => {

  const options = {
      method: 'GET',
      url: `/Items/${id}`,
      headers: { 'Content-Type': 'application/json' }
  };

  return api.request(options).then(function (response) {

      return response.data;
  }).catch(function (error) {

      console.error(error);
  });
};

export const findBPByCode = async (id) => {

  const options = {
      method: 'GET',
      url: `/BusinessPartners/${id}`,
      headers: { 'Content-Type': 'application/json' }
  }

  return api.request(options).then(function (response) {

      return response.data;
  }).catch(function (error) {

      console.error(error);
  })
}

export const findInvoice = async (id) => {

  const options = {
      method: 'GET',
      url: `/Invoices/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

