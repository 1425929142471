import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import referenceid from '../../services/referenceid';
import {
    Grid, Paper, Typography, Tabs, Tab, AppBar, Button, LinearProgress, Collapse
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import Alert from '@material-ui/lab/Alert';
import SenderData from './Components/SenderData'
import RecipientData from './Components/RecipientData';
import ItemData from './Components/ItemData';
import InvoiceData from './Components/InvoiceData';
import validate from './Components/validateOutgoingMailing';
import { createStockTransfer, createInvoice, createDetailsRomaneio, closeDetalhesRomaneio } from './Components/OutgoingMailingController';

const OutgoingMailing = ({ history }) => {

    const [tabs, setTabs] = useState(1);
    const [progressBar, setProgressBar] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: [] });
    const [inStock, setInStock] = useState(0);
    const classes = CreateAndViewStyle();

    // Object State
    const [object, setObject] = useState({
        SenderData: {},
        RecipientData: {},
        ItemData: {},
        InvoiceData: { geraNf: true },
        Location: '',
        DadosCultivo: [{ Variedade: '', Talhao: '', Quantidade: '' }]
    });

    const handleSubmit = async () => {
        
        const ref2 = referenceid();

        setProgressBar(false);
        console.log(object)
        const val = validate(object);

        if (val.length > 0) {
            setError({ isError: true, errorMessage: val });
            setProgressBar(true);
            return;
        }
        // Se Gerar Documento Fiscal estiver ativo
        if (object.InvoiceData.geraNf) {

            if(inStock >= object.ItemData.pesoLiquido) {

                // Gera Nf
                const nf = await invoiceOut(ref2);
    
                if(nf.data.error) {
                    setError({ isError: true, errorMessage: [nf.data.error.message.value] });
                }
    
                if (nf.data.DocEntry) {
                    // Gera Transferencia de Estoque
                    const invt = await inventoryTransfer(ref2, nf.data.DocEntry);
    
                    if(invt.data.error) {
                        setError({ isError: true, errorMessage: [invt.data.error.message.value] });
                    }
    
                    if (invt.data.DocEntry) {
                        // Gera Detalhes do Romaneio
                        const detailRom = await detalhesRomaneio(ref2, invt.data.DocEntry);
    
                        if (!detailRom.data.error) {
                            await closeDetalhesRomaneio(detailRom.data.DocEntry);
                            alert('ROMANEIO REGISTRADO COM SUCESSO!');
                            history.push('/home/outgoingMailing/list');
                        };
                    };
                }
            } else {
                setError({ isError: true, errorMessage: ['Saldo em estoque insuficiente'] });
            }

            // Se Gerar Documento Fiscal estiver inativo
        } else {
            // Gera Transferencia de Estoque
            const invt = await inventoryTransfer(ref2, null);
            
            if(invt.data.error) {
                setError({ isError: true, errorMessage: [invt.data.error.message.value] });
            }

            if (invt.data.DocEntry) {
                // Gera Detalhes do Romaneio
                const detailRom = await detalhesRomaneio(ref2, invt.data.DocEntry);
                await closeDetalhesRomaneio(detailRom.data.DocEntry);

                if (!detailRom.data.error) {
                    alert('ROMANEIO REGISTRADO COM SUCESSO!');
                    setObject({
                        SenderData: {},
                        RecipientData: {},
                        ItemData: {},
                        InvoiceData: {},
                        Location: '',
                        DadosCultivo: [{ Variedade: '', Talhao: '', Quantidade: '' }]
                    })
                    setTabs(1)
                } else {
                    setError({ isError: true, errorMessage: [detailRom.data.error.message.value] });
                };
            };
        }

        setProgressBar(true);        

    }

    const inventoryTransfer = async (refId, NfDocEntry) => {

        let classification = '';

        if (object.ClassificationData) {

            object.ClassificationData.forEach(item => {
                if (item.Valor) {
                    classification += `${item.U_Description}:${item.Valor}, `
                }
            })
        }

        let obj = {
            BPLID: object.SenderData.selectedBranch,
            DocDate: object.InvoiceData.dataNota,
            CardCode: object.RecipientData.destinatario.CardCode,
            Reference2: refId,
            FromWarehouse: object.SenderData.selectedDeposit,
            ToWarehouse: object.RecipientData.depositoDestino,
            U_PesoBruto: object.ItemData.pesoBruto,
            U_PesoLiquido: object.ItemData.pesoLiquido,
            U_PesoTara: object.ItemData.pesoTara,
            U_PesoNeto: object.ItemData.pesoNeto,
            U_NumeroTicket: object.ItemData.numeroTicket,
            U_DescontoKg: object.ItemData.descontoKilos,
            Comments: object.ItemData.comments,
            U_Motorista: object.InvoiceData.motorista,
            U_Classificacao: classification ? classification : '',
            JournalMemo: `B2Agri - Remessa para armazem (${refId})`,
            U_B2Obj: 'B2AGRI_ROMREMARMAZEM',
            U_Safra: object.ItemData.safra,
            U_CCG: object.ItemData.ccg,
            StockTransferLines: [{
                ItemCode: object.ItemData.item.ItemCode,
                WarehouseCode: object.RecipientData.depositoDestino,
                FromWarehouseCode: object.SenderData.selectedDeposit,
                Quantity: object.ItemData.pesoLiquido,
                BatchNumbers: [
                    {
                        BatchNumber: object.ItemData.lote,
                        Quantity: object.ItemData.pesoLiquido
                    }
                ]
            }]
        };

        if (NfDocEntry) {
            obj.DocumentReferences = [
                {
                    RefDocEntr: NfDocEntry,
                    RefObjType: 'rot_DeliveryNotes'
                }
            ]
        }
        console.log(object)
        const result = await createStockTransfer(obj);
        console.log(result)
        return result;
    }

    const detalhesRomaneio = async (refId, invtDocEntry) => {
        const tempArray = [...object.DadosCultivo]
        const tempClass = [...object.ClassificationData]
        let finalDetails = [];
        let classificationDetails = [];

        tempArray.forEach(item => {
            if (item.Variedade && item.Talhao && item.Quantidade) {
                finalDetails.push(
                    {
                        U_ItemCode: object.ItemData.item.ItemCode,
                        U_Variedade: item.Variedade,
                        U_Talhao: item.Talhao,
                        U_Quantidade: item.Quantidade
                    }
                )
            }
        })

        tempClass.forEach(item => {
            if (item.Valor) {
                classificationDetails.push(
                    {
                        U_Code: item.Defeito.U_Code,
                        U_Description: item.Defeito.U_Description,
                        U_Value: item.Valor                        
                    }
                )
            }
        })

        const obj = {
            U_TransfCode: invtDocEntry,
            U_UnProdCode: object.Location,
            U_Ref2: refId,
            B2ROMANEIODETALHESCollection: finalDetails,
            ROM_ANALISECollection: classificationDetails
        }
        console.log(obj)
        const result = await createDetailsRomaneio(obj);
        console.log(result)
        return result;
    }

    const invoiceOut = async (refId) => {

        const obj = {
            BPL_IDAssignedToInvoice: object.SenderData.selectedBranch,
            DocDate: object.InvoiceData.dataNota,
            CardCode: object.RecipientData.destinatario.LinkedBusinessPartner,
            Reference2: refId,
            U_ChaveAcesso: object.InvoiceData.chaveAcesso,
            Carrier: object.InvoiceData.transportadora?.CardCode,
            JournalMemo: `B2Agri - Remessa para armazem (${refId})`,
            U_B2Obj: 'B2AGRI_ROMREMARMAZEM',
            SequenceSerial: object.InvoiceData.nf,
            SeriesString: object.InvoiceData.serie,
            SequenceCode: object.InvoiceData.sequenceCode,
            SequenceModel: object.InvoiceData.model,
            U_Motorista: object.InvoiceData.motorista,
            U_Safra: object.ItemData.safra,
            U_CCG: object.ItemData.ccg,
            TaxExtension: {
                Incoterms: object.InvoiceData.incoterms,
                Vehicle: object.InvoiceData.placaCaminhao,
                VehicleState: object.InvoiceData.estadoPlaca

            },
            DocumentLines: [
                {
                    ItemCode: object.ItemData.item.ItemCode,
                    Quantity: object.InvoiceData.quantidadeNota,
                    UnitPrice: '',
                    WarehouseCode: object.SenderData.dropshipWarehouse.WarehouseCode,
                    LineTotal: object.InvoiceData.valorNota,
                    Usage: 17,
                    BatchNumbers: [
                        {
                            BatchNumber: object.ItemData.lote,
                            Quantity: object.InvoiceData.quantidadeNota
                        }
                    ]
                }
            ]
        }
        console.log(obj)
        const result = await createInvoice(obj);
        console.log(result)
        return result;
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5">
                        Romaneio de Remessa para Armazém de Terceiros
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados do Remetente" value={1} />
                    <Tab label="Dados do Destinatário" value={2} />
                    <Tab label="Dados do Item" value={3} />
                    <Tab label="Documento Fiscal" value={4} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <SenderData
                        object={object}
                        setObject={setObject}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={2}>
                    <RecipientData
                        object={object}
                        setObject={setObject}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={3}>
                    <ItemData
                        object={object}
                        setObject={setObject}
                        inStock={inStock}
                        setInStock={setInStock}
                    />
                </TabPanel>
                <TabPanel value={tabs} index={4}>
                    <InvoiceData
                        object={object}
                        setObject={setObject}
                    />
                </TabPanel>
                <Grid item xs={12} sm={6}>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Adicionar
                    </Button>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link}
                        to={'/home'}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    );
}

export default OutgoingMailing;