import api from '../../services/api.js';

export const getListItems = async (location, greaterThenZero) => {
    
    const options = {
        method: 'POST',
        url: '/Reports/ListItem',
        headers: {'Content-Type': 'application/json'},
        data: {
          location: location,
          greaterThenZero: greaterThenZero
        }
      };
      
      return api.request(options).then(function (response) {
        
        return response.data;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export const getHarvestReport = async (params) => {
  
  const options = {
      method: 'POST',
      url: '/Reports/HarvestSummary',
      headers: {'Content-Type': 'application/json'},
      data: params
    };
    
    return api.request(options).then(function (response) {
      
      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
    
};