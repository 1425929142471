import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const MainTheme = createMuiTheme({
  palette: {
    primary: {
      light: 'rgb(28, 73, 100)',
      main: '#305E79',
      dark: '#rgb(137, 186, 212)',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
}, ptBR);

export default MainTheme;