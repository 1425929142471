import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Typography, Grid, MenuItem, FormControl, InputLabel, Select, Button, LinearProgress } from '@material-ui/core';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';
import { WarehouseLocations, Safra, Cultura } from '../DefaultController';
import { getHarvestReport } from './ReportsController';
import { v4 as uuid } from 'uuid';

const useStyles = ListDocumentsStyle;

const HarvestSummaryReport = () => {

    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('ALL');
    const [safraList, setSafraList] = useState([]);
    const [selectedSafra, setSelectedSafra] = useState(1);
    const [culturaList, setCulturaList] = useState([]);
    const [selectedCultura, setSelectedCultura] = useState('ALL');
    const [progressBar, setProgressBar] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {

            const loc = await WarehouseLocations()
            setLocationsList(loc);
        };
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {

            const sf = await Safra()
            setSafraList(sf)
        };
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {

            const ct = await Cultura()
            setCulturaList(ct)
        };
        fetchData();
    }, []);

    const handleSearch = async () => {
        setProgressBar(false);

        const result = await getHarvestReport({
            safra: selectedSafra,
            location: selectedLocation === 'ALL' ? '' : selectedLocation,
            culture: selectedCultura === 'ALL' ? '' : selectedCultura
        });

        if (result) {

            const link = `data:application/pdf;base64,${result}`;
            fetch(link)
                .then(res => res.blob())
                .then((blob) => {

                    const fileURL = window.URL.createObjectURL(blob);
                    window.open(fileURL);
                })
        }

        setProgressBar(true)
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Resumo de Colheita
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="safra-select-input">Safra</InputLabel>
                            <Select
                                fullWidth
                                labelId="safra-select-input"
                                id="safra-select"
                                label="Safra"
                                defaultValue=""
                                value={selectedSafra}
                                onChange={(event) => {
                                    setSelectedSafra(event.target.value)
                                }}
                            >
                                {safraList.map(item => {
                                    return (
                                        <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                    );
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Localização</InputLabel>
                            <Select
                                fullWidth
                                labelId="localizacao"
                                id="localizacao"
                                label="Localização"
                                value={selectedLocation}
                                onChange={(event) => {
                                    setSelectedLocation(event.target.value)
                                }}
                            >
                                <MenuItem value={'ALL'} key={uuid()}>{'TODAS'}</MenuItem>
                                {locationsList.map(location => {
                                    return (
                                        <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="culturaInput">Cultura</InputLabel>
                            <Select
                                labelId="culturaInput"
                                label="Cultura"
                                value={selectedCultura}
                                onChange={event => { setSelectedCultura(event.target.value) }}
                            >
                                <MenuItem value={'ALL'} key={uuid()}>{'TODAS'}</MenuItem>
                                {culturaList.map(culture => {
                                    return (
                                        <MenuItem value={culture.U_ItemCode} key={uuid()}>{culture.Name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Gerar
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            component={Link}
                            to={'/home'}
                        >
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress hidden={progressBar} />
                    </Grid>
                </Grid>

            </Paper>
        </>
    )
}

export default HarvestSummaryReport;