import React, { useState, useEffect } from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import { findWarehouseFromBranchAndBP } from './OwnShipmentController';

const RecipientData = (props) => {

    const classes = CreateAndViewStyle();

    const handleBPSelection = (value) => {
        props.setObject({
            ...props.object,
            RecipientData: {
                ...props.object.RecipientData,
                destinatario: value
            }
        })
    }

    const resetRecipientData = () => {
        props.setObject({
            ...props.object,
            RecipientData: {}
        })
    }

    useEffect(() => {
        async function fetchData() {

            if (props.object.SenderData.selectedBranch && props.object.RecipientData.destinatario) {
                const whs = await findWarehouseFromBranchAndBP(props.object.SenderData.selectedBranch, props.object.RecipientData.destinatario.CardCode);
                if (!whs) {
                    alert('NÃO EXISTE DEPÓSITO.')
                }
                props.setObject({
                    ...props.object,
                    RecipientData: {
                        ...props.object.RecipientData,
                        depositoDestino: whs ? whs[0]?.WarehouseCode : ''
                    }
                })
            }

        };

        fetchData();
    }, [props.object.SenderData.selectedBranch, props.object.RecipientData.destinatario]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        id="destinatario"
                        label="Destinatário"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.object.SenderData.branchInfo ?
                            props.object.SenderData.branchInfo.BPLName
                            : ''
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Depósito</InputLabel>
                        <Select
                            labelId="deposito"
                            id="deposito"
                            label="Depósito"
                            value={props.object.RecipientData.depositoDestino}
                            defaultValue=""
                            onChange={event => {
                                props.setObject({
                                    ...props.object,
                                    RecipientData: {
                                        ...props.object.RecipientData,
                                        depositoDestino: event.target.value
                                    }
                                })
                            }}
                        >
                            {
                                props.deposits.map(dep => {
                                    return (
                                        <MenuItem value={dep.WarehouseCode}>{dep.WarehouseName}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>               
                <Grid item xs={2}>
                    <TextField
                        id="tipo"
                        label="Tipo"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.recipientType === 1 ?
                            props.object.RecipientData.destinatario?.BPAddresses[1].TypeOfAddress || ''
                            : props.object.SenderData.branchInfo?.AddressType || ''
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="logradouro"
                        label="Logradouro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.recipientType === 1 ?
                            props.object.RecipientData.destinatario?.BPAddresses[1].Street || ''
                            : props.object.SenderData.branchInfo?.Street || ''
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="numero"
                        label="Número"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.recipientType === 1 ?
                            props.object.RecipientData.destinatario?.BPAddresses[1].StreetNo || ''
                            : props.object.SenderData.branchInfo?.StreetNo || ''
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="bairro"
                        label="Bairro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.recipientType === 1 ?
                            props.object.RecipientData.destinatario?.BPAddresses[1].Block || ''
                            : props.object.SenderData.branchInfo?.Block || ''
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="cidade"
                        label="Cidade"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.recipientType === 1 ?
                            props.object.RecipientData.destinatario?.BPAddresses[1].City || ''
                            : props.object.SenderData.branchInfo?.City || ''
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="cep"
                        label="CEP"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.recipientType === 1 ?
                            props.object.RecipientData.destinatario?.BPAddresses[1].ZipCode || ''
                            : props.object.SenderData.branchInfo?.ZipCode || ''
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default RecipientData;