import api from '../../services/api.js';

export const findAll = async () => {

   
    const options = {
        method: 'GET',
        url: '/ServiceCalls',
        headers: {'Content-Type': 'application/json'}
      };
      
      return api.request(options).then(function (response) {
        
        return response.data.value;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export const findOne = async (id) => {

  const options = {
      method: 'GET',
      url: `/ServiceCalls/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const create = async (serviceCallsObject) => {

  const options = {
    method: 'POST',
    url: '/ServiceCalls/create',
    validateStatus: () => true,
    data: serviceCallsObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const update = async (id, serviceCallsObject) => {

  const options = {
      method: 'PATCH',
      url: `/ServiceCalls/${id}`,
      headers: {'Content-Type': 'application/json'},
      data: serviceCallsObject
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const findServiceCallItens = async (id) => {

  const options = {
      method: 'GET',
      url: `/ServiceCalls/Items/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const createServiceCallItens = async (items) => {

  const options = {
    method: 'POST',
    url: '/ServiceCalls/Items/create',
    validateStatus: () => true,
    data: items
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const updateServiceCallItens = async (id, items) => {

  const options = {
      method: 'PATCH',
      url: `/ServiceCalls/Items/${id}`,
      headers: {'Content-Type': 'application/json'},
      data: items
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};
