import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { searchByPartDescriptionConsumeItens } from './ItemsController';

const ItemsAutocomplete = (props) => {

    const width = props.width ? props.width : 300;
    const [listItems, setListItems] = useState([]);
    const [addInputValue, setAddInputValue] = useState('');

    const handleChangeFilterOption = (event, newInputValue) => {

        let searchMethod = searchByPartDescriptionConsumeItens; 
        
        setAddInputValue(newInputValue.toUpperCase());

        if (newInputValue.length >= 3) {
            searchMethod(newInputValue.toUpperCase())
                .then((result => {
                    if (result) {
                        setListItems(result)
                    }
                }))
        } else {
            setListItems([])
        }
    }
 
    const handleSelect = (value) => {
        props.setInputItem(value, props.idx);
        if(props.onExecute)
            props.onExecute(value, props.idx);
    }

    return (
        <>
            <Autocomplete
                style={{ width: width }}
                value={props.addItemField}
                getOptionSelected={(option, value) => {
                    return option.ItemCode === value.ItemCode;
                }}
                getOptionLabel={(option) => option.ItemCode ? option.ItemCode + ' - ' + option.ItemName : ''}
                onChange={(event, value) => { handleSelect(value) }}
                inputValue={addInputValue}
                onInputChange={handleChangeFilterOption}                
                options={listItems} 
                fullWidth
                size="small"
                renderInput={(params) => <TextField {...params} label={props.label}  variant="outlined" />}
            />
        </>
    );
}

export default ItemsAutocomplete;