import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport, gridClasses } from '@mui/x-data-grid';
import DataGridTexts from './DataGridTexts';
import './styles.css';
import { Paper, Table, TableHead, TableRow, TableCell, TableContainer, Typography } from '@material-ui/core';


const CustomToolbar = () => {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
        </GridToolbarContainer>
    );
}

const CustomDataGrid = (props) => {

    const [sum, setSum] = useState(0);

    const Summary = (data) => {

        if (props.sumField) {

            if (!data.visibleRows.visibleRows) {
              
                const totalSum = Object.keys(data.rows.idRowsLookup).reduce((previous, key) => {
                    return previous + data.rows.idRowsLookup[key].[props.sumField];
                }, 0);

                setSum(totalSum);
            } else {
                console.log('Filtrou')
                const totalSum = data.visibleRows.visibleRows.reduce((previous, key) => {
                    return previous + data.rows.idRowsLookup[key].[props.sumField];
                }, 0);

                setSum(totalSum);
            }
        }

    }

    return (
        <div style={{ height: props.height ?? 450, width: '100%' }}>
            <DataGrid
                rows={props.rows}
                columns={props.columns}
                disableSelectionOnClick
                pageSize={10}
                pageSizeOptions={[5, 10, 25, 50]}
                exportButton={true}
                emptyRowsWhenPaging={true}
                components={{
                    Toolbar: CustomToolbar,                    
                }}
                density={'compact'}
                onRowClick={props.onRowClick}
                localeText={DataGridTexts}
                onStateChange={Summary}

            />
            {
                props.sumField &&
                <Paper>
                    <TableContainer component={Paper}>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right"><Typography variant="subtitle1">{props.sumFieldText}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle1"></Typography>{sum}</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                </Paper>
            }
        </div>
    )
}

export default CustomDataGrid;