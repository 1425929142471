import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid, MenuItem, FormControl, InputLabel, Select, Button, LinearProgress } from '@material-ui/core';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';
import DataGrid from '../../components/DataGrid';
import { getListItems } from './ReportsController';
import { WarehouseLocations } from '../DefaultController';

const useStyles = ListDocumentsStyle;

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 90,
    },
    {
        field: 'ItemCode',
        headerName: 'Código',
        width: 200,
    },
    {
        field: 'ItemName',
        headerName: 'Descrição',
        width: 400,
    },
    {
        field: 'InventoryUOM',
        headerName: 'U.M.',
        width: 150,
    },
    {
        field: 'WarehouseCode',
        headerName: 'Depósito',
        width: 150,
    },
    {
        field: 'Quantity',
        headerName: 'Quantidade',
        type: 'number',
        width: 150,
    }
];

const ItemListReport = () => {

    const [rowsData, setRowsData] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [greaterThenZero, setGreaterThenZero] = useState('Y');
    const [progressBar, setProgressBar] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {

            const loc = await WarehouseLocations()
            setLocationsList(loc);
        };

        fetchData();

    }, []);

    const handleSearch = async () => {
        setProgressBar(false);
        if (selectedLocation && greaterThenZero) {

            const result = await getListItems(selectedLocation, greaterThenZero);
            
            if (result) {
                let final = result.map((item, index) => {
                    return {
                        id: index,
                        ItemCode: item.Items.ItemCode,
                        ItemName: item.Items.ItemName,
                        InventoryUOM: item.Items.InventoryUOM,
                        WarehouseCode: item["Items/ItemWarehouseInfoCollection"].WarehouseCode,
                        Quantity: item["Items/ItemWarehouseInfoCollection"].InStock
                    }
                })

                setRowsData(final)
            }
        }
        setProgressBar(true)
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Lista de Itens
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Localização</InputLabel>
                            <Select
                                fullWidth
                                labelId="localizacao"
                                id="localizacao"
                                label="Localização"
                                value={selectedLocation}
                                onChange={(event) => {
                                    setSelectedLocation(event.target.value)
                                }}
                            >
                                {locationsList.map(location => {
                                    return (
                                        <MenuItem value={location.Code}>{location.Name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="zero">Oculta Saldos Zerados</InputLabel>
                            <Select
                                labelId="Zero"
                                id="zero"
                                label="Oculta Saldos Zerados"
                                value={greaterThenZero}
                                defaultValue=""
                                onChange={(event) => {
                                    setGreaterThenZero(event.target.value)
                                }}
                            >
                                <MenuItem value={'Y'}>Sim</MenuItem>
                                <MenuItem value={'N'}>Não</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Buscar
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress hidden={progressBar} />
                    </Grid>
                </Grid>
                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    onRowClick={(event) => {
                        console.log(event.row.id)
                    }}
                />
            </Paper>
        </>
    )
}

export default ItemListReport;