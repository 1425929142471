import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { searchByPartDescription } from './BusinessPartnerController';

const BusinessPartnerAutocomplete = (props) => {

    const width = props.width ? props.width : 300;
    const [listItems, setListItems] = useState([]);
    const [addInputValue, setAddInputValue] = useState('');

    const handleChangeFilterOption = (event, newInputValue) => {

        const searchMethod = searchByPartDescription;

        setAddInputValue(newInputValue.toUpperCase());

        if (newInputValue.length >= 3) {
            searchMethod(newInputValue.toUpperCase(), props.type)
                .then((result => {
                    if (result) {
                        setListItems(result)
                    }
                }))
        } else {
            setListItems([])
        }
    }

    const handleSelection = async (value) => {

        if (value) {
            props.setInputItem(value);
        }
    }

    return (
        <>
            <Autocomplete
                style={{ width: width }}
                value={props.addItemField}
                getOptionSelected={(option, value) => {
                    return option.CardCode === value.CardCode;
                }}
                getOptionLabel={(option) => option.CardCode ? option.CardCode + ' - ' + option.CardName + (option.FederalTaxID ? ` (${option.FederalTaxID})` : '') : ''}
                onChange={(event, value) => { handleSelection(value) }}
                inputValue={addInputValue}
                onInputChange={handleChangeFilterOption}
                options={listItems}
                fullWidth
                size="small"                
                renderInput={(params) => <TextField {...params} variant="outlined" label={props.label} />}
            />
        </>
    );
}

export default BusinessPartnerAutocomplete;