import api from '../../services/api.js'

export const readAll = async () => {

    const options = {
        method: 'GET',
        url: '/BusinessPartners',
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    })
}

export const findByCode = async (id) => {

    const options = {
        method: 'GET',
        url: `/BusinessPartners/${id}`,
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    })
}

export const searchByDescription = async (description) => {

    const options = {
        method: 'GET',
        url: `/BusinessPartners/search/${description}`,
        headers: { 'Content-Type': 'application/json' }
    }

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    })
}

export const create = async (itemObject) => {
    const options = {
        method: 'POST',
        url: '/BusinessPartners',
        data: itemObject
    }

    return api.request(options).then((response) => {
        return response.data
    }).catch((error) => {
        return error
    })
}

export const BusinessPartnerGroups = async (type) => {

    const options = {
        method: 'GET',
        url: `/BusinessPartnerGroups/${type}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(response => {
        return response.data.value
    }).catch((error) => {
        console.error(error)
    })
}
