import api from '../../services/api';

export const getDanfe = async (APIToken, APISecret, CpfCnpj, Modelo, Ano, Numero, Serie, Ambiente) => {

  const options = {
    method: 'POST',
    url: '/oobj/getDanfe',
    validateStatus: () => true,
    data: {APIToken, APISecret, CpfCnpj, Modelo, Ano, Numero, Serie, Ambiente},
    responseType: 'arraybuffer'
  };

  return api.request(options).then(function (response) {

    return response.data;
  }).catch(function (error) {
    return error;
  });

}
export const getXml = async (APIToken, APISecret, CpfCnpj, Modelo, Ano, Numero, Serie, Ambiente) => {

  const options = {
    method: 'POST',
    url: '/oobj/getXml',
    validateStatus: () => true,
    data: {APIToken, APISecret, CpfCnpj, Modelo, Ano, Numero, Serie, Ambiente}
  };

  return api.request(options).then(function (response) {

    return response.data;
  }).catch(function (error) {
    return error;
  });

}