import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findAllFuelAndLubRegisters, getEmployeesList } from '../InventoryGenExits/InventoryGenExitsController';
import dateFormat from '../../services/dateFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 90,
    },
    {
        field: 'Reference2',
        headerName: 'Código',
        width: 140,
    },
    {
        field: 'DocDate',
        headerName: 'Data',
        width: 140,
    },
    {
        field: 'U_TipoAbastecimento',
        headerName: 'Tipo',
        width: 150,
    },
    {
        field: 'U_Equipamento',
        headerName: 'Equipamento',
        width: 300,
    },
    {
        field: 'U_Operador',
        headerName: 'Operador',
        width: 200,
    },
    {
        field: 'ItemCode',
        headerName: 'Item',
        width: 140,
    },
    {
        field: 'ItemDescription',
        headerName: 'Descrição',
        width: 300,
    },
    {
        field: 'Quantity',
        headerName: 'Quantidade',
        width: 200,
    },
    {
        field: 'JournalMemo',
        headerName: 'Observação',
        width: 600,
    }
];

const useStyles = ListDocumentsStyle;

const WarehouseExitsList = ({ history }) => {

    const [rowsData, setRowsData] = useState([]);
    const [employeesList, setEmployeesList] = useState([])
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const emp = await getEmployeesList();

            setEmployeesList(emp); 
            const result = await findAllFuelAndLubRegisters();
            
            if (result) {
                let final = result.map((item, index) => {

                    const oper = employeesList.find( employ => parseInt(employ.EmployeeID) === parseInt(item.InventoryGenExits?.U_Operador) );

                    return {
                        id: index,
                        Reference2: item.InventoryGenExits.Reference2,
                        DocDate: dateFormat(item.InventoryGenExits.DocDate),
                        U_TipoAbastecimento: mapType(item.InventoryGenExits.U_TipoAbastecimento),
                        U_Equipamento: item.InventoryGenExits.U_Equipamento,
                        U_Operador: oper ? oper.FirstName : '',
                        ItemCode: item["InventoryGenExits/DocumentLines"].ItemCode,
                        ItemDescription: item["InventoryGenExits/DocumentLines"].ItemDescription,
                        Quantity: item["InventoryGenExits/DocumentLines"].Quantity,
                        JournalMemo: `${item.InventoryGenExits.JournalMemo} - ${item.InventoryGenExits.Comments}`

                    }
                })

                let filtered = final.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.Reference2 === item.Reference2
                    ))
                )

                setRowsData(filtered)
            }
        };

        fetchData();
    }, [employeesList]);

    const mapType = (type) => {
        switch(type) {
            case 'P': return 'Próprio';
            case 'T': return 'Terceiro';
            case 'C': return 'Colaborador';
            default: return '';
        }
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Abastecimentos / Lubrificações
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}                    
                    onRowClick={(event) => {                        
                        history.push(`/home/fuelAndLubrification/view/${event.row.Reference2}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/fuelAndLubrification/create'}>
                            Novo Abastecimento/Lubrificação
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default WarehouseExitsList;