import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { create } from './BusinessPartnersController'
import DadosGerais from './DadosGerais'
import EnderecosList from './EnderecosList'
import PessoasContatoList from './PessoasContatoList'
import { Grid, Paper, Button, Typography, Collapse, LinearProgress, Box, AppBar, Tabs, Tab } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { fade } from '@material-ui/core/styles/colorManipulator'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(0),
    },
    paperinternal: {
        padding: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
    },
    table: {
    },
    title: {
        background: fade('#000000', 0.3)
    },
    whitebold: {
        fontWeight: 'bold',
        color: '#FFF'
    },
    button: {
        marginRight: '5px',
    }
}))

const BusinessPartnersCreate = ({ history }) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const [message, setMessage] = useState(false)
    const [mensagemAlert, setMessageAlert] = useState('')
    const [progressBar, setProgressBar] = useState(true)

    const [listType] = useState([{ Name: 'Cliente', CardType: 'cCustomer' }, { Name: 'Fornecedor', CardType: 'cSupplier' }])
    const [selectedType, setSelectedType] = useState({})
    const [listSerie, setListSerie] = useState([])
    const [selectedSerie, setSelectedSerie] = useState({ Name: '', CardType: '' })
    const [listPartnersGroup, setListPartnersGroup] = useState([])
    const [selectedPartnersGroup, setSelectedPartnersGroup] = useState({})

    const [dadosGerais, setDadosGerais] = useState({
        CardType: '',
        Series: '',
        CardCode: '',
        GroupCode: '',
        CardName: '',
        AliasName: '',
        Phone1: '',
        Phone2: '',
        Cellular: '',
        EmailAddress: '',
        CpfCnpj: '',
        IE: ''
    })
    const [listEnderecoEntrega, setListEnderecoEntrega] = useState([])
    const [listEnderecoCobranca, setListEnderecoCobranca] = useState([])
    const [listPessoaContato, setListPessoaContato] = useState([])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleSubmit = async () => {

        setProgressBar(false)

        console.log(dadosGerais.CpfCnpj.length)
        let BPFiscalTaxIDCollectionRequest = []
        if (dadosGerais.CpfCnpj) {
            if (dadosGerais.CpfCnpj.length === 14) {
                BPFiscalTaxIDCollectionRequest.push({
                    TaxId4: dadosGerais.CpfCnpj,
                    TaxId1: dadosGerais.IE,
                    AddrType: 'bo_ShipTo'
                })
            }
            else if (dadosGerais.CpfCnpj.length === 18) {
                BPFiscalTaxIDCollectionRequest.push({
                    TaxId0: dadosGerais.CpfCnpj,
                    TaxId1: dadosGerais.IE,
                    AddrType: 'bo_ShipTo'
                })
            }
        }

        /*Cliente: Grava comente no endereço de entrega e quando informar a IE, CNPJ pega na guia Dados Gerais.*/
        if (selectedType.CardType === 'cCustomer') {
            listEnderecoEntrega.forEach(entrega => {
                if (dadosGerais.CpfCnpj) {
                    if (dadosGerais.CpfCnpj.length === 14) {
                        BPFiscalTaxIDCollectionRequest.push({
                            Address: entrega.AddressName,
                            TaxId4: dadosGerais.CpfCnpj,
                            TaxId1: entrega.Padrao ? dadosGerais.IE : entrega.U_IE,
                            AddrType: 'bo_ShipTo'
                        })
                    }
                    else if (dadosGerais.CpfCnpj.length === 18) {
                        BPFiscalTaxIDCollectionRequest.push({
                            Address: entrega.AddressName,
                            TaxId0: dadosGerais.CpfCnpj,
                            TaxId1: entrega.Padrao ? dadosGerais.IE : entrega.U_IE,
                            AddrType: 'bo_ShipTo'
                        })
                    }
                }
            })
        }

        const dadosGeraisRequest = {
            CardType: dadosGerais.CardType,
            Series: dadosGerais.Series,
            CardCode: dadosGerais.CardCode,
            GroupCode: dadosGerais.GroupCode,
            CardName: dadosGerais.CardName,
            AliasName: dadosGerais.AliasName,
            Phone1: dadosGerais.Phone1,
            Phone2: dadosGerais.Phone2,
            Cellular: dadosGerais.Cellular,
            EmailAddress: dadosGerais.EmailAddress,
            U_IE: null
        }

        console.log(selectedType.CardType)
        let listEnderecoCobrancaRequest = []
        listEnderecoCobranca.forEach(item => {
            listEnderecoCobrancaRequest.push({
                AddressName: item.AddressName,
                TypeOfAddress: item.TypeOfAddress,
                Street: item.Street,
                StreetNo: item.StreetNo,
                ZipCode: item.ZipCode,
                Block: item.Block,
                City: item.City,
                State: item.State,
                Country: item.Country,
                AddressType: item.AddressType,
                U_IE: selectedType.CardType === 'cSupplier' ? item.U_IE : null
            })
        })

        let listEnderecoEntregaRequest = []
        listEnderecoEntrega.forEach(item => {
            listEnderecoEntregaRequest.push({
                AddressName: item.AddressName,
                TypeOfAddress: item.TypeOfAddress,
                Street: item.Street,
                StreetNo: item.StreetNo,
                ZipCode: item.ZipCode,
                Block: item.Block,
                City: item.City,
                State: item.State,
                Country: item.Country,
                AddressType: item.AddressType,
                U_IE: null
            })
        })

        let listPessoaContatoRequest = []
        listPessoaContato.forEach(item => {
            listPessoaContatoRequest.push({
                Name: item.Name,
                FirstName: item.FirstName,
                Title: item.Title,
                MobilePhone: item.MobilePhone,
                E_Mail: item.E_Mail
            })
        })

        const newItem = {
            CardType: selectedType.CardType,
            Series: selectedSerie.Series,
            CardCode: dadosGeraisRequest.CardCode,
            GroupCode: selectedPartnersGroup.Code,
            CardName: dadosGeraisRequest.CardName,
            AliasName: dadosGeraisRequest.AliasName,
            Phone1: dadosGeraisRequest.Phone1,
            Phone2: dadosGeraisRequest.Phone2,
            Cellular: dadosGeraisRequest.Cellular,
            EmailAddress: dadosGeraisRequest.EmailAddress,
            BPAddresses: listEnderecoEntregaRequest.concat(listEnderecoCobrancaRequest),
            ContactEmployees: listPessoaContatoRequest,
            BPFiscalTaxIDCollection: BPFiscalTaxIDCollectionRequest
        }

        console.log(newItem)
        //return

        const result = await create(newItem)
        setProgressBar(true)
        console.log(result)

        if (!result.ErroMsg) {
            history.push('/home')
        } else {
            setMessageAlert(result.ErroMsg)
            setMessage(true)
        }
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Cadastro do Parceiro de Negócios - Adicionar
                    </Typography>
                </Grid>
            </Paper>

            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Dados Gerais" {...a11yProps(0)} />
                    <Tab label="Endereços" {...a11yProps(1)} />
                    <Tab label="Contatos" {...a11yProps(2)} />
                </Tabs>
            </AppBar>

            <Paper className={classes.paperinternal}>

                <Grid item xs={12}>
                    <TabPanel value={value} index={0}>
                        <DadosGerais
                            Update={setDadosGerais}
                            Dados={dadosGerais}
                            ListType={listType}
                            SetSelectedType={setSelectedType}
                            SelectedType={selectedType}
                            SetListSerie={setListSerie}
                            ListaSeries={listSerie}
                            SetSelectedSerie={setSelectedSerie}
                            SelectedSerie={selectedSerie}
                            SetListPartnersGroup={setListPartnersGroup}
                            ListaPartnersGroup={listPartnersGroup}
                            SelectedPartnersGroup={selectedPartnersGroup}
                            SetSelectedPartnersGroup={setSelectedPartnersGroup}
                        />
                    </TabPanel>
                </Grid>

                <TabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EnderecosList UpdateList={setListEnderecoCobranca} TipoEndereco='Cobrança' TipoPN={selectedType.CardType} ListDados={listEnderecoCobranca} />
                            <br />
                            <EnderecosList UpdateList={setListEnderecoEntrega} TipoEndereco='Entrega' TipoPN={selectedType.CardType} ListDados={listEnderecoEntrega} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PessoasContatoList UpdateList={setListPessoaContato} ListDados={listPessoaContato} />
                        </Grid>
                    </Grid>
                </TabPanel>

                <Grid item xs={12}>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Adicionar
                        </Button>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="outlined"
                        component={Link}
                        to={'/home/businesspartners'}
                    >
                        Cancelar
                        </Button>
                </Grid>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={message}>
                        <br />
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setMessageAlert('')
                                        setMessage(false)
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            Erro ao realizar cadastro, verifique os dados e tente novamente:
                            <p>{mensagemAlert}</p>
                        </Alert>
                    </Collapse>
                </Grid>

            </Paper>
        </>
    )
}

export default BusinessPartnersCreate
