import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import dateFormat from '../../services/dateFormat';
import {
    Grid, Paper, TextField, Typography, TableContainer, LinearProgress,
    Tabs, Tab, AppBar, Button, Table, TableHead, TableCell, TableBody, TableRow, Collapse
} from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { findByReference2 } from '../../pages/InventoryGenExits/InventoryGenExitsController';
import { findOneSafra, findOneCultura } from '../DefaultController';
import { createInventoryGenEntrie, update } from '../InventoryGenExits/InventoryGenExitsController';
import { v4 as uuid } from 'uuid';
import Alert from '@material-ui/lab/Alert';
import { findOneOperation } from './AgriculturalOperationsController';

const AgriculturalOperationsView = ({ history }) => {

    let { id } = useParams();

    const [tabs, setTabs] = useState(1);
    const [register, setRegister] = useState({});
    const [documentsToCancel, setDocumentsToCancel] = useState([]);
    const [lines, setLines] = useState([]);
    const [error, setError] = useState({ isError: false, errorMessage: [] });   
    const [safra, setSafra] = useState('');
    const [cultura, setCultura] = useState('');
    const [operation, setOperation] = useState('');
    const [progressBar, setProgressBar] = useState(true);
    

    const classes = CreateAndViewStyle();


    useEffect(() => {
        async function fetchData() {
            const reg = await findByReference2(id)
           
            setDocumentsToCancel(reg)

            const filtered = reg.filter(item => item.U_B2Obj === 'B2AGRI_AGRIOPER')
            let tempLines = []

            for(let i = 0; i < filtered.length; i++) {
                for(let j = 0; j < filtered[i].DocumentLines.length; j++) {
                    tempLines.push(filtered[i].DocumentLines[j])
                }
            }
            
            setLines(tempLines);
            let obj = null;
            if (reg[0]) {
                obj = reg[0];
                setRegister(reg[0])
            }

            if (obj.U_Safra) {
                const saf = await findOneSafra(obj.U_Safra)
                setSafra(saf)
            }

            if (obj.U_Cultura) {
                const cul = await findOneCultura(obj.U_Cultura)
                setCultura(cul)
            }

            if(obj.U_OperacaoAgricola) {
                const oper = await findOneOperation(obj.U_OperacaoAgricola)
                setOperation(oper[0].Name)
            }

        };
        fetchData();
    }, [id]);

    const realizeCancel = async () => {
        setProgressBar(false);

        if(!documentsToCancel) {
            return
        }

        //documentsToCancel.forEach(async document => {
        for (const document of documentsToCancel) {
            
            const docLines = document.DocumentLines.map(item => {
                return {
                    ItemCode: item.ItemCode,             
                    WarehouseCode: item.WarehouseCode,
                    Quantity: item.Quantity,
                    UnitPrice: item.Price,
                    AccountCode: item.AccountCode
                }
            })

            const obj = {
                DocDate: document.DocDate,
                JournalMemo: `Estorno B2Agri - Operação Agrícola (${document.Reference2})`,
                Reference2: document.Reference2,
                DocumentLines: docLines,
                BPL_IDAssignedToInvoice: document.BPL_IDAssignedToInvoice
            }  

            const result = await createInventoryGenEntrie(obj);
            
            await update(document.DocEntry, {
                JournalMemo: `CANCELADO - ${document.JournalMemo}`
            })

            if (result?.error) {
               
                setError({ isError: true, errorMessage: [result.error.message.value] });    
            }   

 
        }

        setProgressBar(true);
    }

    const handleCancel = async () => {  

        await realizeCancel();

        if(!error.isError) {
            setProgressBar(true);
            alert('CANCELAMENTO REALIZADO COM SUCESSO!')
            history.push('/home/agriculturalOperations/list');
        }      
        
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Operação Agrícola - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>

                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="code"
                                label="Código"
                                value={id}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="operacao"
                                label="Operação"
                                value={operation}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="rda"
                                label="RDA"
                                value={register.U_RDA ? register.U_RDA : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="datainicial"
                                label="Data Inicial"
                                value={register.U_OPAgriDtIni ? dateFormat(register.U_OPAgriDtIni) : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="horainicial"
                                label="Hora Inicial"
                                value={register?.U_OPAgriHIni}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="datafinal"
                                label="Data Final"
                                value={register.U_OPAgriDtFin ? dateFormat(register.U_OPAgriDtFin) : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="horafinal"
                                label="Hora Final"
                                value={register?.U_OPAgriHFin}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="Safra"
                                label="Safra"
                                value={safra ? `${safra.Code} - ${safra.Name}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="Cultura"
                                label="Cultura"
                                value={cultura ? `${cultura.Code} - ${cultura.Name}` : ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="Variedade"
                                label="Variedade"
                                value={register?.U_Variedade}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="Talhao"
                                label="Talhão"
                                value={register.U_Talhao}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="Area"
                                label="Área Realizada"
                                value={register?.U_AreaRealizada}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>U.M.</TableCell>
                                            <TableCell>Quantidade</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lines &&
                                            lines.map((item, index) => {
                                                return (
                                                    <TableRow key={uuid()}>
                                                        <TableCell>{item.ItemCode}</TableCell>
                                                        <TableCell>{item.ItemDescription}</TableCell>
                                                        <TableCell>{item.MeasureUnit}</TableCell>
                                                        <TableCell>{item.Quantity}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>                       

                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                                value={`${register.Comments} - ${register.JournalMemo}`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                 
                            <Button color="primary" className={classes.button} variant="outlined" onClick={handleCancel}>
                                Cancelar Apontamento
                            </Button>                                                  
                            <Button variant="contained" color="primary" className={classes.button} component={Link} to={'/home'}>
                                Voltar
                            </Button>
                        </Grid>

                    </Grid>
                </TabPanel>
                <Grid item xs={12}>
                    <LinearProgress hidden={progressBar} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={error.isError}>
                        <Alert severity="error"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => {
                                        setError({ isError: false, errorMessage: [] });
                                    }}
                                >
                                    Fechar
                                </Button>
                            }
                        >
                            {error.errorMessage.map(err => {
                                return (
                                    <>- {err} <br /></>

                                )
                            })}
                        </Alert>
                    </Collapse>
                </Grid>
            </Paper>
        </>
    )
}

export default AgriculturalOperationsView;