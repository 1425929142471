import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findAllWeatherRegisters } from './WeatherRecordController';
import dateFormat from '../../services/dateFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';

const WeatherRecordList = ({ history }) => {

    const columns = [
        {
            field: 'id',
            headerName: 'Nº',
            width: 90,
        },
        {
            field: 'U_Data',
            headerName: 'Data',
            width: 200,
        },
        {
            field: 'U_PostoID',
            headerName: 'Posto',
            width: 200,
        },
        {
            field: 'Canceled',
            headerName: 'Status',
            width: 200,
        },
        {
            field: 'U_Obs',
            headerName: 'Observações',
            width: 450,
        }
    ];

    const useStyles = ListDocumentsStyle;

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const result = await findAllWeatherRegisters();
            
            if (result) {
                let final = result.map((item, index) => {
                    return {
                        id: item.DocEntry,
                        U_Data: dateFormat(item.U_Data),
                        U_PostoID: item.U_PostoID,
                        Canceled: status(item.Canceled),
                        U_Obs: item.U_Obs
                    }
                })

                setRowsData(final);
            }
        }

        fetchData();
    }, []);

    const status = (code) => {
        if(code === 'Y') {
            return 'Cancelado'
        } else {
            return 'Aberto'
        }
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Registros Climáticos
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    onRowClick={(event) => {
                        history.push(`/home/weatherRecord/view/${event.row.id}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/weatherRecord/create'}>
                            Novo Registro Climático
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default WeatherRecordList;