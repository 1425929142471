import React, { useState, useEffect } from 'react';
import {
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Button, Typography,
    FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import Classification from './Classification';
import ItemsAutocomplete from '../../../components/Items/ItemsAutocomplete';
import { findBatchNumbersFromItem, findCultureByItemCode, findItemByCode } from './PlantationShipmentController';
import { findByBranchIdWithThird } from '../../../components/Deposits/DepositsController';
import { Talhoes, Cultura } from '../../DefaultController';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import moneyFormat from '../../../services/moneyFormat';
import { Safra } from '../../DefaultController';
import { v4 as uuid } from 'uuid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const ItemData = (props) => {

    const classes = CreateAndViewStyle();

    const [batchs, setBatchs] = useState([]);
    const [talhoes, setTalhoes] = useState([]);
    const [disableVarieties, setDisableVarieties] = useState(true);
    const [varieties, setVarieties] = useState([]);
    const [totalQtdeTalhoes, setTotalQtdeTalhoes] = useState(0.0)
    const [classificationList, setClassificationList] = useState([]);
    const [safraList, setSafraList] = useState([]);

    useEffect(() => {
        async function fetchData() {

            const sf = await Safra()
            setSafraList(sf)

        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {

            atualizaSomatoria()
        }

        fetchData();
    });

    useEffect(() => {
        async function fetchData() {

            const sf = await Safra()
            setSafraList(sf)
        }

        fetchData();
    }, []);


    useEffect(() => {
        async function fetchData() {

            if (props.vwObj.Vw_Item) {
                const iCode = props.vwObj.Vw_Item.split(' - ')[0];

                const itemResult = await findItemByCode(iCode);

                if (itemResult?.ManageBatchNumbers === 'tYES') {

                    const btc = await findBatchNumbersFromItem(iCode);
                    setBatchs(btc);
                }

                const ct = await Cultura()
                const tempCulture = ct.filter((atual) => {
                    if (atual.U_ItemCode === iCode)
                        return atual;
                })

                if (tempCulture[0].VARIEDADESCollection.length > 0) {
                    setDisableVarieties(false);
                    setVarieties(tempCulture[0].VARIEDADESCollection)
                } else {
                    setDisableVarieties(true);
                    setVarieties([]);
                }
            }
        };

        fetchData();
    }, [props.vwObj.Vw_Item]);


    useEffect(() => {
        async function fetchData() {

            if (props.vwObj.Vw_Deposito) {

                const deposits = await findByBranchIdWithThird(props.vwObj.Vw_FilialID);
                const depObj = deposits.find(x => x.WarehouseCode === props.vwObj.Vw_Deposito);
                if (depObj) {
                    
                    props.updateVwObject('Vw_Location', depObj.Location);

                    const tal = await Talhoes(depObj.Location)
                    if (tal[0]) {

                        setTalhoes(tal[0].TALHOESCollection);
                    }
                }
            }
        };

        fetchData();
    }, [props.vwObj.Vw_Deposito]);


    const atualizaSomatoria = () => {


        const dCultivo = props.variedades

        if (dCultivo) {
            const pCultivo = Object.values(dCultivo).reduce((t, { U_Quantidade }) => t + parseFloat(U_Quantidade ? U_Quantidade : 0), 0)

            setTotalQtdeTalhoes(pCultivo)
        }

    }

    const handlePesoNeto = () => {
        if (props.obj.U_PesoBruto && props.obj.U_PesoTara) {
            const neto = props.obj.U_PesoBruto - props.obj.U_PesoTara;

            props.updateObject('U_PesoNeto', neto)
        }
    }

    const handlePesoLiquido = () => {
        if (props.obj.U_PesoNeto) {
            const liquido = props.obj.U_PesoNeto - props.obj.U_DescontoKg;

            props.updateObject('U_PesoLiquido', liquido)
        }
    }

    const handleDeleteItem = (event) => {
        const index = event.currentTarget.dataset.key;

        let tempArray = [];
        if (props.variedades) {
            tempArray = [...props.variedades]
            tempArray.splice(index, 1);
            props.setVariedades([...tempArray])
        }

    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Dados do Item</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="Vw_Item"
                        label="Item"
                        value={props.vwObj.Vw_Item}
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                        <InputLabel id="descricaoComplementar" >Descrição Complementar</InputLabel>
                        <Select
                            labelId="descricaoComplementar"
                            id="descricaoComplementar"
                            label="Descrição Complementar"
                            defaultValue="NENHUMA"
                            value={props.obj.U_ItemDescComplementar || ''}
                            defaultValue=""
                            onChange={event => {
                                props.updateObject('U_ItemDescComplementar', event.target.value);
                            }}
                            disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        >
                            <MenuItem value={'NENHUMA'}>NENHUMA</MenuItem>
                            <MenuItem value={'INTACTA'}>INTACTA</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    {
                        batchs.length > 0 &&

                        <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                            <InputLabel id="loteLabel">Lote</InputLabel>
                            <Select
                                labelId="loteLabel"
                                id="lote"
                                label="Lote"
                                value={props.obj.U_LoteItem || ''}
                                defaultValue=""
                                onChange={event => {
                                    props.updateObject('U_LoteItem', event.target.value)
                                }}
                            >
                                {batchs.map(lote => {
                                    return (
                                        <MenuItem value={lote.Batch}>{lote.Batch}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography>Dados de Cultivo</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="safra-select-input">Safra</InputLabel>
                        <Select
                            fullWidth
                            labelId="safra-select-input"
                            id="safra-select"
                            label="Safra"
                            defaultValue=""
                            value={props.obj.U_Safra}
                            disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                            onChange={event => {
                                props.updateObject('U_Safra', event.target.value)
                            }}
                        >
                            {safraList.map(item => {
                                return (
                                    <MenuItem value={item.Code} key={uuid()}>{item.Code + ' - ' + item.Name}</MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} disabled>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Variedade</TableCell>
                                    <TableCell>Talhão</TableCell>
                                    <TableCell align='right'>Quantidade</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.variedades.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={classes.tablePaddingRemove} style={{ width: '40%' }}>
                                                <FormControl variant="outlined" size="small" fullWidth>
                                                    <Select
                                                        labelId="variedadeInput"
                                                        disabled={disableVarieties}
                                                        value={props.variedades ? props.variedades[index].U_Variedade : ''}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                                                        inputProps={{
                                                            'data-key': index,
                                                        }}
                                                        onChange={event => {
                                                            let tempArray = [];
                                                            if (props.variedades) {
                                                                tempArray = [...props.variedades];
                                                            }

                                                            tempArray[index] = {
                                                                U_Talhao: tempArray[index].U_Talhao,
                                                                U_Variedade: event.target.value,
                                                                U_Quantidade: tempArray[index].U_Quantidade
                                                            };

                                                            props.setVariedades([...tempArray])
                                                        }}
                                                    >
                                                        {varieties.map(variety => {
                                                            return (
                                                                <MenuItem value={variety.U_Variedades}>{variety.U_Variedades}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={classes.tablePaddingRemove} style={{ width: '30%' }}>
                                                <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '100%' }}>
                                                    <Select
                                                        labelId="talhao"
                                                        id={index}
                                                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                                                        value={props.variedades ? props.variedades[index].U_Talhao : ''}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            'data-key': index,
                                                        }}
                                                        onChange={event => {
                                                            let tempArray = [];
                                                            if (props.variedades) {
                                                                tempArray = [...props.variedades];
                                                            }

                                                            tempArray[index] = {
                                                                U_Talhao: event.target.value,
                                                                U_Variedade: tempArray[index].U_Variedade,
                                                                U_Quantidade: tempArray[index].U_Quantidade
                                                            };

                                                            props.setVariedades([...tempArray])
                                                        }}
                                                    >
                                                        {talhoes.map(talhao => {
                                                            return (
                                                                <MenuItem value={talhao.U_TalhaoCode}>{talhao.U_TalhaoCode}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='right' className={classes.tablePaddingRemove} style={{ width: '30%' }}>
                                                <CurrencyTextField
                                                    align='right'
                                                    id={index}
                                                    currencySymbol=""
                                                    decimalCharacter=","
                                                    digitGroupSeparator="."
                                                    minimumValue="0"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={props.variedades ? props.variedades[index].U_Quantidade : ''}
                                                    onChange={(event, value) => {
                                                        let tempArray = [];
                                                        if (props.variedades) {
                                                            tempArray = [...props.variedades];
                                                        }

                                                        tempArray[index] = {
                                                            U_Talhao: tempArray[index].U_Talhao,
                                                            U_Variedade: tempArray[index].U_Variedade,
                                                            U_Quantidade: value
                                                        };

                                                        props.setVariedades([...tempArray])

                                                        atualizaSomatoria()
                                                    }}
                                                    onBlur={() => {

                                                        props.setVariedades([...props.variedades, { U_Variedade: '', U_Talhao: '', U_Quantidade: '' }])
                                                        atualizaSomatoria()
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    data-key={index}
                                                    onClick={handleDeleteItem}
                                                    disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                                                >
                                                    <DeleteOutlineIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>

                                    );
                                })}
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align='right'>{moneyFormat(totalQtdeTalhoes)}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Dados de Pesagem</Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="ticket"
                        label="Nº Ticket"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.obj.U_NumeroTicket}
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        onChange={event => {
                            props.updateObject('U_NumeroTicket', event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="ccg"
                        label="CCG"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.obj.U_CCG}
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        onChange={event => {
                            props.updateObject('U_CCG', event.target.value);
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoBruto"
                        label="Peso Bruto"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"                        
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        InputLabelProps={{ shrink: true }}
                        value={props.obj.U_PesoBruto}
                        onBlur={handlePesoNeto}
                        onChange={(event, value) => {
                            props.updateObject('U_PesoBruto', value);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoTara"
                        label="Peso Tara"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        InputLabelProps={{ shrink: true }}
                        value={props.obj.U_PesoTara}
                        onBlur={handlePesoNeto}
                        onChange={(event, value) => {
                            props.updateObject('U_PesoTara', value);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoNeto"
                        label="Peso Neto"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.obj.U_PesoNeto}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <CurrencyTextField
                        id="desconto"
                        label="Desconto KG"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={props.obj.DocEntry && props.obj.Status !== 'O'}
                        InputLabelProps={{ shrink: true }}
                        value={props.obj.U_DescontoKg}
                        onBlur={handlePesoLiquido}
                        onChange={(event, value) => {
                            props.updateObject('U_DescontoKg', value);
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CurrencyTextField
                        id="pesoLiquido"
                        label="Peso Liquido"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={props.obj.U_PesoLiquido}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Classificação</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Classification
                        obj={props.obj}
                        classification={props.classification}
                        setClassification={props.setClassification}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ItemData;