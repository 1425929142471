import React, { useState } from 'react'
import LoginController from './LoginController.js'
import BaseController from './BaseController.js'
import UserBranchsController from './UserBranchsController.js'
import logoHead from '../../assets/img/logo-head.png'
import powered from '../../assets/img/powered.png'
import Background from '../../assets/img/wallpaper.jpg'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { InputAdornment } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import { AccountCircle, LockRounded } from '@material-ui/icons'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://base2ss.com">
                BASE2
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

function Powered() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Powered for '}
            <img
                src={powered}
                width={70}
                alt='logo'
            />
        </Typography>
    )
}

const Login = ({ history }) => {

    const [_email, setEmail] = useState('')
    const [_senha, setSenha] = useState('')
    const [progressBar, setProgressBar] = useState(true)
    const [message, setMessage] = useState(false)
    const [mensagemAlert, setMessageAlert] = useState('')

    const handleSubmit = (callback) => (event) => {
        event.preventDefault();
        setProgressBar(true)
        callback()
        setProgressBar(false)
    }

    const handleLogin = async () => {
        setProgressBar(false);
        try {
            const result = await LoginController(_email, _senha)
            if (!result) {
                setMessageAlert('LOGIN INVÁLIDO!')
                setMessage(true)
                setProgressBar(true)
                return
            }
            localStorage.setItem('@SAP:EMAIL', result.eMail)
            localStorage.setItem('@SAP:USER', `${result.FirstName} ${result.LastName}`)
            localStorage.setItem('@SAP:EMPLOYEEID', result.EmployeeID)
            const userBranchs = await UserBranchsController.getUserBranchs(result.eMail)
            if (userBranchs.value[0].EmployeeBranchAssignment.length === 0) {
                setMessageAlert('USUÁRIO SEM ATRIBUIÇÃO DE FILIAL!')
                setMessage(true)
                setProgressBar(true)
                return
            }
            const branchArray = []
            userBranchs.value[0].EmployeeBranchAssignment.forEach(branch => {
                branchArray.push(branch.BPLID)
            });

            const branchsInfo = await UserBranchsController.getUserBranchsInfo(branchArray);
            localStorage.setItem("@SAP:USERBRANCHS", JSON.stringify(branchsInfo.value));
            
            const userCurrentBase = await BaseController()
            localStorage.setItem("@SAP:CURRENTBASE", userCurrentBase);
            history.push('/home')

        } catch (error) {
            setMessageAlert(`ERRO NO LOGIN: ${error}`)
            setMessage(true)
            setProgressBar(true)
        }

    }

    return (
        <div style={{ backgroundColor: '#FFFDFF' }}>
            <Grid container style={{ minHeight: '100vh' }}>
                <Grid item xs={12} sm={8}>
                    <img
                        src={Background}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        alt='brand'
                    />
                </Grid>
                <Grid container item xs={12} sm={4}
                    alignItems='center'
                    direction='column'
                    justify='space-between'
                    style={{ padding: 10 }}>
                    <div />
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 400, minWidth: 300 }}>
                        <form onSubmit={handleSubmit(handleLogin)} >
                            <Grid container justify='center'>
                                <img
                                    src={logoHead}
                                    width={200}
                                    alt='logo'
                                />
                            </Grid>
                            <TextField
                                InputProps={{ startAdornment: <InputAdornment position='start'><AccountCircle /></InputAdornment> }}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                value={_email}
                                onChange={(event) => {
                                    setEmail(event.target.value)
                                }}
                                autoFocus
                            />
                            <TextField
                                InputProps={{ startAdornment: <InputAdornment position='start'><LockRounded /></InputAdornment> }}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                value={_senha}
                                onChange={(event) => {
                                    setSenha(event.target.value)
                                }}
                            />
                            <div style={{ heght: 20 }} />
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Entrar
                            </Button>
                            <br />
                            <Grid item xs={12}>
                                <LinearProgress hidden={progressBar} />
                            </Grid>
                            <br />
                            <Grid item xs={12}>
                                <Collapse in={message}>
                                    <Alert severity="error"
                                        action={
                                            <Button color="inherit" size="small"
                                                onClick={() => {
                                                    setMessage(false);
                                                }}
                                            >
                                                Fechar
                                </Button>
                                        }
                                    >
                                        {mensagemAlert}
                                    </Alert>
                                </Collapse>
                            </Grid>
                        </form>
                    </div>
                    <Grid>
                        <Grid container justify='center' spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Copyright />
                            </Grid>
                        </Grid>
                        <Grid container justify='center' spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <Powered />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Login