import api from '../../services/api';

export const findByBranchId = async (id) => {

    const options = {
        method: 'GET',
        url: `/Deposits/${id}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};

export const findByLocationId = async (id) => {

    const options = {
        method: 'GET',
        url: `/Locations/${id}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};

export const findByWarehouseCode = async (id) => {

    const options = {
        method: 'GET',
        url: `/Warehouse/${id}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};

export const findByBranchIdWithThird = async (id) => {

    const options = {
        method: 'GET',
        url: `/Deposits/withThird/${id}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        console.error(error);
    });
};
