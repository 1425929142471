import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Checkbox from '@material-ui/core/Checkbox'
import { SeriesService_GetDocumentSeriesList } from '../DefaultController'
import { BusinessPartnerGroups } from './BusinessPartnersController'
import {
    Grid,
    TextField
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(0),
    },
    center: {
        textAlign: 'center',
    },
    table: {
    },
    button: {
        marginRight: '5px',
    },
    tabBackground: {
        background: '#FDF5E6'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}))

const DadosGerais = props => {
    const classes = useStyles
    const [_CodigoDisabled, setCodigoDisabled] = useState(false)
    const [checkedIsento, setCheckedIsento] = React.useState(false);
    const [newPN, setNewPN] = useState(props.Dados)

    const handleChangeIsento = (event) => {
        setCheckedIsento(event.target.checked)
        if (event.target.checked) {
            let tempPN = { ...newPN }
            tempPN.IE = 'Isento'
            setNewPN(tempPN)
        }
        else {
            let tempPN = { ...newPN }
            tempPN.IE = ''
            setNewPN(tempPN)
        }
    }

    const handleChangeTipoAndGroupList = async (tipo) => {
        const doctosSerieList = await SeriesService_GetDocumentSeriesList(2, tipo === 'Fornecedor' ? 'S' : 'C')
        props.SetListSerie([])
        if (doctosSerieList) {
            props.SetListSerie(doctosSerieList)
        }

        const bpGroup = await BusinessPartnerGroups(tipo === 'Fornecedor' ? 'bbpgt_VendorGroup' : 'bbpgt_CustomerGroup')
        props.SetListPartnersGroup([])
        if (bpGroup) {
            console.log(bpGroup)
            props.SetListPartnersGroup(bpGroup)
        }
    }

    useEffect(() => {
        props.Update(newPN)
    })

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={3} xs={6}>
                    <Autocomplete
                        id="tipo"
                        options={props.ListType}
                        value={props.SelectedType}
                        getOptionLabel={(option) => option.Name}
                        onBlur={(event) => {
                            props.SetSelectedType(props.ListType.find(t => t.Name === event.target.value))
                            handleChangeTipoAndGroupList(event.target.value)
                        }}
                        renderInput={(params) => <TextField {...params} label="Tipo" variant="outlined" />}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item sm={3} xs={6}>
                    <Autocomplete
                        id="serie"
                        options={props.ListaSeries}
                        value={props.SelectedSerie}
                        getOptionLabel={(option) => option.Name}
                        onBlur={(event) => {
                            if (event.target.value) {
                                props.SetSelectedSerie(props.ListaSeries.find(t => t.Name === event.target.value))
                                let tempPN = { ...newPN }
                                tempPN.Series = parseInt(event.target.value)
                                tempPN.CardCode = ''
                                setNewPN(tempPN)
                                setCodigoDisabled(true)
                                if ((event.target.value === 'Manual')) {
                                    setCodigoDisabled(false)
                                }
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Série" variant="outlined" />}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Código"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 20 }}
                        value={newPN.CardCode}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.CardCode = event.target.value.toUpperCase()
                            setNewPN(tempPN)
                        }}
                        disabled={(_CodigoDisabled) ? "disabled" : ""}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        id="grupo"
                        options={props.ListaPartnersGroup}
                        value={props.SelectedPartnersGroup}
                        getOptionLabel={(option) => option.Name}
                        onBlur={(event) => {
                            if (event.target.value) {
                                props.SetSelectedPartnersGroup(props.ListaPartnersGroup.find(t => t.Name === event.target.value))
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Grupo" variant="outlined" />}
                        size="small"
                        fullWidth
                    />

                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Nome"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 100 }}
                        value={newPN.CardName}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.CardName = event.target.value.toUpperCase()
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Nome Fantasia"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 100 }}
                        value={newPN.AliasName}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.AliasName = event.target.value.toUpperCase()
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Telefone 1"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 20 }}
                        value={newPN.Phone1}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.Phone1 = event.target.value
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Telefone 2"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 20 }}
                        value={newPN.Phone2}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.Phone2 = event.target.value
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Celular"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 20 }}
                        value={newPN.Cellular}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.Cellular = event.target.value
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="E-mail"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 60 }}
                        value={newPN.EmailAddress}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.EmailAddress = event.target.value.toLowerCase()
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="CPF/CNPJ"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 18 }}
                        value={newPN.CpfCnpj}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.CpfCnpj = event.target.value
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={checkedIsento}
                        label="Inscrição Estadual"
                        type="text"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 18 }}
                        value={newPN.IE}
                        onChange={(event) => {
                            let tempPN = { ...newPN }
                            tempPN.IE = event.target.value
                            setNewPN(tempPN)
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        checked={checkedIsento}
                        onChange={handleChangeIsento}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> Isento
                                </Grid>
            </Grid>
        </>
    )
}
export default DadosGerais