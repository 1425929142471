import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Paper, Typography, Grid, TextField, Tabs, Tab, AppBar, Button
} from '@material-ui/core';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import TabPanel from '../../components/TabPanel';
import { findOneDeath } from './AnimalLifeCycleController';

const AnimalBirthRecordView = ({history}) => {

    let { id } = useParams();

    const useStyles = ListDocumentsStyle;
    const classes = useStyles();
    const [tabs, setTabs] = useState(1);

    const [register, setRegister] = useState({})
    const [lines, setLines] = useState({})
    const [cancelado, setCancelado] = useState(false)

    useEffect(() => {
        async function fetchData() {
            const obj = await findOneDeath(id);
            if(obj.DocumentLines) {
                setRegister(obj);
                setLines(obj.DocumentLines[0]);
                if(obj.Comments.includes('CANCELADO')) {
                    setCancelado(true)
                }
            }
        };
        fetchData();
    }, [id]);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Registros de Morte de Animais - Visualização
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="codigo"
                                label="Código"
                                value={register.Reference2 || ''}
                                variant="outlined"
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="date"
                                label="Data da Saída"
                                type="date"
                                value={register.DocDate || ''}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                id="filial"
                                label="Filial"
                                value={register.BPLName || ''}
                                variant="outlined"
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="deposito"
                                label="Depósito"
                                value={lines.WarehouseCode}
                                variant="outlined"
                                size="small"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="item"
                                label="Item"
                                value={`${lines.ItemCode} - ${lines.ItemDescription}`}
                                variant="outlined"
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CurrencyTextField
                                fullWidth
                                variant="outlined"
                                currencySymbol=""
                                decimalCharacter=","
                                digitGroupSeparator="."
                                minimumValue="0"
                                label="Quantidade"
                                size="small"
                                disabled
                                value={lines.Quantity}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CurrencyTextField
                                fullWidth
                                variant="outlined"
                                currencySymbol="R$"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                minimumValue="0"
                                label="Custo Unitário"
                                size="small"
                                disabled
                                value={lines.Price}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                                value={register.Comments}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button color="primary" className={classes.button} disabled={cancelado} variant="outlined" onClick={() => { history.push(`/home/animaldeathrecord/cancel/${id}-${register.Reference2}`)}}>
                                Cancelar Apontamento
                            </Button>
                            <Button variant="contained" color="primary" className={classes.button} component={Link} to={'/home/animaldeathrecord/list'}>
                                Voltar
                            </Button>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Paper>
        </>
    )
}

export default AnimalBirthRecordView;