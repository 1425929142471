import api from '../../services/api';

export const findActiveProfitCentersInFirstDimension = async () => {

    const options = {
        method: 'GET',
        url: `/ProfitCenters`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        return error;
    });
};

export const findActiveProfitCentersInFirstDimension2 = async (id) => {

    const options = {
        method: 'GET',
        url: `/ProfitCenters2/${id}`,
        headers: { 'Content-Type': 'application/json' }
    };

    return api.request(options).then(function (response) {

        return response.data.value;
    }).catch(function (error) {

        return error;
    });
};



