import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findAll } from './PurchaseRequestController';
import dateFormat from '../../services/dateFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';
import status from '../../services/documentStatus';


const PurchaseRequestList = ({ history }) => {

    const columns = [
        {
            field: 'id',
            headerName: '#',
            width: 90,
        },
        {
            field: 'DocNum',
            headerName: 'Nº',
            width: 100,
        },
        {
            field: 'DocDate',
            headerName: 'Data',
            width: 120,
        },
        {
            field: 'RequriedDate',
            headerName: 'Data Necessária',
            width: 120,
        },
        {
            field: 'DocumentStatus',
            headerName: 'Status',
            width: 120,
        },
        {
            field: 'BPLName',
            headerName: 'Filial',
            width: 300,
        },
        {
            field: 'RequesterName',
            headerName: 'Criador',
            width: 300,
        },
        {
            field: 'U_TX_NDfe',
            headerName: 'Nº NF',
            width: 140,
        },
        {
            field: 'Comments',
            headerName: 'Observações',
            width: 1000,  
        },
        {
            field: 'DocEntry',
            headerName: '',
            width: 0,
        }
    ];

    const useStyles = ListDocumentsStyle;

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    const formatName = (name) => {

        const splitted = name.split(',')

        return `${splitted[1] || ''} ${splitted[0] || ''}`
    }

    useEffect(() => {
        async function fetchData() {
            const result = await findAll();
            
            if (result) {
                let final = result.map((item, index) => {
                    return {
                        id: index,
                        DocNum: item.DocNum,
                        DocEntry: item.DocEntry,
                        DocDate: dateFormat(item.DocDate),
                        RequriedDate: dateFormat(item.RequriedDate),
                        DocumentStatus: status(item.DocumentStatus, item.Cancelled),
                        BPLName: item.BPLName,
                        RequesterName: formatName(item.RequesterName),
                        Comments: item.Comments,
                        U_TX_NDfe: item.U_TX_NDfe
                    }
                })

                setRowsData(final);
            }
        }

        fetchData();
    }, []);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Solicitações de Compra
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    onRowClick={(event) => {
                        history.push(`/home/purchaseRequest/view/${event.row.DocEntry}`)
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/purchaseRequest/create'}>
                            Nova Solicitação de Compras
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default PurchaseRequestList;