import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Grid, Button
} from '@material-ui/core';
import { findOutboundListingList } from './Components/Controller';
import moneyFormat from '../../services/moneyFormat';
import DataGrid from '../../components/DataGrid';
import { Link } from 'react-router-dom';
import ListDocumentsStyle from '../../themes/CreateAndViewStyle';
import dateFormat from '../../services/dateFormat';


const OutboundListingList = ({ history }) => {

    const columns = [
        {
            field: 'id',
            headerName: '#',
            width: 90,
        },
        {
            field: 'DocEntry',
            headerName: 'Nº',
            width: 100,
        },
        {
            field: 'U_Data',
            headerName: 'Data',
            width: 140,
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 140,
        },
        {
            field: 'U_CCG',
            headerName: 'CCG',
            width: 140,
        },
        {
            field: 'SequenceSerial',
            headerName: 'NF',
            width: 100,
        },
        {
            field: 'U_B2Obj',
            headerName: 'Origem',
            width: 140,
        },
        {
            field: 'BPLName',
            headerName: 'Filial',
            width: 400,
        },
        {
            field: 'CardCode',
            headerName: 'Código',
            width: 140,
        },
        {
            field: 'CardName',
            headerName: 'Cliente',
            width: 400,
        },
        {
            field: 'ItemCode',
            headerName: 'Código',
            width: 140,
        },
        {
            field: 'ItemDescription',
            headerName: 'Descrição',
            width: 200,
        },
        {
            field: 'U_PesoBruto',
            headerName: 'Peso Bruto',
            width: 200,
            type: 'number'
        },
        {
            field: 'U_PesoTara',
            headerName: 'Peso Tara',
            width: 200,
            type: 'number'
        },
        {
            field: 'U_DescontoKg',
            headerName: 'Desconto Kg',
            width: 200,
            type: 'number'
        },
        {
            field: 'U_PesoLiquido',
            headerName: 'Peso Liquido',
            width: 200,
            type: 'number'
        },
        {
            field: 'U_Motorista',
            headerName: 'Motorista',
            width: 200,
        },
        {
            field: 'U_Placa',
            headerName: 'Placa',
            width: 200,
        }
    ];

    const status = (StatusValue, CanceledValue) => {
        if (CanceledValue === 'Y') {
            return 'Cancelado'
        }
        if (StatusValue === 'C') {
            return 'Encerrado'
        }
        if (StatusValue === 'O') {
            return 'Aberto'
        }
        return ''
    }

    const useStyles = ListDocumentsStyle;

    const [rowsData, setRowsData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        async function fetchData() {
            const result = await findOutboundListingList();
            
            if (result) {
                let final = result.map((item, index) => {

                    return {
                        id: index,
                        DocEntry: item.RomaneioSaida.DocEntry,
                        SequenceSerial: item.RomaneioSaida.U_SequenceSerial,
                        BPLName: item.Orders.BPLName,
                        CardCode: item.Orders.CardCode,
                        CardName: item.Orders.CardName,
                        ItemCode: item['Orders/DocumentLines'].ItemCode,
                        ItemDescription: item['Orders/DocumentLines'].ItemDescription,
                        U_Motorista: item.RomaneioSaida.U_Motorista,
                        U_Placa: item.RomaneioSaida.U_Placa,
                        U_B2Obj: mapOrigin(item.RomaneioSaida.U_B2Obj),
                        U_PesoBruto: item.RomaneioSaida.U_PesoBruto ? moneyFormat(item.RomaneioSaida.U_PesoBruto) : '0,00',
                        U_PesoTara: item.RomaneioSaida.U_PesoTara ? moneyFormat(item.RomaneioSaida.U_PesoTara) : '0,00',
                        U_DescontoKg: item.RomaneioSaida.U_DescontoKg ? moneyFormat(item.RomaneioSaida.U_DescontoKg) : '0,00',
                        U_PesoLiquido: item.RomaneioSaida.U_PesoLiquido ? moneyFormat(item.RomaneioSaida.U_PesoLiquido) : '0,00',
                        U_Data: dateFormat(item.RomaneioSaida.U_Data),
                        Status: status(item.RomaneioSaida.Status, item.RomaneioSaida.Canceled),
                        U_CCG: item.RomaneioSaida.U_CCG

                    }
                })

                setRowsData(final);
            }
        }

        fetchData();
    }, []);

    const mapOrigin = (origin) => {
        if(origin === 'B2ROM_LAV') {
            return 'LAVOURA'
        } else {
            return 'ARMAZÉM'
        }
    }

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Romaneios de Saída
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>

                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    onRowClick={(event) => {
                        if(event.row.U_B2Obj === 'LAVOURA') {

                            history.push(`/home/plantationShipment/create/${event.row.DocEntry}`)
                        } else {

                            history.push(`/home/outboundListing/create/${event.row.DocEntry}`)
                        }
                    }}
                />
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/outboundListing/create'}>
                            Novo Romaneio de Saída Armazém
                        </Button>
                        <Button color="primary" variant="contained" className={classes.button} component={Link} to={'/home/plantationShipment/create'}>
                            Novo Romaneio de Saída Lavoura
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default OutboundListingList;