import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { 
    Paper, 
    Grid,
    Typography,
    Button,
    LinearProgress

} from '@material-ui/core';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import { findOneBirth } from './AnimalLifeCycleController';
import { create } from '../../pages/InventoryGenExits/InventoryGenExitsController';
import { updateInventoryGenEntrie } from './AnimalLifeCycleController';

const PurchaseRequestCancel = ({history}) => {

    let { id } = useParams();
    let splited = id.split('-');
    let docEntry = splited[0];
    let Reference2 = splited[1];

    const [register, setRegister] = useState({})
    const [lines, setLines] = useState({})
    const [progressBar, setProgressBar] = useState(true);

    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {
            const obj = await findOneBirth(docEntry);
            if(obj.DocumentLines) {
                setRegister(obj);
                setLines(obj.DocumentLines[0]);
            }
        };
        fetchData();
    }, [docEntry]);

    const handleCancel = async () => {

        setProgressBar(false);
        
        const obj = {
            DocDate: register.DocDate,
            JournalMemo: `CANCELAMENTO - B2Agri - Registro de Nascimento de Animais (${register.Reference2})`,
            Reference2: register.Reference2,
            U_B2Obj: 'B2AGRI_ANIMALBIRTH',
            Comments: `CANCELAMENTO - ${register.Comments}`,
            DocumentLines: [{
                ItemCode: lines.ItemCode,
                WarehouseCode: lines.WarehouseCode,
                Quantity: lines.Quantity,
                UnitPrice: lines.UnitPrice
            }],
            BPL_IDAssignedToInvoice: register.BPL_IDAssignedToInvoice
        }

        const result = await create(obj);
        console.log(result)
        if (result.data?.error) {

            alert('Erro ao realizar cancelamento.');

        } else {
            const att = await updateInventoryGenEntrie(docEntry, {
                Comments: `CANCELADO - B2Agri - Registro de Nascimento de Animais (${register.Reference2})`,
                JournalMemo: `CANCELADO - B2Agri - Registro de Nascimento de Animais (${register.Reference2})`
            })
            alert('CANCELAMENTO REALIZADO COM SUCESSO!')
            history.push('/home/animalbirthrecord/list');
        }
        
        setProgressBar(true);
    };

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                    Registro de Nascimento - Cancelar
                    </Typography>
                </Grid>
            </Paper>
            <Paper className={classes.paperInternal}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6">
                            Tem certeza que deseja cancelar o Registro de Nascimento de Animais <strong>{Reference2}</strong> ?
                        </Typography>     
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <Button 
                        color="primary" 
                        className={classes.button} 
                        variant="contained"   
                        onClick={handleCancel}                     
                    >
                            Cancelar
                    </Button>
                    <Button 
                        color="primary" 
                        className={classes.button} 
                        variant="outlined"
                        component={Link} 
                        to={`/home/animalbirthrecord/view/${docEntry}`}                         
                    >
                            Voltar
                    </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress hidden={progressBar} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default PurchaseRequestCancel;