import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Route from '../../routes/Route';
import { ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import simpleRoutes from './SimpleRoutes';
import List from '@material-ui/core/List';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import {
    ShoppingCart as ShoppingCartIcon,
    EventAvailable as EventAvailableIcon,
    Build as BuildIcon,
    LocalShipping as LocalShippingIcon,
    Assessment as AssessmentIcon
} from '@material-ui/icons';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as TouroIcon } from '../../assets/img/touro2.svg';

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: '#707070'
    },
    menuItem: {
        marginLeft: '10px'
    },
    subMenuItem: {
        paddingLeft: '20px'

    }
}));

export const MenuItens = () => {

    const classes = useStyles();
    const [purchaseMenuStatus, setPurchaseMenuStatus] = useState(false);
    const [purchaseReportMenuStatus, setPurchaseReportMenuStatus] = useState(false);
    const [notesMenuStatus, setNotesMenuStatus] = useState(false);

    const [vendasMenuStatus, setVendasMenuStatus] = useState(false);
    const [vendasReportMenuStatus, setVendasReportMenuStatus] = useState(false);
    const [oficinaMenuStatus, setOficinaMenuStatus] = useState(false);
    const [pecuariaMenuStatus, setPecuariaMenuStatus] = useState(false);
    const [notesReportMenuStatus, setNotesReportMenuStatus] = useState(false);
    const [osReportMenuStatus, setOsReportMenuStatus] = useState(false);
    const [animalReportMenuStatus, setAnimalReportMenuStatus] = useState(false);
    const [reportsMenuStatus, setReportMenuStatus] = useState(false);

    return (
        <>
            {/* <ListItem button onClick={() => { setPurchaseMenuStatus(!purchaseMenuStatus) }}>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Compras" />
                <ExpandMore />
            </ListItem>
            <Collapse in={purchaseMenuStatus} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/purchaseRequest/create" key="purchaseRequest" className={classes.link}>
                        <ListItem button>
                            <ListItemText primary="- Solicitação de Compras" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <Link to="/home/purchaseDeliveryNotes/openPurchaseOrders" key="purchaseDeliveryNotes" className={classes.link}>
                        <ListItem button>
                            <ListItemText primary="- Recebimento de Mercadorias" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <ListItem
                        button
                        className={classes.link}
                        onClick={() => { setPurchaseReportMenuStatus(!purchaseReportMenuStatus) }}
                    >
                        <ListItemText button primary="+ Relatórios de Compras" className={classes.menuItem} />
                        <ExpandMore />
                    </ListItem>
                    <Collapse in={purchaseReportMenuStatus} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/home/purchaseRequest" key="purchaseRequestReport" className={classes.link}>
                                <ListItem button>
                                    <ListItemText primary="- Lista de Solicitações de Compras" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                            <Link to="/home/purchaseDeliveryNotes" key="purchaseDeliveryNotesReport" className={classes.link}>
                                <ListItem button>
                                    <ListItemText primary="- Lista de Recebimentos de Mercadorias" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                    <Divider />

                </List>
            </Collapse> */}

            <ListItem button onClick={() => { setNotesMenuStatus(!notesMenuStatus) }}>
                <ListItemIcon>
                    <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText primary="Apontamentos" />
                <ExpandMore />
            </ListItem>
            <Collapse in={notesMenuStatus} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/* <Link to="/home/agriculturalOperations/create" key="agriculturalOperation" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Operações Agrícolas" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <Link to="/home/fuelAndLubrification/create" key="fuelAndLubrification" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Abastecimento / Lubrificação" className={classes.menuItem} />
                        </ListItem>
                    </Link> */}
                     <Link to="/home/seedTreatment/create" key="seedTreatment" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Tratamento de Sementes" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    {/*<Link to="/home/weatherRecord/create" key="weatherRecord" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Registro Climático" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <Link to="/home/outgoingMailing/create" key="outgoingMailing" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Romaneio de Remessa para Armazém de Terceiros" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <Link to="/home/ownShipment/create" key="ownShipment" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Romaneio de Remessa para Armazém Próprio" className={classes.menuItem} />
                        </ListItem>
                    </Link> 

                    <ListItem
                        button
                        className={classes.link}
                        onClick={() => { setNotesReportMenuStatus(!notesReportMenuStatus) }}
                    >
                        <ListItemText button primary="+ Relatórios de Apontamentos" className={classes.menuItem} />
                        <ExpandMore />
                    </ListItem>*/}
                    { /*<Collapse in={notesReportMenuStatus} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/home/agriculturalOperations/list" key="agriculturalOperationsReports" className={classes.link}>
                                <ListItem button >
                                    <ListItemText primary="- Lista de Operações Agrícolas" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                            <Link to="/home/fuelAndLubrification/list" key="fuelAndLubrificationReport" className={classes.link}>
                                <ListItem button >
                                    <ListItemText primary="- Lista de Abastecimentos e Lubrificações" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                            <Link to="/home/seedTreatment/list" key="seedTreatmentReport" className={classes.link}>
                                <ListItem button >
                                    <ListItemText primary="- Lista de Tratamentos de Semente" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                            <Link to="/home/weatherRecord/list" key="weatherRecordReport" className={classes.link}>
                                <ListItem button >
                                    <ListItemText primary="- Lista de Registros Climáticos" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                            <Link to="/home/outgoingMailing/list" key="outgoingMailingReport" className={classes.link}>
                                <ListItem button>
                                    <ListItemText primary="- Lista de Romaneios de Remessa para Armazém de Terceiros" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                            <Link to="/home/ownShipment/list" key="ownShipmentReport" className={classes.link}>
                                <ListItem button>
                                    <ListItemText primary="- Lista de Romaneios de Remessa para Armazém Próprio" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                        </List>
                </Collapse>*/}
                    <Divider />
                </List>
            </Collapse>

            {/*<ListItem button onClick={() => { setOficinaMenuStatus(!oficinaMenuStatus) }}>
                <ListItemIcon>
                    <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Oficina" />
                <ExpandMore />
            </ListItem>
            <Collapse in={oficinaMenuStatus} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/warehouseExit/create" key="saidaalmoxarifado" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Saída Almoxarifado" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <ListItem
                        button
                        className={classes.link}
                        onClick={() => { setOsReportMenuStatus(!osReportMenuStatus) }}
                    >
                        <ListItemText button primary="+ Relatórios de Oficina" className={classes.menuItem} />
                        <ExpandMore />
                    </ListItem>
                    <Collapse in={osReportMenuStatus} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/home/warehouseExit/list" key="weRequestReport" className={classes.link}>
                                <ListItem button>
                                    <ListItemText primary="- Lista de Saídas Almoxarifado" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                    <Divider />
                </List>
            </Collapse>


            <ListItem button onClick={() => { setPecuariaMenuStatus(!pecuariaMenuStatus) }}>
                <ListItemIcon>
                    <Icon>
                        <TouroIcon style={{ width: 25, height: 25, fill: '#7F7474' }} />
                    </Icon>
                </ListItemIcon>
                <ListItemText primary="Pecuária" />
                <ExpandMore />
            </ListItem>
            <Collapse in={pecuariaMenuStatus} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/animaldeathrecord/create" key="pecuaria" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Registro de Morte de Animal" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <Link to="/home/animalbirthrecord/create" key="pecuaria" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Registro de Nascimento de Animal" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <ListItem
                        button
                        className={classes.link}
                        onClick={() => { setAnimalReportMenuStatus(!animalReportMenuStatus) }}
                    >
                        <ListItemText button primary="+ Relatórios de Pecuária" className={classes.menuItem} />
                        <ExpandMore />
                    </ListItem>
                    <Collapse in={animalReportMenuStatus} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/home/animaldeathrecord/list" key="weRequestReport" className={classes.link}>
                                <ListItem button>
                                    <ListItemText primary="- Lista de Registros de Morte de Animais" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                            <Link to="/home/animalbirthrecord/list" key="weRequestReport" className={classes.link}>
                                <ListItem button>
                                    <ListItemText primary="- Lista de Registros de Nascimento de Animais" className={classes.subMenuItem} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                    <Divider />
                </List>
            </Collapse>

            <ListItem button onClick={() => { setVendasMenuStatus(!vendasMenuStatus) }}>
                <ListItemIcon>
                    <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary="Vendas" />
                <ExpandMore />
            </ListItem>
            <Collapse in={vendasMenuStatus} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/outboundListing/create" key="romaneiosaida" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Romaneio de Saída Armazém" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <Link to="/home/plantationShipment/create" key="romaneiosaidalavoura" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Romaneio de Saída Lavoura" className={classes.menuItem} />
                        </ListItem>
                    </Link>

                    <ListItem
                        button
                        className={classes.link}
                        onClick={() => { setVendasReportMenuStatus(!vendasReportMenuStatus) }}
                    >
                        <ListItemText button primary="+ Relatórios de Vendas" className={classes.menuItem} />
                        <ExpandMore />
                    </ListItem>
                    <Collapse in={vendasReportMenuStatus} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/home/outboundListing/list" key="romaneiosaidalist" className={classes.link}>
                                <ListItem button >
                                    <ListItemText primary="- Lista de Romaneios de Saída" className={classes.menuItem} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                    <Divider />
                </List>
            </Collapse>
            <ListItem button onClick={() => { setReportMenuStatus(!reportsMenuStatus) }}>
                <ListItemIcon>
                    <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Relatórios" />
                <ExpandMore />
            </ListItem>
            <Collapse in={reportsMenuStatus} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <Link to="/home/report/ItemList" key="itemlist" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Lista de Itens" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                    <Link to="/home/report/HarvestSummary" key="harvestSummary" className={classes.link}>
                        <ListItem button >
                            <ListItemText primary="- Resumo de Colheita" className={classes.menuItem} />
                        </ListItem>
                    </Link>
                </List>
            </Collapse> */}
        </>
    );
}

export const MenuRoutes = () => {
    return (
        <>
            {simpleRoutes.map((item, index) => {
                return (

                    <Route path={item.route} exact component={item.component} isPrivate key={index} />
                );
            })}
        </>
    );
}


