import React, { useEffect, useState } from 'react'
import {
    TextField, Collapse,
    Grid, Paper, Button, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, DialogContentText
} from '@material-ui/core'
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import { v4 as uuid } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { BuscaCEP } from '../../services/buscaCep'

const useStyles = makeStyles((theme) => ({
    title: {
        background: '#000000',
        fontWeight: 'bold',
        color: '#FFF'
    }
}))

const EnderecosList = props => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }
    const [message, setMessage] = useState(false)
    const [mensagemAlert, setMessageAlert] = useState('')
    const [list, setList] = useState(props.ListDados)
    const [checkedIsento, setCheckedIsento] = React.useState(false);
    const [checkedPadrao, setCheckedPadrao] = React.useState(true);
    const [_AddressName, setAddressName] = useState('')
    const [_ZipCode, setZipCode] = useState('')
    const [_TypeOfAddress, setTypeOfAddress] = useState('')
    const [_Street, setStreet] = useState('')
    const [_StreetNo, setStreetNo] = useState('')
    const [_Block, setBlock] = useState('')
    const [_City, setCity] = useState('')
    const [_State, setState] = useState('')
    const [_County, setCounty] = useState('')
    const [_U_IE, setU_IE] = useState('')

    const handleBuscaCEP = async (cep) => {
        setMessageAlert('')
        setMessage(false)

        const result = await BuscaCEP(cep)
        if (!result.erro) {
            let addr = result.logradouro.split(' ')
            setStreet(result.logradouro.slice(addr[0].length, result.logradouro.length).toUpperCase().trim())
            setTypeOfAddress(addr[0].toUpperCase())
            setBlock(result.bairro.toUpperCase())
            setCity(result.localidade.toUpperCase())
            setState(result.uf.toUpperCase())
            setCounty(result.localidade.toUpperCase())
        } else {
            setMessageAlert('CEP inválido ou inexistente!')
            setMessage(true)
        }
    }

    const [exibeTextIE] = useState(((props.TipoPN === 'cCustomer' && props.TipoEndereco === 'Entrega') || (props.TipoPN === 'cSupplier' && props.TipoEndereco === 'Cobrança')) ?? false)

    const handleChangeIsento = (event) => {
        setCheckedIsento(event.target.checked)
        if (event.target.checked) {
            setCheckedPadrao(false)
            setU_IE('Isento')
        }
        else {
            setU_IE('')
        }
    }

    const handleChangePadrao = (event) => {
        setCheckedPadrao(event.target.checked)
        setCheckedIsento(false)
        if (event.target.checked) {
            setCheckedIsento(false)
            setU_IE('')
        }
    };

    const handleAddItem = () => {
        const keyid = uuid()
        list.push({
            key: keyid,
            AddressName: _AddressName,
            TypeOfAddress: _TypeOfAddress,
            Street: _Street,
            StreetNo: _StreetNo,
            ZipCode: _ZipCode,
            Block: _Block,
            City: _City,
            State: _State,
            Country: 'BR',
            AddressType: props.TipoEndereco === 'Cobrança' ? 'bo_BillTo' : 'bo_ShipTo',
            U_IE: _U_IE,
            Padrao: checkedPadrao
        })
        props.UpdateList(list)
        setOpen(false)
    }

    const handleRemoveItem = async (key) => {
        setList(list.filter(i => i.key !== key))
        props.UpdateList(list)
    }

    useEffect(() => {
        props.UpdateList(list)
    })

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Button
                            size='small'
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setAddressName(null)
                                setZipCode(null)
                                setTypeOfAddress(null)
                                setStreet(null)
                                setStreetNo(null)
                                setBlock(null)
                                setCity(null)
                                setState(null)
                                setCounty(null)
                                setU_IE(null)
                                setCheckedPadrao(true)
                                setCheckedIsento(false)
                                setU_IE(null)
                                handleOpen()
                            }}
                        >
                            Novo Endereço de {props.TipoEndereco}
                        </Button>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Rua/Caixa Postal</TableCell>
                                    <TableCell>Rua Nº</TableCell>
                                    <TableCell>CEP</TableCell>
                                    <TableCell>Bairro</TableCell>
                                    <TableCell>Cidade</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>IE</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list?.map((item) => {
                                    return (
                                        <TableRow key={item.key}>
                                            <TableCell>{item.AddressName}</TableCell>
                                            <TableCell>{item.Street}</TableCell>
                                            <TableCell>{item.StreetNo}</TableCell>
                                            <TableCell>{item.ZipCode}</TableCell>
                                            <TableCell>{item.Block}</TableCell>
                                            <TableCell>{item.City}</TableCell>
                                            <TableCell>{item.State}</TableCell>
                                            <TableCell>{item.U_IE}</TableCell>
                                            <TableCell><Button onClick={() => handleRemoveItem(item.key)}><DeleteOutlineIcon /></Button></TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle className={classes.title} id="form-dialog-title">Cadastrar novo Endereço de {props.TipoEndereco}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid item xs={12}>
                            <Collapse in={message}>
                                <Alert severity="error"
                                    action={
                                        <Button color="inherit" size="small"
                                            onClick={() => {
                                                setMessageAlert('')
                                                setMessage(false)
                                            }}
                                        >
                                            Fechar
                                    </Button>
                                    }
                                >
                                    {mensagemAlert}
                                </Alert>
                            </Collapse>
                        </Grid>
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="ID do Endereço"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_AddressName}
                                onChange={(event) => {
                                    setAddressName(event.target.value.toUpperCase())
                                }}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="CEP"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_ZipCode}
                                onChange={(event) => {
                                    setZipCode(event.target.value)
                                }}
                                onFocus={() => {
                                    setMessage(false)
                                }}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                        handleBuscaCEP(event.target.value)
                                    }
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Tipo"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_TypeOfAddress}
                                onChange={(event) => {
                                    setTypeOfAddress(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                label="Rua/caixa postal"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_Street}
                                onChange={(event) => {
                                    setStreet(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Rua nº"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_StreetNo}
                                onChange={(event) => {
                                    setStreetNo(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Bairro"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_Block}
                                onChange={(event) => {
                                    setBlock(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="Cidade"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_City}
                                onChange={(event) => {
                                    setCity(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label="Estado"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_State}
                                onChange={(event) => {
                                    setState(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="Municipio"
                                type="text"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                value={_County}
                                onChange={(event) => {
                                    setCounty(event.target.value.toUpperCase())
                                }}
                                fullWidth
                            />
                        </Grid>
                        {exibeTextIE ?
                            <>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled={checkedPadrao || checkedIsento}
                                        label="IE"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 100 }}
                                        value={_U_IE}
                                        onChange={(event) => {
                                            setU_IE(event.target.value)
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox
                                        disabled={checkedPadrao}
                                        checked={checkedIsento}
                                        onChange={handleChangeIsento}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    /> Isento
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox
                                        disabled={checkedIsento}
                                        checked={checkedPadrao}
                                        onChange={handleChangePadrao}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    /> Padrão
                                </Grid>
                            </>
                            : <></>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                    <Button onClick={handleAddItem} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default EnderecosList
