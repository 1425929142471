import api from '../../services/api.js';

export const findAll = async () => {

    const branchs = JSON.parse(localStorage.getItem('@SAP:USERBRANCHS')); 

    const options = {
        method: 'POST',
        url: '/PurchaseRequest',
        headers: {'Content-Type': 'application/json'},
        data: branchs
      };
      
      return api.request(options).then(function (response) {
        
        return response.data.value;
      }).catch(function (error) {
        
        console.error(error);        
      });
};

export const findOne = async (id) => {

  const options = {
      method: 'GET',
      url: `/PurchaseRequest/${id}`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const cancel = async (id) => {

  const options = {
      method: 'POST',
      url: `/PurchaseRequest/${id}/Cancel`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const close = async (id) => {

  const options = {
      method: 'POST',
      url: `/PurchaseRequest/${id}/Close`,
      headers: {'Content-Type': 'application/json'}
    };
    
    return api.request(options).then(function (response) {

      return response.data;
    }).catch(function (error) {
      
      console.error(error);        
    });
};

export const create = async (purchaseRequestObject) => {

  const options = {
    method: 'POST',
    url: '/PurchaseRequest/create',
    validateStatus: () => true,
    data: purchaseRequestObject
  };
  
  return api.request(options).then(function (response) {
    
    return response;
  }).catch(function (error) {
    return error;
  });
};

export const updateAttachments = async (purchaseRequestDocEntry, AttachmentEntry) => {

  const options = {
    method: 'PATCH',
    url: `/PurchaseRequest/UpdateAttachments/${purchaseRequestDocEntry}`,
    validateStatus: () => true,
    data: {
      AttachmentEntry: AttachmentEntry
    }
  };
  
  return api.request(options).then(function (response) {
    
    return {status: 204};
  }).catch(function (error) {
    return error;
  });
};
