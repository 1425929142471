import React from 'react'
import { Grid, TextField } from '@material-ui/core';

const SenderData = (props) => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        id="codigo"
                        label="Código"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.Reference2}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="filial"
                        label="Filial"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.BPLName}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="deposito"
                        label="Depósito"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.FromWarehouse}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="razao"
                        label="Razão Social"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.BPLName}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="fantasia"
                        label="Nome Fantasia"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.AliasName}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="tipo"
                        label="Tipo"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.AddressType}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="logradouro"
                        label="Logradouro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.Street}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="numero"
                        label="Número"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.StreetNo}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="bairro"
                        label="Bairro"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.Block}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="cidade"
                        label="Cidade"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.City}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="cep"
                        label="CEP"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.branch.ZipCode}
                    />
                </Grid>

                <Grid item xs={8}>
                    <TextField
                        id="motorista"
                        label="Motorista"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer.U_Motorista}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="placa"
                        label="Placa"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.detalhes.U_Placa}
                    />
                </Grid>

                <Grid item xs={8}>
                    <TextField
                        id="transportadora"
                        label="Transportadora"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.detalhes.U_Transportadora}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="uf"
                        label="UF"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.detalhes.U_UFPlaca}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Observações"
                        multiline
                        rows={2}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={props.transfer.JournalMemo}
                        disabled
                    />
                </Grid>

            </Grid>
        </>
    )
}

export default SenderData;