import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import CreateAndViewStyle from '../../../themes/CreateAndViewStyle';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const InvoiceData = (props) => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        id="dataNota"
                        label="Data da Nota"
                        type="date"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice ? props.invoice.DocDate : ''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="quantidadeNota"
                        label="Quantidade da Nota"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.DocumentLines ? props.invoice.DocumentLines[0].Quantity : ''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CurrencyTextField
                        style={{ width: '100%' }}
                        id="valorNota"
                        variant="outlined"
                        currencySymbol="R$"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        minimumValue="0"
                        size="small"
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={props.invoice ? props.invoice.DocTotal : ''}

                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="sequencia"
                        label="Sequencia"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice ? props.invoice.DocDate : ''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="numNf"
                        label="Número NF"
                        type="number"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice ? props.invoice.SequenceSerial : ''}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="serie"
                        label="Série NF"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice ? props.invoice.SeriesString : ''}

                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="modelo"
                        label="Modelo"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice ? props.invoice.SequenceModel : ''}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="chaveAcesso"
                        label="Chave de Acesso"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice ? props.invoice.U_ChaveAcesso : ''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="frete"
                        label="Tipo de Frete"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.DocumentLines ? props.invoice.DocumentLines[0].Incoterms : ''}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="motorista"
                        label="Motorista"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.transfer ? props.transfer.U_Motorista : ''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="placaCaminhao"
                        label="Placa Caminhão"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.TaxExtension ? props.invoice.TaxExtension.Vehicle : ''}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="transportadora"
                        label="Transportadora"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={props.invoice.TaxExtension ? props.invoice.TaxExtension.Carrier : ''} 
                    />
                </Grid>
                <Grid item xs={4}>
                <TextField
                        id="estadoVeiculo"
                        label="UF Veiculo"
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        InputLabelProps={{ shrink: true }}   
                        value={props.invoice.TaxExtension ? props.invoice.TaxExtension.VehicleState : ''}                     
                    />
                </Grid>

            </Grid>
        </>
    )
}

export default InvoiceData;