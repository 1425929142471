import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CreateAndViewStyle from '../../themes/CreateAndViewStyle';
import { findOne, updateAttachments } from './PurchaseRequestController';
import status from '../../services/documentStatus';
import { findAttachments } from '../../services/AttachmentController';
import {
    Grid, Paper, TextField, Table, TableHead, TableBody, TableRow, TableCell,
    TableContainer, Button, Typography, Tabs, AppBar, Tab, IconButton
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Archive } from '@material-ui/icons';
import TabPanel from '../../components/TabPanel';
import moneyFormat from '../../services/moneyFormat';
import AttachmentUpdate from '../../components/AttachmentsUpdate/Update';

const PurchaseRequestView = () => {

    let { id } = useParams();
    const [tabs, setTabs] = useState(0);
    const [purchaseRequest, setPurchaseRequest] = useState({});
    const [items, setItems] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const classes = CreateAndViewStyle();

    useEffect(() => {
        async function fetchData() {
            const result = await findOne(id);

            const docStatus = status(result.DocumentStatus, result.Cancelled);
            setPurchaseRequest({
                "DocNum": result.DocNum,
                "DocEntry": result.DocEntry,
                "DocDate": result.DocDate,
                "Comments": result.Comments,
                "BPLName": result.BPLName,
                "RequriedDate": result.RequriedDate,
                "DocumentStatus": docStatus,
                "AttachmentEntry": result.AttachmentEntry
            });
            setItems(result.DocumentLines);
        };

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (purchaseRequest.AttachmentEntry) {

                const result = await findAttachments(purchaseRequest.AttachmentEntry);
                setAttachments(result.Attachments2_Lines);
            }
        };

        fetchData();
    }, [purchaseRequest.AttachmentEntry]);

    return (
        <>
            <Paper className={classes.title}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography className={classes.whitebold} variant="h5" fullWidth>
                        Solicitação de Compra - Visualizar
                    </Typography>
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={tabs}
                    onChange={(event, newValue) => {
                        setTabs(newValue);
                    }}
                >
                    <Tab label="Dados Gerais" value={0} />
                    <Tab label="Anexos" value={1} />
                </Tabs>
            </AppBar>
            <Paper className={classes.paperInternal}>
                <TabPanel value={tabs} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Código"
                                type="text"
                                value={purchaseRequest.DocNum}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="date"
                                label="Data da Solicitação"
                                type="date"
                                value={purchaseRequest.DocDate}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="date"
                                label="Data Necessária"
                                type="date"
                                value={purchaseRequest.RequriedDate}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="status"
                                label="Status"
                                type="text"
                                value={purchaseRequest.DocumentStatus}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Filial"
                                variant="outlined"
                                size="small"
                                value={purchaseRequest.BPLName}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tablehead}>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>Descrição Complementar</TableCell>
                                            <TableCell align="right">Quantidade</TableCell>
                                            <TableCell>Depósito</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item) => {
                                            return (
                                                <TableRow key={uuid()}>
                                                    <TableCell>{item.ItemCode}</TableCell>
                                                    <TableCell>{item.ItemDescription}</TableCell>
                                                    <TableCell>{item.FreeText}</TableCell>
                                                    <TableCell align="right">{moneyFormat(item.Quantity)}</TableCell>
                                                    <TableCell>{item.WarehouseCode}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Observações"
                                multiline
                                rows={2}
                                variant="outlined"
                                value={purchaseRequest.Comments}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                color="primary"
                                className={classes.button}
                                variant="contained"
                                component={Link}
                                to={'/home/purchaseRequest'}
                            >
                                Voltar
                            </Button>
                            {purchaseRequest.DocumentStatus === 'Aberto' &&
                                <>
                                    <Button
                                        color="primary"
                                        className={classes.button}
                                        variant="outlined"
                                        component={Link}
                                        to={`/home/purchaseRequest/close/${purchaseRequest.DocNum}-${purchaseRequest.DocEntry}`}
                                    >
                                        Encerrar
                                    </Button>
                                    <Button
                                        color="primary"
                                        className={classes.button}
                                        variant="outlined"
                                        component={Link}
                                        to={`/home/purchaseRequest/cancel/${purchaseRequest.DocNum}-${purchaseRequest.DocEntry}`}
                                    >
                                        Cancelar
                                    </Button>
                                </>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabs} index={1}>

                    <AttachmentUpdate 
                        AttachmentEntry={purchaseRequest.AttachmentEntry}
                        DocEntry={purchaseRequest.DocEntry}
                        DocumentListLink={'/home/purchaseRequest'}
                        UpdateDocumentFunction={updateAttachments}
                    />
                    <br />
                    <br />
                    <br />
                    <Paper className={classes.title}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Typography className={classes.whitebold} variant="h5" fullWidth>
                                Anexos Existentes
                            </Typography>
                        </Grid>
                    </Paper>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.tablehead}>
                                    <TableCell><Typography className={classes.bold}>Arquivo</Typography></TableCell>
                                    <TableCell><Typography className={classes.bold}>Extensão</Typography></TableCell>
                                    <TableCell><Typography className={classes.bold}>Download</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attachments.map((attachment) => {
                                    return (
                                        <TableRow
                                            key={uuid()}
                                        >

                                            <TableCell>{attachment.FileName}</TableCell>
                                            <TableCell>{attachment.FileExtension}</TableCell>
                                            <TableCell>
                                                <a href={process.env.REACT_APP_NODE_API_URL + `/Attachments2/${purchaseRequest.AttachmentEntry}/${attachment.FileName}.${attachment.FileExtension}`}  >
                                                    <IconButton component="span">
                                                        <Archive />
                                                    </IconButton>
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </Paper>
        </>
    );
};

export default PurchaseRequestView;